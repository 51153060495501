<script setup lang="ts">
// Script section remains the same
import {defineProps, defineEmits, computed} from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const showDaysWarning = computed(() => {
  const enterDate = props.state.taxResidency?.enterDate ? new Date(props.state.taxResidency.enterDate) : null;
  if (!enterDate) return false;

  const july1st = new Date(enterDate.getFullYear(), 6, 1);
  return enterDate <= july1st;
});

const handleResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
    props.state.taxResidency.agreeDays = true;
  }
  emit('next');
};

const handleNonResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.agreeDays = false;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налоговое резидентство: время пребывания в Испании</h1>
    </div>

    <!-- Info block with source link -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Для признания налоговым резидентом Испании необходимо учитывать не только пребывание более 183 дней в году, но и другие критерии, описанные ниже.      </p>
      <a href="https://t.me/desmontaje_iberico/117"
         class="flex items-start space-x-3 group"
         target="_blank"
         rel="noopener noreferrer">
        <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
          <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
          </svg>
        </div>
        <div class="flex-1">
          <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
            Канал "Иберийские разборки"
          </h4>
          <p class="text-sm">Определение физической связи с Испанией. </p>
        </div>
      </a>
    </div>

    <!-- Warning message -->
    <div v-if="showDaysWarning" class="bg-yellow-900/30 border-l-4 border-yellow-600 p-4 rounded-r-lg">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-yellow-100">
            На основании даты вашего въезда в Испанию, вы можете превысить порог в 183 дня пребывания в стране.
          </p>
        </div>
      </div>
    </div>

    <!-- Main content sections -->
    <div class="space-y-6">
      <!-- Previous sections remain the same until the last block -->
      <!-- Certified Days -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-blue-400">
          1. Подтвержденные дни (Presencia certificada)
        </h2>
        <p class="mb-4">Документально зафиксированные дни нахождения в Испании:</p>
        <ul class="space-y-2">
          <li class="flex items-center">
            <span class="text-blue-400 mr-2">•</span>
            Штампы о пересечении границы
          </li>
          <li class="flex items-center">
            <span class="text-blue-400 mr-2">•</span>
            Договоры аренды и банковского обслуживания
          </li>
          <li class="flex items-center">
            <span class="text-blue-400 mr-2">•</span>
            Квитанции за использование банковской карты
          </li>
          <li class="flex items-center">
            <span class="text-blue-400 mr-2">•</span>
            Визиты к врачам, нотариусам
          </li>
          <li class="flex items-center">
            <span class="text-blue-400 mr-2">•</span>
            Посещение курсов, мероприятий
          </li>
        </ul>
      </div>

      <!-- Presumed Days -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-green-400">
          2. Предполагаемые дни (Dias presuntos)
        </h2>
        <p class="mb-4">Дни между подтвержденными событиями, если не доказано обратное.</p>
        <div class="mt-3 p-4 bg-gray-700/50 rounded-lg">
          <p>
            Пример: если въезд зафиксирован 1 марта, а следующий документ датирован 20 марта,
            дни между этими датами будут считаться предполагаемыми.
          </p>
        </div>
        <p class="mt-4">
          Чтобы исключить предполагаемые дни, необходимо предоставить документы,
          подтверждающие ваше отсутствие в Испании (штампы о выезде, квитанции за границей).
        </p>
      </div>

      <!-- Temporary Absences -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-purple-400">
          3. Временные отсутствия (Ausencias esporádicas)
        </h2>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Все выезды считаются временными, если у вас нет сертификата налоговой резиденции другой страны</span>
          </li>
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Учитываются субъективные факторы (намерения остаться) и объективные данные (время в других странах)</span>
          </li>
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Если в Испании вы провели больше времени, чем в любой другой стране, все выезды считаются временными</span>
          </li>
        </ul>
      </div>

      <!-- Updated Important Aspects block -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-yellow-400">
          4. Важные аспекты временного отсутствия и переезда
        </h2>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Дни до первого въезда в Испанию не считаются временными отсутствиями</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Выезд после подачи на резиденцию может быть расценен как временное отсутствие, особенно если вы планировали вернуться и фактически вернулись</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Resident Option -->
      <button
          @click="handleResident"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Соответствую критериям налогового резидента
          </span>
          <span class="block text-sm text-blue-200">
            С учётом всех критериев выше
          </span>
        </div>
      </button>

      <!-- Non-resident Option -->
      <div class="relative">
        <div class="absolute -inset-1">
          <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
        </div>
        <button
            @click="handleNonResident"
            class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
        >
          <span class="block font-medium mb-1">
            Нет, я проведу менее 183 дней в Испании
          </span>
          <span class="block text-sm text-red-300">
            Могу подтвердить отсутствие документами
          </span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
    props.state.taxResidency.conflict = false;
  }
  emit('next');
};

const handleNonResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.conflict = true;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налоговая резиденция другой страны и СОИДН</h1>
    </div>

    <!-- Info block with source link -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Для подтверждения, что вы не являетесь налоговым резидентом Испании, необходимо предъявить сертификат налоговой резиденции другой страны с указанием, что он выдан для целей соглашения об избежании двойного налогообложения (СОИДН).
      </p>
      <a href="https://t.me/desmontaje_iberico/123"
         class="flex items-start space-x-3 group"
         target="_blank"
         rel="noopener noreferrer">
        <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
          <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
          </svg>
        </div>
        <div class="flex-1">
          <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
            Канал "Иберийские разборки"
          </h4>
          <p class="text-sm">О разрешении конфликта налоговых резиденций</p>
        </div>
      </a>
    </div>

    <!-- Certificate Requirements -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4 text-blue-400">Особенности сертификата налоговой резиденции</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Обязательное требование: сертификат должен подтверждать, что вы платили налоги на доходы в стране резидентства</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Без сертификата: налоговая Испании автоматически признает вас налоговым резидентом</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Если сертификат не соответствует требованиям СОИДН: спор возможен, но он потребует времени, денег и усилий</span>
        </li>
      </ul>
    </div>

    <!-- СОИДН Limitations -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4 text-yellow-400">СОИДН и ограничения</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-yellow-400 mr-2">•</span>
          <span>Не все налоги подпадают под соглашение (например, налоги на дарение и наследство не учитываются)</span>
        </li>
        <li class="flex items-start">
          <span class="text-yellow-400 mr-2">•</span>
          <span>При льготных режимах (как британский "non-dom") сертификат резидентства может быть отклонен</span>
        </li>
      </ul>
    </div>

    <!-- Conflict Resolution -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Разрешение конфликта резиденций</h2>
      <div class="space-y-6">
        <!-- Step 1 -->
        <div class="p-4 bg-gray-700/50 rounded-lg">
          <h3 class="text-lg font-medium mb-2 text-green-400">1. Постоянное жилье</h3>
          <p class="mb-2">Жилье считается постоянным, если оно доступно вам на протяжении всего года.</p>
          <div class="bg-gray-800/50 p-4 rounded-lg">
            <p class="text-sm text-gray-300">
              <span class="text-green-400 font-medium">Пример:</span> съемная квартира во Франции, куда вы можете вернуться в любой момент, считается постоянным жильем. Но если для доступа нужно запрашивать ключи или договариваться с владельцем, такое жилье не учитывается.
            </p>
          </div>
        </div>

        <!-- Step 2 -->
        <div class="p-4 bg-gray-700/50 rounded-lg">
          <h3 class="text-lg font-medium mb-2 text-purple-400">2. Центр жизненных и экономических интересов</h3>
          <ul class="space-y-2">
            <li class="flex items-start">
              <span class="text-purple-400 mr-2">•</span>
              <span>Местоположение ваших родственников</span>
            </li>
            <li class="flex items-start">
              <span class="text-purple-400 mr-2">•</span>
              <span>Активность на конференциях, профессиональные контакты</span>
            </li>
            <li class="flex items-start">
              <span class="text-purple-400 mr-2">•</span>
              <span>Основные расходы и экономическая активность</span>
            </li>
          </ul>
        </div>

        <!-- Steps 3-4 -->
        <div class="p-4 bg-gray-700/50 rounded-lg">
          <h3 class="text-lg font-medium mb-2 text-blue-400">3. Последующие критерии</h3>
          <ul class="space-y-2">
            <li class="flex items-start">
              <span class="text-blue-400 mr-2">•</span>
              <span>Сроки проживания: сравнивается, где вы провели больше времени</span>
            </li>
            <li class="flex items-start">
              <span class="text-blue-400 mr-2">•</span>
              <span>Национальность: используется, если предыдущие критерии не помогли определить резиденцию</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Digital Nomad Example -->
    <div class="bg-gray-800/50 border border-gray-700 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Пример: цифровой кочевник</h2>
      <p class="text-gray-300">
        Если вы жили в съемной квартире в Батуми, затем переехали в Испанию и сняли жилье в Барселоне, продолжая работать удаленно на компанию в США, Испания, вероятно, признает вас налоговым резидентом. Ваш доход не связан с местом пребывания, а постоянное жилье в Испании может стать определяющим фактором.
      </p>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Resident Option -->
      <button
          @click="handleResident"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Соответствую критериям налогового резидента
          </span>
          <span class="block text-sm text-blue-200">
            С учётом всех критериев выше
          </span>
        </div>
      </button>

      <!-- Non-resident Option -->
      <div class="relative">
        <div class="absolute -inset-1">
          <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
        </div>
        <button
            @click="handleNonResident"
            class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
        >
          <span class="block font-medium mb-1">
            Нерезидент
          </span>
          <span class="block text-sm text-red-300">
            Готов пройти проверку по критериям СОИДН
          </span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-yellow-900/30 border-l-4 border-yellow-600 p-4" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = {
  key: 0,
  class: "text-yellow-100"
}
const _hoisted_6 = {
  key: 1,
  class: "text-yellow-100"
}
const _hoisted_7 = { class: "bg-gray-800 rounded-lg p-6 space-y-6" }
const _hoisted_8 = {
  key: 0,
  class: "text-red-500 text-sm"
}
const _hoisted_9 = { class: "space-y-4" }
const _hoisted_10 = { class: "bg-gray-800 rounded-lg p-6" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "relative" }
const _hoisted_13 = {
  key: 0,
  class: "absolute -inset-1"
}
const _hoisted_14 = ["disabled"]

import {computed, onMounted, ref} from 'vue';
import {CalculatorState, ResidencyStatus} from '@/api/proto';
import CountrySelector from '@/components/CountrySelector.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxResidencyPage',
  props: {
    state: {}
  },
  emits: ["next", "prev"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Local state
const status = ref<CalculatorState.TaxResidency.Status | null>(null);
const enterDate = ref('');
const country = ref('');
const error = ref<string | null>(null);

const canApplyBeckham = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_WORK_VISA ||
      status === ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST ||
      status === ResidencyStatus.RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT ||
      status === ResidencyStatus.RS_STARTUP_AUTONOMO;
});

const isValidDate = computed(() => {
  if (!enterDate.value) return false;
  const date = new Date(enterDate.value);
  return date.getFullYear() >= 2023;
});

const isValid = computed(() => {
  if (!enterDate.value || !country.value || !isValidDate.value) {
    return false;
  }
  if (country.value.toLowerCase() === 'spain' || country.value.toLowerCase() === 'españa') {
    return false;
  }
  return true;
});

// Initialize states on mount
onMounted(() => {
  if (props.state.taxResidency) {
    status.value = props.state.taxResidency.status;
    enterDate.value = props.state.taxResidency.enterDate || '';
    country.value = props.state.taxResidency.country || '';
  }
});

const validateAndSetError = () => {
  error.value = null;
  if (!enterDate.value) {
    error.value = 'Пожалуйста, укажите дату въезда';
    return false;
  }
  if (!isValidDate.value) {
    error.value = 'Дата въезда должна быть не ранее 2023 года';
    return false;
  }
  if (!country.value) {
    error.value = 'Пожалуйста, выберите страну';
    return false;
  }
  if (country.value.toLowerCase() === 'spain' || country.value.toLowerCase() === 'españa') {
    error.value = 'Пожалуйста, выберите страну, отличную от Испании';
    return false;
  }
  return true;
};

const handleResident = () => {
  if (!validateAndSetError()) {
    return;
  }

  if (!props.state.taxResidency) {
    props.state.taxResidency = {};
  }

  props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
  props.state.taxResidency.enterDate = enterDate.value;
  props.state.taxResidency.country = country.value;
  emit('next');
};

const handleNonResident = () => {
  if (!validateAndSetError()) {
    return;
  }

  if (!props.state.taxResidency) {
    props.state.taxResidency = {};
  }

  props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
  props.state.taxResidency.enterDate = enterDate.value;
  props.state.taxResidency.country = country.value;
  emit('next');
};

const handlePrev = () => {
  if (status.value !== null) {
    props.state.taxResidency = {
      status: status.value,
      enterDate: enterDate.value,
      country: country.value
    };
  }
  emit('prev');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "space-y-4" }, [
      _createElementVNode("h1", { class: "text-3xl font-bold" }, "Налоговый резидент в год переезда")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-shrink-0" }, [
          _createElementVNode("svg", {
            class: "h-5 w-5 text-yellow-600",
            viewBox: "0 0 20 20",
            fill: "currentColor"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
              "clip-rule": "evenodd"
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          (canApplyBeckham.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Важно: если вы налоговый резидент Испании, вы обязаны уплачивать налоги за весь календарный год, включая период до переезда, кроме случая применения специального режима Бэкхема. "))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, " Важно: если вы налоговый резидент Испании, вы обязаны уплачивать налоги за весь календарный год, включая период до переезда. ")),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-yellow-100" }, " Можно быть налоговым резидентом нескольких стран одновременно. ", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-xl font-semibold mb-4" }, "Переезд в Испанию", -1)),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
          _createTextVNode(" Страна, откуда "),
          _createElementVNode("span", { class: "text-red-500" }, "*")
        ], -1)),
        _createVNode(CountrySelector, {
          modelValue: country.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((country).value = $event)),
          placeholder: "Выберите страну",
          "ring-color": "blue",
          required: ""
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
          _createTextVNode(" Дата первого въезда в Испанию с целью остаться (например туристом для подачи на ВНЖ) "),
          _createElementVNode("span", { class: "text-red-500" }, "*")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((enterDate).value = $event)),
          required: "",
          min: "2023-01-01",
          class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        }, null, 512), [
          [_vModelText, enterDate.value]
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-sm text-gray-400 mt-1" }, "Дата должна быть не ранее 2023 года", -1))
      ]),
      (error.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(error.value), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "text-xl font-medium mb-4" }, "Считаю себя", -1)),
        _createElementVNode("button", {
          onClick: handleResident,
          class: _normalizeClass(["group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30 shadow-lg shadow-blue-500/20 mb-4", {'opacity-50 cursor-not-allowed': !isValid.value}]),
          disabled: !isValid.value
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("span", { class: "font-medium text-white" }, "Налоговым резидентом Испании", -1)
        ]), 10, _hoisted_11),
        _createElementVNode("div", _hoisted_12, [
          (isValid.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[9] || (_cache[9] = [
                _createElementVNode("div", { class: "w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: handleNonResident,
            class: _normalizeClass(["relative w-full px-6 py-3 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors flex items-center justify-center", {'opacity-50 cursor-not-allowed': !isValid.value}]),
            disabled: !isValid.value
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "font-medium" }, "Налоговым резидентом другой страны", -1)
          ]), 10, _hoisted_14)
        ])
      ])
    ]),
    _createElementVNode("div", { class: "flex justify-start items-center px-1" }, [
      _createElementVNode("button", {
        onClick: handlePrev,
        class: "group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      }, _cache[12] || (_cache[12] = [
        _createElementVNode("svg", {
          class: "w-5 h-5 text-gray-400 group-hover:text-white transition-colors",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 19l-7-7 7-7"
          })
        ], -1),
        _createElementVNode("span", { class: "text-gray-400 group-hover:text-white transition-colors" }, "Назад", -1)
      ]))
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-gray-800 rounded-lg p-6 space-y-6" }
const _hoisted_3 = { class: "px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-gray-300" }
const _hoisted_4 = { class: "block text-sm font-medium text-gray-400 mb-2" }
const _hoisted_5 = ["min"]
const _hoisted_6 = {
  key: 0,
  class: "mt-2 text-sm text-red-500"
}
const _hoisted_7 = {
  key: 0,
  class: "p-4 bg-blue-900/30 border border-blue-800/50 rounded-lg"
}
const _hoisted_8 = {
  key: 0,
  class: "p-4 bg-gray-700/50 rounded-lg"
}
const _hoisted_9 = { class: "text-sm text-gray-300" }
const _hoisted_10 = { class: "font-medium" }
const _hoisted_11 = {
  key: 1,
  class: "space-y-6"
}
const _hoisted_12 = { class: "relative" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "relative" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "flex justify-between items-center mt-12 px-1" }
const _hoisted_18 = ["disabled"]

import { computed, onMounted, ref, watch } from 'vue';
import { CalculatorState, MoneyAmount, ResidencyStatus } from '@/api/proto';
import Long from "long";


export default /*@__PURE__*/_defineComponent({
  __name: 'IRNRPage',
  props: {
    state: {}
  },
  emits: ["next", "prev"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const residencyDate = ref('');
const monthsAsNonResident = ref(0);
const userNonResidentIncome = ref<number>(0);
const spouseNonResidentIncome = ref<number>(0);

const hasSpouse = computed(() => {
  return !!props.state.family?.spouse?.birthdate;
});

const enterDate = computed(() => {
  return props.state.taxResidency?.enterDate || '';
});

const isSelfEmployed = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT ||
      status === ResidencyStatus.RS_STARTUP_AUTONOMO ||
      status === ResidencyStatus.RS_CUENTA_PROPIA;
});

const isTourist = computed(() => {
  if (!residencyDate.value || !enterDate.value) return false;
  const residencyYear = new Date(residencyDate.value).getFullYear();
  const enterYear = new Date(enterDate.value).getFullYear();
  return residencyYear > enterYear;
});

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const formatIncome = (value: number): string => {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseIncome = (value: string): number => {
  const parsed = parseFloat(value.replace(/\s/g, ''));
  return isNaN(parsed) ? 0 : parsed;
};

const getPeriodText = (months: number): string => {
  if (months === 1) return 'месяц';
  if (months >= 2 && months <= 4) return 'месяца';
  return 'месяцев';
};

onMounted(() => {
  if (props.state.irnr) {
    residencyDate.value = props.state.irnr.registerDate || '';
    monthsAsNonResident.value = props.state.irnr.months || 0;

    if (props.state.irnr.income?.amount?.amount) {
      userNonResidentIncome.value = Math.floor(props.state.irnr.income.amount.amount.toNumber() / 100);
    }

    if (props.state.irnr.spouseIncome?.amount?.amount) {
      spouseNonResidentIncome.value = Math.floor(props.state.irnr.spouseIncome.amount.amount.toNumber() / 100);
    }
  }
});

watch(residencyDate, (newDate) => {
  if (!newDate || !enterDate.value) return;

  const residencyDateObj = new Date(newDate);
  const yearEnd = new Date(residencyDateObj.getFullYear(), 11, 31);
  const monthDiff = (yearEnd.getMonth() - residencyDateObj.getMonth()) +
      (12 * (yearEnd.getFullYear() - residencyDateObj.getFullYear()));

  monthsAsNonResident.value = monthDiff + 1;

  if (props.state.income?.user?.amount?.amount) {
    const yearlyIncome = Math.floor(props.state.income.user.amount.amount.toNumber() / 100);
    userNonResidentIncome.value = Math.round((yearlyIncome / 12) * monthsAsNonResident.value * 100) / 100;
  }

  if (hasSpouse.value && props.state.income?.spouse?.amount?.amount) {
    const yearlyIncome = Math.floor(props.state.income.spouse.amount.amount.toNumber() / 100);
    spouseNonResidentIncome.value = Math.round((yearlyIncome / 12) * monthsAsNonResident.value * 100) / 100;
  }
});

const isDateValid = computed(() => {
  if (!residencyDate.value || !enterDate.value) return false;
  return new Date(residencyDate.value) >= new Date(enterDate.value);
});

const isValid = computed(() => {
  return isDateValid.value && monthsAsNonResident.value > 0;
});

const updateState = () => {
  props.state.irnr = {
    registerDate: residencyDate.value,
    months: monthsAsNonResident.value,
    income: userNonResidentIncome.value ? { amount: createMoneyAmount(userNonResidentIncome.value) } : undefined,
    spouseIncome: hasSpouse.value && spouseNonResidentIncome.value ?
        { amount: createMoneyAmount(spouseNonResidentIncome.value) } : undefined
  };
};

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"space-y-4\"><h1 class=\"text-3xl font-bold\">Налог на доход нерезидентов (IRNR)</h1></div><div class=\"bg-blue-900/30 border border-blue-800/50 rounded-lg p-6\"><div class=\"space-y-4\"><p class=\"text-sm text-blue-100\"> Поскольку вы выбрали статус нерезидента в переходный год, нам нужны дополнительные данные для корректного расчёта налогообложения в год переезда. </p><a href=\"https://www.boe.es/buscar/act.php?id=BOE-A-2004-4527\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"flex items-start space-x-3 group\"><div class=\"p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors\"><svg class=\"w-5 h-5 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z\"></path></svg></div><div class=\"flex-1\"><h4 class=\"text-sm font-medium text-blue-400 group-hover:text-blue-300\"> Ley del Impuesto sobre la Renta de no Residentes </h4><p class=\"text-sm\">Подробнее о налоге для нерезидентов</p></div></a></div></div>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, " Дата въезда в Испанию ", -1)),
        _createElementVNode("div", _hoisted_3, _toDisplayString(new Date(enterDate.value).toLocaleDateString('ru-RU')), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(isSelfEmployed.value ? 'Дата регистрации как autónomo' : 'Дата получения ВНЖ'), 1),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((residencyDate).value = $event)),
          min: enterDate.value,
          class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        }, null, 8, _hoisted_5), [
          [_vModelText, residencyDate.value]
        ]),
        (residencyDate.value && !isDateValid.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Дата получения ВНЖ не может быть раньше даты въезда "))
          : _createCommentVNode("", true)
      ]),
      (isTourist.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
            _createElementVNode("p", { class: "text-gray-300" }, " Похоже, вы турист в переходный год и платите налоги только со следующего, база для IRNR - 0 EUR ", -1)
          ])))
        : _createCommentVNode("", true),
      (!isTourist.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (monthsAsNonResident.value > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, [
                    _cache[5] || (_cache[5] = _createTextVNode(" Период расчёта IRNR: ")),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(monthsAsNonResident.value) + " " + _toDisplayString(getPeriodText(monthsAsNonResident.value)), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (isValid.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, " Ваш доход за этот период (EUR) ", -1)),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("input", {
                        type: "text",
                        value: formatIncome(userNonResidentIncome.value),
                        onInput: _cache[1] || (_cache[1] = e => userNonResidentIncome.value = parseIncome((e.target as HTMLInputElement).value)),
                        class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      }, null, 40, _hoisted_13),
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
                    ])
                  ]),
                  (hasSpouse.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, " Доход супруга/супруги за этот период (EUR) ", -1)),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("input", {
                            type: "text",
                            value: formatIncome(spouseNonResidentIncome.value),
                            onInput: _cache[2] || (_cache[2] = e => spouseNonResidentIncome.value = parseIncome((e.target as HTMLInputElement).value)),
                            class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                          }, null, 40, _hoisted_16),
                          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("button", {
        onClick: handlePrev,
        class: "group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("svg", {
          class: "w-5 h-5 text-gray-400 group-hover:text-white transition-colors",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 19l-7-7 7-7"
          })
        ], -1),
        _createElementVNode("span", { class: "text-gray-400 group-hover:text-white transition-colors" }, "Назад", -1)
      ])),
      _createElementVNode("button", {
        onClick: handleNext,
        disabled: !isValid.value,
        class: "group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600 disabled:hover:translate-y-0 disabled:hover:shadow-lg bg-blue-600 hover:bg-blue-500 active:bg-blue-700 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30 shadow-lg shadow-blue-500/20"
      }, _cache[11] || (_cache[11] = [
        _createElementVNode("span", { class: "text-white font-medium" }, "Далее", -1),
        _createElementVNode("svg", {
          class: "w-5 h-5 text-white",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M9 5l7 7-7 7"
          })
        ], -1)
      ]), 8, _hoisted_18)
    ])
  ]))
}
}

})
<script setup lang="ts">
import { defineProps, computed } from 'vue';
import { CalculatorState, } from '@/api/proto';
import { getStatusName } from '@/data/residency';

const props = defineProps<{
  state: CalculatorState;
}>();

// Helper to format date
const formatDate = (dateStr?: string) => {
  if (!dateStr) return '';
  return new Date(dateStr).toLocaleDateString('ru-RU');
};

const entryYear = computed(() => {
  if (!props.state.taxResidency?.enterDate) return null;
  return new Date(props.state.taxResidency.enterDate).getFullYear();
});

const userAge = computed(() => {
  if (!props.state.family?.userBirthdate || !entryYear.value) {
    return null;
  }

  const birth = new Date(props.state.family.userBirthdate);
  const endOfYear = new Date(entryYear.value, 11, 31);

  let age = endOfYear.getFullYear() - birth.getFullYear();

  if (birth.getMonth() > endOfYear.getMonth() ||
      (birth.getMonth() === endOfYear.getMonth() &&
          birth.getDate() > endOfYear.getDate())) {
    age--;
  }

  return age;
});

const spouseAge = computed(() => {
  if (!props.state.family?.spouse?.birthdate || !entryYear.value) {
    return null;
  }

  const birth = new Date(props.state.family.spouse.birthdate);
  const endOfYear = new Date(entryYear.value, 11, 31);

  let age = endOfYear.getFullYear() - birth.getFullYear();

  if (birth.getMonth() > endOfYear.getMonth() ||
      (birth.getMonth() === endOfYear.getMonth() &&
          birth.getDate() > endOfYear.getDate())) {
    age--;
  }

  return age;
});

const getChildrenGroups = computed(() => {
  if (!props.state.family?.children?.length || !entryYear.value) {
    return { underThree: 0, overThree: 0 };
  }

  const endOfYear = new Date(entryYear.value, 11, 31);

  return props.state.family.children.reduce((acc, child) => {
    const birthDate = new Date(child.birthdate);
    const ageAtEndOfYear = Math.floor(
        (endOfYear.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25)
    );

    if (ageAtEndOfYear < 3) {
      acc.underThree++;
    } else {
      acc.overThree++;
    }
    return acc;
  }, { underThree: 0, overThree: 0 });
});
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <h1 class="text-3xl font-bold mb-8">Итоговые данные</h1>

    <!-- General Information Block -->
    <div class="bg-gray-800 rounded-lg p-6 space-y-4">
      <h2 class="text-xl font-semibold flex items-center space-x-2">
        <svg class="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <span>Общая информация</span>
      </h2>

      <div class="space-y-2">
        <div class="flex justify-between">
          <span class="text-gray-400">ВНЖ:</span>
          <span>{{ getStatusName(state.residency?.status) }}</span>
        </div>

        <div class="flex justify-between">
          <span class="text-gray-400">Дата въезда в Испанию:</span>
          <span>{{ formatDate(state.taxResidency?.enterDate) }}</span>
        </div>

        <div class="flex justify-between">
          <span class="text-gray-400">Возраст (на 31 декабря {{ entryYear }} года):</span>
          <span>{{ userAge !== null ? `${userAge} лет` : 'Не указано' }}</span>
        </div>

        <div class="flex justify-between">
          <span class="text-gray-400">Дети до 3х лет (на 31 декабря {{ entryYear }} года):</span>
          <span>{{ getChildrenGroups.underThree }}</span>
        </div>

        <div class="flex justify-between">
          <span class="text-gray-400">Дети старше 3х лет (на 31 декабря {{ entryYear }} года):</span>
          <span>{{ getChildrenGroups.overThree }}</span>
        </div>

        <template v-if="state.family?.spouse">
          <div class="flex justify-between">
            <span class="text-gray-400">Супруг/Супруга (на 31 декабря {{ entryYear }} года):</span>
            <span>{{ spouseAge !== null ? `${spouseAge} лет` : 'Не указано' }}</span>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>
<script setup lang="ts">
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { CalculatorState, MoneyAmount, ResidencyStatus } from '@/api/proto';
import Long from "long";

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const residencyDate = ref('');
const monthsAsNonResident = ref(0);
const userNonResidentIncome = ref<number>(0);
const spouseNonResidentIncome = ref<number>(0);

const hasSpouse = computed(() => {
  return !!props.state.family?.spouse?.birthdate;
});

const enterDate = computed(() => {
  return props.state.taxResidency?.enterDate || '';
});

const isSelfEmployed = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT ||
      status === ResidencyStatus.RS_STARTUP_AUTONOMO ||
      status === ResidencyStatus.RS_CUENTA_PROPIA;
});

const isTourist = computed(() => {
  if (!residencyDate.value || !enterDate.value) return false;
  const residencyYear = new Date(residencyDate.value).getFullYear();
  const enterYear = new Date(enterDate.value).getFullYear();
  return residencyYear > enterYear;
});

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const formatIncome = (value: number): string => {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseIncome = (value: string): number => {
  const parsed = parseFloat(value.replace(/\s/g, ''));
  return isNaN(parsed) ? 0 : parsed;
};

const getPeriodText = (months: number): string => {
  if (months === 1) return 'месяц';
  if (months >= 2 && months <= 4) return 'месяца';
  return 'месяцев';
};

onMounted(() => {
  if (props.state.irnr) {
    residencyDate.value = props.state.irnr.registerDate || '';
    monthsAsNonResident.value = props.state.irnr.months || 0;

    if (props.state.irnr.income?.amount?.amount) {
      userNonResidentIncome.value = Math.floor(props.state.irnr.income.amount.amount.toNumber() / 100);
    }

    if (props.state.irnr.spouseIncome?.amount?.amount) {
      spouseNonResidentIncome.value = Math.floor(props.state.irnr.spouseIncome.amount.amount.toNumber() / 100);
    }
  }
});

watch(residencyDate, (newDate) => {
  if (!newDate || !enterDate.value) return;

  const residencyDateObj = new Date(newDate);
  const yearEnd = new Date(residencyDateObj.getFullYear(), 11, 31);
  const monthDiff = (yearEnd.getMonth() - residencyDateObj.getMonth()) +
      (12 * (yearEnd.getFullYear() - residencyDateObj.getFullYear()));

  monthsAsNonResident.value = monthDiff + 1;

  if (props.state.income?.user?.amount?.amount) {
    const yearlyIncome = Math.floor(props.state.income.user.amount.amount.toNumber() / 100);
    userNonResidentIncome.value = Math.round((yearlyIncome / 12) * monthsAsNonResident.value * 100) / 100;
  }

  if (hasSpouse.value && props.state.income?.spouse?.amount?.amount) {
    const yearlyIncome = Math.floor(props.state.income.spouse.amount.amount.toNumber() / 100);
    spouseNonResidentIncome.value = Math.round((yearlyIncome / 12) * monthsAsNonResident.value * 100) / 100;
  }
});

const isDateValid = computed(() => {
  if (!residencyDate.value || !enterDate.value) return false;
  return new Date(residencyDate.value) >= new Date(enterDate.value);
});

const isValid = computed(() => {
  return isDateValid.value && monthsAsNonResident.value > 0;
});

const updateState = () => {
  props.state.irnr = {
    registerDate: residencyDate.value,
    months: monthsAsNonResident.value,
    income: userNonResidentIncome.value ? { amount: createMoneyAmount(userNonResidentIncome.value) } : undefined,
    spouseIncome: hasSpouse.value && spouseNonResidentIncome.value ?
        { amount: createMoneyAmount(spouseNonResidentIncome.value) } : undefined
  };
};

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налог на доход нерезидентов (IRNR)</h1>
    </div>

    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6">
      <div class="space-y-4">
        <p class="text-sm text-blue-100">
          Поскольку вы выбрали статус нерезидента в переходный год, нам нужны дополнительные данные для корректного расчёта налогообложения в год переезда.
        </p>
        <a
            href="https://www.boe.es/buscar/act.php?id=BOE-A-2004-4527"
            target="_blank"
            rel="noopener noreferrer"
            class="flex items-start space-x-3 group"
        >
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Ley del Impuesto sobre la Renta de no Residentes
            </h4>
            <p class="text-sm">Подробнее о налоге для нерезидентов</p>
          </div>
        </a>
      </div>
    </div>

    <div class="bg-gray-800 rounded-lg p-6 space-y-6">
      <div>
        <label class="block text-sm font-medium text-gray-400 mb-2">
          Дата въезда в Испанию
        </label>
        <div class="px-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg text-gray-300">
          {{ new Date(enterDate).toLocaleDateString('ru-RU') }}
        </div>
      </div>

      <div>
        <label class="block text-sm font-medium text-gray-400 mb-2">
          {{ isSelfEmployed ? 'Дата регистрации как autónomo' : 'Дата получения ВНЖ' }}
        </label>
        <input
            type="date"
            v-model="residencyDate"
            :min="enterDate"
            class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
           focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <p v-if="residencyDate && !isDateValid" class="mt-2 text-sm text-red-500">
          Дата получения ВНЖ не может быть раньше даты въезда
        </p>
      </div>

      <div v-if="isTourist" class="p-4 bg-blue-900/30 border border-blue-800/50 rounded-lg">
        <p class="text-gray-300">
          Похоже, вы турист в переходный год и платите налоги только со следующего, база для IRNR - 0 EUR
        </p>
      </div>

      <template v-if="!isTourist">
        <div v-if="monthsAsNonResident > 0" class="p-4 bg-gray-700/50 rounded-lg">
          <p class="text-sm text-gray-300">
            Период расчёта IRNR: <span class="font-medium">{{ monthsAsNonResident }} {{ getPeriodText(monthsAsNonResident) }}</span>
          </p>
        </div>

        <div v-if="isValid" class="space-y-6">
          <div>
            <label class="block text-sm font-medium text-gray-400 mb-2">
              Ваш доход за этот период (EUR)
            </label>
            <div class="relative">
              <input
                  type="text"
                  :value="formatIncome(userNonResidentIncome)"
                  @input="e => userNonResidentIncome = parseIncome((e.target as HTMLInputElement).value)"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
            </div>
          </div>

          <div v-if="hasSpouse">
            <label class="block text-sm font-medium text-gray-400 mb-2">
              Доход супруга/супруги за этот период (EUR)
            </label>
            <div class="relative">
              <input
                  type="text"
                  :value="formatIncome(spouseNonResidentIncome)"
                  @input="e => spouseNonResidentIncome = parseIncome((e.target as HTMLInputElement).value)"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                 focus:ring-2 focus:ring-pink-500 focus:border-transparent"
              />
              <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
             fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button
          @click="handleNext"
          :disabled="!isValid"
          class="group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200
           disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600
           disabled:hover:translate-y-0 disabled:hover:shadow-lg
           bg-blue-600 hover:bg-blue-500 active:bg-blue-700
           hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
           shadow-lg shadow-blue-500/20"
      >
        <span class="text-white font-medium">Далее</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
  </div>
</template>
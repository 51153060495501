import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-gray-800 rounded-lg p-6 space-y-4" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "text-gray-400" }
const _hoisted_8 = { class: "flex justify-between" }
const _hoisted_9 = { class: "text-gray-400" }
const _hoisted_10 = { class: "flex justify-between" }
const _hoisted_11 = { class: "text-gray-400" }
const _hoisted_12 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_13 = { class: "text-gray-400" }

import { computed } from 'vue';
import { CalculatorState, } from '@/api/proto';
import { getStatusName } from '@/data/residency';


export default /*@__PURE__*/_defineComponent({
  __name: 'FinalPage',
  props: {
    state: {}
  },
  setup(__props: any) {

const props = __props;

// Helper to format date
const formatDate = (dateStr?: string) => {
  if (!dateStr) return '';
  return new Date(dateStr).toLocaleDateString('ru-RU');
};

const entryYear = computed(() => {
  if (!props.state.taxResidency?.enterDate) return null;
  return new Date(props.state.taxResidency.enterDate).getFullYear();
});

const userAge = computed(() => {
  if (!props.state.family?.userBirthdate || !entryYear.value) {
    return null;
  }

  const birth = new Date(props.state.family.userBirthdate);
  const endOfYear = new Date(entryYear.value, 11, 31);

  let age = endOfYear.getFullYear() - birth.getFullYear();

  if (birth.getMonth() > endOfYear.getMonth() ||
      (birth.getMonth() === endOfYear.getMonth() &&
          birth.getDate() > endOfYear.getDate())) {
    age--;
  }

  return age;
});

const spouseAge = computed(() => {
  if (!props.state.family?.spouse?.birthdate || !entryYear.value) {
    return null;
  }

  const birth = new Date(props.state.family.spouse.birthdate);
  const endOfYear = new Date(entryYear.value, 11, 31);

  let age = endOfYear.getFullYear() - birth.getFullYear();

  if (birth.getMonth() > endOfYear.getMonth() ||
      (birth.getMonth() === endOfYear.getMonth() &&
          birth.getDate() > endOfYear.getDate())) {
    age--;
  }

  return age;
});

const getChildrenGroups = computed(() => {
  if (!props.state.family?.children?.length || !entryYear.value) {
    return { underThree: 0, overThree: 0 };
  }

  const endOfYear = new Date(entryYear.value, 11, 31);

  return props.state.family.children.reduce((acc, child) => {
    const birthDate = new Date(child.birthdate);
    const ageAtEndOfYear = Math.floor(
        (endOfYear.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25)
    );

    if (ageAtEndOfYear < 3) {
      acc.underThree++;
    } else {
      acc.overThree++;
    }
    return acc;
  }, { underThree: 0, overThree: 0 });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-3xl font-bold mb-8" }, "Итоговые данные", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl font-semibold flex items-center space-x-2" }, [
        _createElementVNode("svg", {
          class: "w-6 h-6 text-blue-400",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          })
        ]),
        _createElementVNode("span", null, "Общая информация")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-400" }, "ВНЖ:", -1)),
          _createElementVNode("span", null, _toDisplayString(_unref(getStatusName)(_ctx.state.residency?.status)), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-400" }, "Дата въезда в Испанию:", -1)),
          _createElementVNode("span", null, _toDisplayString(formatDate(_ctx.state.taxResidency?.enterDate)), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, "Возраст (на 31 декабря " + _toDisplayString(entryYear.value) + " года):", 1),
          _createElementVNode("span", null, _toDisplayString(userAge.value !== null ? `${userAge.value} лет` : 'Не указано'), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, "Дети до 3х лет (на 31 декабря " + _toDisplayString(entryYear.value) + " года):", 1),
          _createElementVNode("span", null, _toDisplayString(getChildrenGroups.value.underThree), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, "Дети старше 3х лет (на 31 декабря " + _toDisplayString(entryYear.value) + " года):", 1),
          _createElementVNode("span", null, _toDisplayString(getChildrenGroups.value.overThree), 1)
        ]),
        (_ctx.state.family?.spouse)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, "Супруг/Супруга (на 31 декабря " + _toDisplayString(entryYear.value) + " года):", 1),
              _createElementVNode("span", null, _toDisplayString(spouseAge.value !== null ? `${spouseAge.value} лет` : 'Не указано'), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})
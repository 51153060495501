<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
    props.state.taxResidency.familyResidence = true;
  }
  emit('next');
};

const handleNonResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.familyResidence = false;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налоговое резидентство: семейная связь с Испанией</h1>
    </div>

    <!-- Info block with source link -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Если вы переезжаете с семьей - этот критерий считается "presumido" (предполагаемым) - то есть по умолчанию налоговая считает вас налоговым резидентом.
      </p>
      <a href="https://t.me/desmontaje_iberico/121"
         class="flex items-start space-x-3 group"
         target="_blank"
         rel="noopener noreferrer">
        <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
          <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
          </svg>
        </div>
        <div class="flex-1">
          <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
            Канал "Иберийские разборки"
          </h4>
          <p class="text-sm">Определение семейной связи с Испанией</p>
        </div>
      </a>
    </div>

    <!-- Main content sections -->
    <div class="space-y-6">
      <!-- General Rule -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-blue-400">
          Общее правило
        </h2>
        <p class="mb-4">
          Если вы переезжаете вместе с супругом(ой) и/или несовершеннолетними детьми,
          вы автоматически предполагаетесь налоговым резидентом, если не докажете обратное.
        </p>
      </div>

      <!-- Contesting Status -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-green-400">
          Возможность оспорить статус
        </h2>
        <p class="mb-4">Вы можете оспорить этот статус, если:</p>
        <ul class="space-y-2">
          <li class="flex items-center">
            <span class="text-green-400 mr-2">•</span>
            Проживаете в другой стране
          </li>
          <li class="flex items-center">
            <span class="text-green-400 mr-2">•</span>
            Имеете там налоговую резиденцию (подтвержденную сертификатом)
          </li>
          <li class="flex items-center">
            <span class="text-green-400 mr-2">•</span>
            Получаете доходы и платите налоги в другой стране
          </li>
          <li class="flex items-center">
            <span class="text-green-400 mr-2">•</span>
            Имеете там жилье
          </li>
        </ul>
      </div>

      <!-- Dependent Spouse -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-purple-400">
          Супруг/супруга на содержании
        </h2>
        <p class="mb-4">Закон допускает ситуацию, когда:</p>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Супруг/а находится в Испании на содержании второй половины</span>
          </li>
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Второй супруг/а проживает и платит налоги за пределами Испании</span>
          </li>
        </ul>

        <div class="mt-6">
          <p class="mb-4">Супруг/а в Испании:</p>
          <ul class="space-y-3">
            <li class="flex items-start">
              <span class="text-purple-400 mr-2">•</span>
              <span>Имеет статус налогового резидента</span>
            </li>
            <li class="flex items-start">
              <span class="text-purple-400 mr-2">•</span>
              <span>Не платит налоги с денег, полученных от супруга из-за границы</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Resident Option -->
      <button
          @click="handleResident"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Соответствую критериям налогового резидента
          </span>
          <span class="block text-sm text-blue-200">
            С учётом всех критериев выше
          </span>
        </div>
      </button>

      <!-- Non-resident Option -->
      <div class="relative">
        <div class="absolute -inset-1">
          <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
        </div>
        <button
            @click="handleNonResident"
            class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
        >
          <span class="block font-medium mb-1">
            Не резидент: центр семейных интересов в другой стране
          </span>
          <span class="block text-sm text-red-300">
            Могу документально подтвердить связь с другой страной
          </span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
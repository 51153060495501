<script setup lang="ts">
import {defineProps, defineEmits, computed, ref} from 'vue';
import {CalculatorState, ResidencyStatus} from '@/api/proto';
import {needAutonomoForSpouse, titularWithAutonomo} from '@/data/residency';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Helper to check if person is autonomo
const isAutonomo = computed(() => {
  return props.state.residency && titularWithAutonomo(props.state.residency!.status!);
});

const isSpouseAutonomo = computed(() => {
  return needAutonomoForSpouse(props.state);
});

// Default selections
const userSelection = ref(false); // "не применять" by default
const spouseSelection = ref(true); // "применить" by default

// Check contract dates for warnings
const getUserContractWarning = computed(() => {
  return false; // TODO: implement based on all dates
});

const getSpouseContractWarning = computed(() => {
  return false; // TODO: implement based on all data
});

const handleNext = () => {
  if (!props.state.newActivity) {
    props.state.newActivity = {};
  }
  props.state.newActivity.userApplied = userSelection.value;
  props.state.newActivity.spouseApplied = spouseSelection.value;
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Reducción por inicio de una actividad económica</h1>
    </div>

    <!-- Info block with all information -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-6">
      <!-- Main description -->
      <div class="flex items-start space-x-3">
        <svg class="w-6 h-6 text-blue-400 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <div class="space-y-4">
          <p class="text-blue-100">
            Налоговая льгота в размере 20% (10% в Бискайе) от чистого дохода для тех, кто впервые начинает экономическую
            деятельность.
            Льгота действует в течение первого налогового периода с положительным результатом и следующего за ним.
          </p>
        </div>
      </div>

      <!-- Requirements -->
      <div class="border-t border-blue-800/50 pt-4">
        <h3 class="text-lg font-medium text-blue-300 mb-3">Требования</h3>
        <ul class="space-y-2">
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Впервые начать экономическую деятельность в текущем налоговом периоде</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Иметь положительный результат от экономической деятельности</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Не заниматься аналогичной деятельностью в предыдущий год, в том числе в другой стране</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Вычет не применяется, если более 50% доходов за период поступает от лица или организации, от которых налогоплательщик получал заработную плату в предыдущем году до начала деятельности.</span>
          </li>
        </ul>
      </div>

      <!-- Official documentation -->
      <div class="border-t border-blue-800/50 pt-4 space-y-4">
        <h3 class="text-lg font-medium text-blue-300 mb-3">Официальная документация</h3>
        <div class="space-y-3">
          <a href="https://sede.agenciatributaria.gob.es/Sede/ayuda/manuales-videos-folletos/manuales-practicos/irpf-2023/c07-rendimientos-actividades-economicas-estimacion-directa/fase-3-determinacion-rendimiento-neto-total/reduccion-rendimiento-neto-inicio-actividad-economica.html"
             class="flex items-start space-x-3 group"
             target="_blank"
             rel="noopener noreferrer">
            <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
              <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
              </svg>
            </div>
            <div class="flex-1">
              <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
                Manual IRPF 2023
              </h4>
              <p class="text-sm">Официальное руководство по налогообложению: раздел о льготах при начале
                деятельности</p>
            </div>
          </a>

          <a href="https://petete.tributos.hacienda.gob.es/consultas/?num_consulta=V0494-16"
             class="flex items-start space-x-3 group p-2 bg-gradient-to-r from-blue-600/30 to-blue-500/30 rounded-lg border-2 border-blue-400/50 hover:border-blue-300 transition-all transform hover:scale-102 hover:shadow-lg hover:from-blue-600/40 hover:to-blue-500/40"
             target="_blank"
             rel="noopener noreferrer">
            <div class="p-2 bg-blue-500/30 rounded-lg group-hover:bg-blue-400/40 transition-colors">
              <svg class="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </div>
            <div class="flex-1">
              <h4 class="text-lg font-medium text-blue-300 group-hover:text-blue-200 transition-colors">
                Разъяснение налоговой службы по применению льготы
              </h4>
              <p class="text-sm text-blue-200 group-hover:text-blue-100">Consulta V0494-16</p>
            </div>
            <div class="flex-shrink-0">
              <svg class="w-6 h-6 text-blue-400 group-hover:text-blue-300" fill="none" stroke="currentColor"
                   viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
              </svg>
            </div>
          </a>
        </div>
      </div>

      <!-- Important notes -->
      <div class="border-t border-blue-800/50 pt-4">
        <h3 class="text-lg font-medium text-blue-300 mb-3">Важно</h3>
        <ul class="space-y-2">
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Льгота указывается в годовой налоговой декларации резидента</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>При ошибочном декларировании штраф составляет от 50% от неуплаченной суммы + пени за весь срок</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Налоговая может проверить правомерность в течение 4-5 лет</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- User Section -->
    <!-- User Section -->
    <div v-if="isAutonomo" class="bg-gray-800 p-6 rounded-lg">
      <div class="flex items-center space-x-4 mb-6">
        <div class="p-3 bg-blue-600 rounded-lg">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
        </div>
        <h2 class="text-xl font-semibold">Вы</h2>
      </div>

      <div class="space-y-4">
        <!-- Warning if applicable -->
        <div v-if="getUserContractWarning" class="bg-yellow-900/30 border border-yellow-800/50 rounded p-4">
          <div class="flex items-start space-x-3">
            <svg class="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
            <span>Дата начала контракта - в прошлом налоговом периоде, согласно разъяснению V0494-16 - льгота не применяется</span>
          </div>
        </div>

        <!-- Options -->
        <div class="space-y-3">
          <button
              @click="userSelection = true"
              :class="[
              'w-full px-4 py-3 rounded-lg transition-colors',
              userSelection ? 'bg-blue-600 hover:bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'
            ]"
          >
            Применить льготу
          </button>
          <button
              @click="userSelection = false"
              :class="[
              'w-full px-4 py-3 rounded-lg transition-colors',
              !userSelection ? 'bg-blue-600 hover:bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'
            ]"
          >
            Не применять
          </button>
        </div>
      </div>
    </div>

    <!-- Spouse Section -->
    <div v-if="isSpouseAutonomo" class="bg-gray-800 p-6 rounded-lg">
      <div class="flex items-center space-x-4 mb-6">
        <div class="p-3 bg-purple-600 rounded-lg">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
          </svg>
        </div>
        <h2 class="text-xl font-semibold">Супруг/Супруга</h2>
      </div>

      <div class="space-y-4">
        <!-- Warning if applicable -->
        <div v-if="getSpouseContractWarning" class="bg-yellow-900/30 border border-yellow-800/50 rounded p-4">
          <div class="flex items-start space-x-3">
            <svg class="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" fill="none" stroke="currentColor"
                 viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
            <span>Дата начала контракта - в прошлом налоговом периоде, согласно разъяснению V0494-16 - льгота не применяется</span>
          </div>
        </div>

        <!-- Options -->
        <div class="space-y-3">
          <button
              @click="spouseSelection = true"
              :class="[
              'w-full px-4 py-3 rounded-lg transition-colors',
              spouseSelection ? 'bg-blue-600 hover:bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'
            ]"
          >
            Применить льготу
          </button>
          <button
              @click="spouseSelection = false"
              :class="[
              'w-full px-4 py-3 rounded-lg transition-colors',
              !spouseSelection ? 'bg-blue-600 hover:bg-blue-500' : 'bg-gray-700 hover:bg-gray-600'
            ]"
          >
            Не применять
          </button>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button
          @click="handleNext"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-500 transition-colors"
      >
        <span class="text-white">Далее</span>
        <svg
            class="w-5 h-5 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue';
import {CalculatorState, MoneyAmount} from '@/api/proto';
import {needAutonomoForSpouse, titularWithAutonomo} from '@/data/residency';
import Long from "long";

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Default expenses suggestions with amounts
const DEFAULT_EXPENSES = [
  { name: 'Хестор/сервис для налогов', amount: 60 },
  { name: 'Coworking', amount: 100 },
  { name: 'Claude Pro', amount: 20 },
  { name: 'ChatGPT Plus', amount: 20 },
  { name: 'GitHub Copilot', amount: 10 },
  { name: 'Midjourney', amount: 10 },
  { name: 'Notion', amount: 8 },
];

// Initialize reactive refs
const rentAmount = ref<number | null>(null);
const insuranceAmount = ref<number | null>(null);
const expenses = ref<Array<{name: string; amount: number}>>([]);
const spouseExpenses = ref<Array<{name: string; amount: number}>>([]);

const isAutonomo = computed(() => {
  return props.state.residency && titularWithAutonomo(props.state.residency!.status);
});

const isSpouseAutonomo = computed(() => {
  return needAutonomoForSpouse(props.state);
});

// Initialize expenses based on state or defaults
const initializeExpenses = () => {
  // Load expenses from state if they exist
  if (props.state.bills?.expenses?.length) {
    expenses.value = props.state.bills.expenses.map(exp => ({
      name: exp.name,
      amount: Math.floor((exp.amount?.toNumber() || 0) / 1200) // Convert yearly cents to monthly EUR
    }));
  } else if (isAutonomo.value) {
    // If no state but is autonomo, load all defaults
    expenses.value = [...DEFAULT_EXPENSES];
  }

  // Load spouse expenses from state if they exist
  if (props.state.bills?.spouseExpenses?.length) {
    spouseExpenses.value = props.state.bills.spouseExpenses.map(exp => ({
      name: exp.name,
      amount: Math.floor((exp.amount?.toNumber() || 0) / 1200) // Convert yearly cents to monthly EUR
    }));
  } else if (isSpouseAutonomo.value) {
    // If no state but spouse is autonomo, load first default
    spouseExpenses.value = [DEFAULT_EXPENSES[0]];
  }

  // Load rent and insurance if they exist in state
  if (props.state.bills?.rent?.amount) {
    rentAmount.value = Math.floor(props.state.bills.rent.amount.toNumber() / 1200); // Convert to monthly
  }

  if (props.state.bills?.insurance?.amount) {
    insuranceAmount.value = Math.floor(props.state.bills.insurance.amount.toNumber() / 100); // Already yearly
  }
};

onMounted(() => {
  initializeExpenses();
});

const formatAmount = (value: number | null): string => {
  if (value === null) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseAmount = (value: string): number | null => {
  if (!value) return null;
  const parsed = parseInt(value.replace(/\s/g, ''), 10);
  return isNaN(parsed) ? null : parsed;
};

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const addUserExpense = () => {
  expenses.value.push({ name: '', amount: 0 });
};

const addSpouseExpense = () => {
  spouseExpenses.value.push({ name: '', amount: 0 });
};

const removeUserExpense = (index: number) => {
  expenses.value.splice(index, 1);
};

const removeSpouseExpense = (index: number) => {
  spouseExpenses.value.splice(index, 1);
};

const updateState = () => {
  props.state.bills = {
    rent: rentAmount.value !== null ? {
      amount: createMoneyAmount(rentAmount.value * 12) // Convert monthly to yearly
    } : undefined,
    insurance: insuranceAmount.value !== null ? {
      amount: createMoneyAmount(insuranceAmount.value) // Already yearly
    } : undefined,
    expenses: expenses.value.map(exp => ({
      name: exp.name,
      amount: Long.fromNumber(exp.amount * 100 * 12, true) // Converting monthly to yearly cents
    })),
    spouseExpenses: spouseExpenses.value.map(exp => ({
      name: exp.name,
      amount: Long.fromNumber(exp.amount * 100 * 12, true) // Converting monthly to yearly cents
    }))
  };
};

const handleNext = () => {
  updateState();
  emit('next');
};

const handlePrev = () => {
  updateState();
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Расходы</h1>
      <p class="text-gray-400">Укажите регулярные расходы, которые могут быть учтены при расчете налогов</p>
    </div>

    <!-- Info block -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6">
      <div class="space-y-4">
        <p class="text-sm text-blue-100">
          Некоторые расходы могут уменьшить ваш налогооблагаемый доход в зависимости от:
        </p>
        <ul class="space-y-2">
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Провинции</span>
          </li>
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Выбранного налогового режима</span>
          </li>
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Других условий</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Bills form -->
    <div class="bg-gray-800 rounded-lg p-6 space-y-8">
      <!-- Living Expenses -->
      <div class="space-y-6">
        <h3 class="text-lg font-medium text-gray-200">Расходы на проживание</h3>

        <!-- Rent -->
        <div>
          <label class="block text-sm font-medium text-gray-400 mb-2">
            Аренда жилья (EUR в месяц)
            <span class="ml-2 text-xs text-gray-500">(если есть)</span>
          </label>
          <div class="relative">
            <input
                type="text"
                :value="formatAmount(rentAmount)"
                @input="e => rentAmount = parseAmount((e.target as HTMLInputElement).value)"
                class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="0"
            />
            <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
          </div>
        </div>

        <!-- Insurance -->
        <div>
          <label class="block text-sm font-medium text-gray-400 mb-2">
            Частная медицинская страховка (EUR в год)
            <span class="ml-2 text-xs text-gray-500">(если есть)</span>
          </label>
          <div class="relative">
            <input
                type="text"
                :value="formatAmount(insuranceAmount)"
                @input="e => insuranceAmount = parseAmount((e.target as HTMLInputElement).value)"
                class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="0"
            />
            <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
          </div>
        </div>
      </div>

      <!-- Divider -->
      <div v-if="isAutonomo || isSpouseAutonomo" class="border-t border-gray-700"></div>

      <!-- User Expenses section -->
      <div v-if="isAutonomo" class="space-y-4">
        <h3 class="text-lg font-medium text-gray-200">Ваши расходы на ПО и сервисы</h3>
        <p class="text-sm text-gray-400">Укажите ежемесячные расходы на ПО и сервисы, которые вы используете для работы</p>

        <!-- List of expenses -->
        <div class="space-y-4">
          <div v-for="(expense, index) in expenses" :key="index" class="flex items-start space-x-4">
            <div class="flex-grow">
              <input
                  v-model="expense.name"
                  type="text"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
                  placeholder="Название сервиса"
              />
            </div>
            <div class="w-48 relative">
              <input
                  type="text"
                  :value="formatAmount(expense.amount)"
                  @input="e => expense.amount = parseAmount((e.target as HTMLInputElement).value)"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
                  placeholder="0"
              />
              <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
            </div>
            <button
                @click="() => removeUserExpense(index)"
                class="p-2 text-gray-400 hover:text-red-400 transition-colors"
            >
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>

        <div>
          <button
              @click="addUserExpense"
              class="px-4 py-2 text-sm bg-blue-600 hover:bg-blue-500 rounded-lg transition-colors"
          >
            + Добавить сервис
          </button>
        </div>
      </div>

      <!-- Divider -->
      <div v-if="isAutonomo && isSpouseAutonomo" class="border-t border-gray-700"></div>

      <!-- Spouse Expenses section -->
      <div v-if="isSpouseAutonomo" class="space-y-4">
        <h3 class="text-lg font-medium text-gray-200">Расходы супруга(и) на ПО и сервисы</h3>
        <p class="text-sm text-gray-400">Укажите ежемесячные расходы на ПО и сервисы, которые использует ваш супруг(а) для работы</p>

        <!-- List of spouse expenses -->
        <div class="space-y-4">
          <div v-for="(expense, index) in spouseExpenses" :key="index" class="flex items-start space-x-4">
            <div class="flex-grow">
              <input
                  v-model="expense.name"
                  type="text"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
                  placeholder="Название сервиса"
              />
            </div>
            <div class="w-48 relative">
              <input
                  type="text"
                  :value="formatAmount(expense.amount)"
                  @input="e => expense.amount = parseAmount((e.target as HTMLInputElement).value)"
                  class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
                  placeholder="0"
              />
              <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
            </div>
            <button
                @click="() => removeSpouseExpense(index)"
                class="p-2 text-gray-400 hover:text-red-400 transition-colors"
            >
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>

        <!-- Add spouse expense button -->
        <div>
          <button
              @click="addSpouseExpense"
              class="px-4 py-2 text-sm bg-blue-600 hover:bg-blue-500 rounded-lg transition-colors"
          >
            + Добавить сервис
          </button>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button
          @click="handleNext"
          class="group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200
           bg-blue-600 hover:bg-blue-500 active:bg-blue-700
           hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
           shadow-lg shadow-blue-500/20"
      >
        <span class="text-white font-medium">Далее</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
  </div>
</template>
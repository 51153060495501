<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits} from 'vue';
import {CalculatorState, ResidencyStatus} from '@/api/proto';
import {
  getStatusName,
  requireBilateralAgreement,
  statusesList
} from '@/data/residency';

const props = defineProps<{
  state: CalculatorState;
}>();
const emit = defineEmits<{
  (e: 'next'): void
}>();

const reasons = [
  "Помогает рассчитать налоги и отчисления в социальное страхование с учётом провинции проживания, уровня дохода и семейного положения",
  "Позволяет оценить налоговую нагрузку и сравнить ставки в разных регионах Испании (например, Каталония, Мадрид)",
  "Учитывает статус налогового резидента и применяет соответствующие вычеты",
  "Подходит для индивидуальных расчётов и для всей семьи, включая семейные льготы",
  "Результаты можно сохранить, скачать или использовать для планирования бюджета без регистрации и смс",
  "Простой инструмент для тех, кто рассматривает релокацию или уже работает в Испании",
  "Идеально для иммиграционных помощников и хесторов",
];

// Get all possible statuses across all groups
const allStatuses = statusesList.map(status => ({
      value: status,
      label: getStatusName(status)
    }));

// Local state
const selectedStatus = ref<ResidencyStatus>(allStatuses[0].value);
const showWarning = ref<boolean>(false);

// Initialize states on mount
onMounted(() => {
  if (props.state.residency?.status != undefined) {
    selectedStatus.value = props.state.residency.status;
    showWarning.value = requireBilateralAgreement(selectedStatus.value);
  }
});

const updateSelectedStatus = (newStatus: string) => {
  const status = parseInt(newStatus) as ResidencyStatus;
  selectedStatus.value = status;
  showWarning.value = requireBilateralAgreement(status);

  // Update parent state
  props.state.residency = {
    status: status
  };
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Калькулятор налогов в Испании</h1>
    </div>

    <ul class="space-y-3">
      <li v-for="(item, index) in reasons" :key="index" class="flex items-start">
        <svg class="w-4 h-4 mt-1 mr-2 text-secondary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
        </svg>
        <span>{{ item }}</span>
      </li>
    </ul>

    <div class="bg-gray-800 rounded-lg p-8">
      <h2 class="text-2xl font-semibold mb-4">Выберите ваш статус в Испании</h2>
      <p class="mb-8">
        Ваш миграционный статус определяет доступ к специальным налоговым режимам и льготам.<br/>
        В зависимости от типа резиденции устанавливаются соответствующие налоговые преимущества и обязательства.<br/>
        Калькулятор автоматически учтет все подходящие специальные налоговые режимы на основании вашего выбора.<br/>
      </p>

      <select
          :value="selectedStatus"
          @change="updateSelectedStatus($event.target.value)"
          class="w-full p-4 mb-6 rounded-lg bg-gray-700 border border-gray-600 text-gray-200
                 focus:ring-2 focus:ring-primary focus:border-primary"
      >
        <option
            v-for="option in allStatuses"
            :key="option.value"
            :value="option.value"
            class="bg-gray-700 text-gray-200 py-2"
        >
          {{ option.label }}
        </option>
      </select>

      <div v-if="showWarning" class="mb-8 bg-[#2b2523] rounded-lg p-6 space-y-4">
        <div class="flex items-start space-x-3">
          <svg class="h-6 w-6 text-yellow-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
          </svg>
          <div class="space-y-1">
            <p class="font-medium text-yellow-400">Важно:</p>
            <p class="text-gray-200">
              Если ваш трудовой договор заключен с компанией из страны, не входящей в ЕС или не включенной в перечень
              стран с зачетом социальных отчислений, вашей компании придется регистрироваться в системе социального обеспечения Испании.
            </p>
          </div>
        </div>
        <div class="border-t border-gray-700 pt-4">
          <a
              href="https://www.seg-social.es/wps/portal/wss/internet/InformacionUtil/32078/32253"
              class="flex items-center space-x-2 text-gray-400 hover:text-gray-300"
              target="_blank"
              rel="noopener noreferrer"
          >
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
            <span>Полный список стран доступен на сайте: seg-social.es</span>
          </a>
        </div>
      </div>

      <!-- Moved button inside the block with adjusted styling -->
      <button
          @click="emit('next')"
          class="w-full px-8 py-4 bg-blue-600 text-lg font-medium text-white rounded-lg
                 hover:bg-blue-500 active:bg-blue-700 transition-all duration-200
                 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30
                 hover:-translate-y-0.5 active:translate-y-0
                 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
      >
        <div class="flex items-center justify-center space-x-2">
          <span>Рассчитать налоги</span>
          <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
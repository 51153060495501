<script setup lang="ts">
import {ref} from 'vue';
import {CalculatorState, Page, ResidencyStatus} from "@/api/proto";
import countries from '@/data/countries.json';
import IntroPage from './pages/IntroPage.vue';
import FamilyPage from "@/pages/FamilyPage.vue";
import IncomePage from "@/pages/IncomePage.vue";
import TaxResidencyPage from "@/pages/TaxResidencyPage.vue";
import TRNoDTAPage from "@/pages/tax_residency/TRNoDTAPage.vue";
import TRDTAPage from "@/pages/tax_residency/TRDTAPage.vue";
import TRDaysPage from "@/pages/tax_residency/TRDaysPage.vue";
import TRFinancesPage from "@/pages/tax_residency/TRFinancesPage.vue";
import TRFamilyPage from "@/pages/tax_residency/TRFamilyPage.vue";
import TRConflict from "@/pages/tax_residency/TRConflictPage.vue";
import ComunidadPage from "@/pages/ComunidadPage.vue";
import TaxSpecialRegime from "@/pages/TaxSpecialRegimePage.vue";
import TaxVizkayaRegimePage from "@/pages/TaxVizkayaRegimePage.vue";
import IRNRPage from "@/pages/tax_residency/IRNRPage.vue";
import ExpensesPage from "@/pages/ExpensesPage.vue";
import {canApplySpecialRegime, needAutonomoForSpouse, titularWithAutonomo} from "@/data/residency";
import NewActivityPage from "@/pages/NewActivityPage.vue";
import FinalPage from "@/pages/FinalPage.vue";

const initializeState = () => {
  try {
    if (location.hash) {
      const hashData = location.hash.substring(1); // Remove the # symbol
      const binaryData = new Uint8Array(
          atob(hashData)
              .split('')
              .map(char => char.charCodeAt(0))
      );
      return CalculatorState.decode(binaryData);
    }
  } catch (error) {
    console.error('Failed to parse state from hash:', error);
  }

  // Return default state if hash parsing fails or hash is empty
  return new CalculatorState({
    residency: {
      status: ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT,
    },
    page: Page.P_INITIAL,
  });
};

const state = initializeState();
const page = ref<Page>(state.page);

const handlePageChange = (newPage: Page) => {
  console.log('state', state, newPage);

  if (newPage === Page.P_TAX_SPECIAL_REGIME) {
    if (!canApplySpecialRegime(state.residency!.status!)) {
      newPage = Page.P_EXPENSES;
    }
  }

  if (newPage == Page.P_NEW_ACTIVITY_DEDUCTION) {
    if (!needAutonomoForSpouse(state) && !titularWithAutonomo(state.residency!.status!)) {
      newPage = Page.P_FINAL;
    }
  }

  state.page = newPage;
  page.value = newPage;

  const body: Uint8Array = CalculatorState.encode(state).finish();
  const base64body = btoa(String.fromCharCode.apply(null, body));
  location.hash = base64body;
};

const handleTRNext = (nextPage: Page) => {
  if (state.taxResidency?.status === CalculatorState.TaxResidency.Status.TRS_RESIDENT) {
    handlePageChange(Page.P_TAX_SPECIAL_REGIME);
    return;
  }

  if (state.page === Page.P_TAX_RESIDENCY) {
    const country = countries.countries.find(c => c.code === state.taxResidency?.country);
    const hasDTA = country?.has_dta ?? false;
    handlePageChange(hasDTA ? Page.P_TAX_RESIDENCY_DTA : Page.P_TAX_RESIDENCY_NO_DTA);
    return;
  }

  // Skip if resident
  if (state.taxResidency?.status === CalculatorState.TaxResidency.Status.TRS_RESIDENT) {
    handlePageChange(Page.P_TAX_SPECIAL_REGIME);
    return;
  }

  // Skip family page if no family
  const hasFamily = !!(state.family?.spouse || (state.family?.children && state.family.children.length > 0));
  if (!hasFamily && nextPage === Page.P_TAX_RESIDENCY_FAMILY) {
    handlePageChange(Page.P_TAX_RESIDENCY_CONFLICT);
    return;
  }

  handlePageChange(nextPage);
};

const handleTRPrev = (prevPage: Page) => {
  // Skip family page if no family
  const hasFamily = !!(state.family?.spouse || (state.family?.children && state.family.children.length > 0));
  if (!hasFamily && prevPage === Page.P_TAX_RESIDENCY_FAMILY) {
    handlePageChange(Page.P_TAX_RESIDENCY_FINANCES);
    return;
  }

  handlePageChange(prevPage);
};
</script>

<template>
  <!-- Main flow -->
  <IntroPage
      v-if="page === Page.P_INITIAL"
      :state="state"
      @next="() => handlePageChange(Page.P_FAMILY)"
  />

  <FamilyPage
      v-else-if="page === Page.P_FAMILY"
      :state="state"
      @next="() => handlePageChange(Page.P_INCOME)"
      @prev="() => handlePageChange(Page.P_INITIAL)"
  />

  <IncomePage
      v-else-if="page === Page.P_INCOME"
      :state="state"
      @next="() => handlePageChange(Page.P_COMUNIDAD)"
      @prev="() => handlePageChange(Page.P_FAMILY)"
  />

  <ComunidadPage
      v-else-if="page === Page.P_COMUNIDAD"
      :state="state"
      @next="() => handlePageChange(Page.P_TAX_RESIDENCY)"
      @prev="() => handlePageChange(Page.P_INITIAL)"/>

  <TaxResidencyPage
      v-else-if="page === Page.P_TAX_RESIDENCY"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_DTA)"
      @prev="() => handlePageChange(Page.P_COMUNIDAD)"
  />

  <TaxSpecialRegime v-else-if="page === Page.P_TAX_SPECIAL_REGIME"
                    :state="state"
                    @prev="() => handlePageChange(Page.P_TAX_RESIDENCY)"
                    @next="() => handlePageChange(Page.P_VIZKAYA_SPECIAL_REGIME)"/>

  <TaxVizkayaRegimePage v-else-if="page === Page.P_VIZKAYA_SPECIAL_REGIME"
                        :state="state"
                        @prev="() => handlePageChange(Page.P_TAX_SPECIAL_REGIME)"
                        @next="() => handlePageChange(Page.P_EXPENSES)"/>

  <ExpensesPage v-else-if="page === Page.P_EXPENSES"
                :state="state"
                @next="() => handlePageChange(Page.P_NEW_ACTIVITY_DEDUCTION)"
                @prev="() => handlePageChange(Page.P_TAX_SPECIAL_REGIME)"/>

  <NewActivityPage v-else-if="page === Page.P_NEW_ACTIVITY_DEDUCTION"
                   :state="state"
                   @next="() => handlePageChange(Page.P_FINAL)"
                   @prev="() => handlePageChange(Page.P_EXPENSES)"/>

  <FinalPage v-else-if="page === Page.P_FINAL"
             :state="state"/>

  <!-- Tax residency flow -->
  <TRNoDTAPage
      v-else-if="page === Page.P_TAX_RESIDENCY_NO_DTA"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_DAYS)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY)"
  />

  <TRDTAPage
      v-else-if="page === Page.P_TAX_RESIDENCY_DTA"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_DAYS)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY)"
  />

  <TRDaysPage
      v-else-if="page === Page.P_TAX_RESIDENCY_DAYS"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_FINANCES)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY_DTA)"
  />

  <TRFinancesPage
      v-else-if="page === Page.P_TAX_RESIDENCY_FINANCES"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_FAMILY)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY_DAYS)"
  />

  <TRFamilyPage
      v-else-if="page === Page.P_TAX_RESIDENCY_FAMILY"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_RESIDENCY_CONFLICT)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY_FINANCES)"
  />

  <TRConflict
      v-else-if="page === Page.P_TAX_RESIDENCY_CONFLICT"
      :state="state"
      @next="() => handleTRNext(Page.P_IRNR)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY_FAMILY)"
  />

  <IRNRPage
      v-else-if="page === Page.P_IRNR"
      :state="state"
      @next="() => handleTRNext(Page.P_TAX_SPECIAL_REGIME)"
      @prev="() => handleTRPrev(Page.P_TAX_RESIDENCY_CONFLICT)"/>

</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleResident = () => {
  // Update parent state
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
  }
  emit('next');
};

const handleNonResident = () => {
  // Update parent state
  if (props.state.taxResidency) {
    props.state.taxResidency.agreeNoDta = true;
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Соглашение об избежании двойного налогообложения</h1>
    </div>

    <!-- Warning block -->
    <div class="bg-red-900/30 border-l-4 border-red-600 p-6 mb-8 rounded-r-lg">
      <div class="flex space-x-4">
        <div class="flex-shrink-0">
          <svg
              class="h-6 w-6 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="space-y-3">
          <p class="text-gray-200">
            Между выбранной страной и Испанией отсутствует соглашение об избежании двойного налогообложения.
          </p>
          <p class="text-gray-200">
            Даже если у вас есть сертификат налогового резидента, для Испании это не имеет значения.
          </p>
        </div>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Resident Option -->
      <button
          @click="handleResident"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">Считать как налогового резидента Испании</span>
          <span class="block text-sm text-blue-200">Рекомендуемый вариант</span>
        </div>
      </button>

      <!-- Non-resident Option -->
      <div class="relative">
        <div class="absolute -inset-1">
          <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
        </div>
        <button
            @click="handleNonResident"
            class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
        >
          <span class="block font-medium mb-1">
            Не смотря на это, все еще считаю себя нерезидентом Испании
          </span>
          <span class="block text-sm text-red-300">Высокий риск признания резидентом</span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {defineProps, defineEmits} from 'vue';
import {CalculatorState} from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleRegular = () => {
  if (props.state.special) {
    props.state.special.vizkaya = false;
  } else {
    props.state.special = {
      vizkaya: false
    };
  }
  emit('next');
};

const handleSpecial = () => {
  if (props.state.special) {
    props.state.special.vizkaya = true;
  } else {
    props.state.special = {
      vizkaya: true
    };
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Специальный налоговый режим Бискайи</h1>
    </div>

    <!-- Info block with links -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Источники:
      </p>
      <div class="flex flex-col space-y-3">
        <a href="https://www.bizkaia.eus/documents/880307/15250543/Manual+Renta+y+Patrimonio+2023.pdf"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Руководство Renta 2023
            </h4>
            <p class="text-sm">Официальное руководство по налогообложению в Бискайе, стр. 85</p>
          </div>
        </a>
        <a href="https://t.me/desmontaje_iberico/129"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Иберийские разборки
            </h4>
            <p class="text-sm">Подробный разбор налогового режима в канале Иберийские разборки</p>
          </div>
        </a>
        <a href="https://natural-stranger-03c.notion.site/Aut-nomo-Digital-Nomad-81e2f696f6b049489e2937848b66c583#c243ba4927844b4da68f1903146fc069"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Разбор от @denis_voskvitsov
            </h4>
            <p class="text-sm">Подробная документация в Notion</p>
          </div>
        </a>
      </div>
    </div>

    <!-- Requirements -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Требования</h2>
      <div class="space-y-6">
        <div class="space-y-3">
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Не быть налоговым резидентом Испании 5 лет до въезда</span>
          </div>
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>На момент переезда и каждый год ваша ситуация должна соответствовать требованиям статьи 56.bis закона Бискайи</span>
          </div>
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Надо быть налоговым резидентом в Бискайе</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Advantages -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Преимущества</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Длительный срок действия: 10 полных лет + год переезда</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Подходит всем: и наёмным работникам, и самозанятым</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Снижение налоговой базы на 30%</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Дополнительные вычеты, связанные с переездом в Бискайю:
            <ul class="ml-6 space-y-1">
              <li class="flex items-start">
                <span class="text-blue-400 mr-2">-</span>
                <span>Аренда жилья</span>
              </li>
              <li class="flex items-start">
                <span class="text-blue-400 mr-2">-</span>
                <span>Языковые курсы</span>
              </li>
              <li class="flex items-start">
                <span class="text-blue-400 mr-2">-</span>
                <span>Две поездки в год в страну, откуда переехали</span>
              </li>
              <li class="flex items-start">
                <span class="text-blue-400 mr-2">-</span>
                <span>Частные школы для детей</span>
              </li>
              <li class="flex items-start">
                <span class="text-blue-400 mr-2">-</span>
                <span>Общая сумма вычетов до 20% от налоговой базы</span>
              </li>
            </ul>
          </span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Можно комбинировать с другими налоговыми вычетами</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Так же доступен при переезде из любого региона Испании</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Гибкое включение/отключение режима в годовой декларации</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Если вы уже заплатили налог с дохода от имущества за пределами Испании, повторно платить не нужно</span>
        </li>
      </ul>
    </div>

    <!-- Limitations -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Особенности и ограничения</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Необходимо декларировать всё имущество и активы за пределами Испании</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Для применения данного режима самозанятым работником, он должен использовать общую систему налогообложения</span>
        </li>
      </ul>
    </div>

    <!-- Warning -->
    <div class="bg-yellow-900/30 border border-yellow-800/50 rounded-lg p-6">
      <div class="flex items-start space-x-3">
        <svg class="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <div class="space-y-2">
          <span class="font-semibold">Важно понимать:</span> Страна Басков имеет собственное налоговое законодательство,
          которое отличается от общеиспанского.
          <p>Описанные здесь условия относятся только к провинции Бискайя. В других провинциях Страны Басков могут
            действовать свои правила.</p>
        </div>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Special Regime Option -->
      <button
          @click="handleSpecial"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
                 transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
                 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
                 shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Добавить в расчет
          </span>
          <span class="block text-sm text-blue-200">
            Снижение налоговой базы на 30% + дополнительные вычеты
          </span>
        </div>
      </button>

      <!-- Regular Option -->
      <button
          @click="handleRegular"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-gray-800 hover:bg-gray-700 active:bg-gray-900
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-gray-800/30
               shadow-lg shadow-gray-800/20"
      >
        <div class="text-center">
        <span class="block font-medium text-white mb-1">
          Не добавлять в расчет
        </span>
          <span class="block text-sm text-gray-300">
          Не хочу жить на севере Испании
        </span>
        </div>
      </button>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors">
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors" fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24">
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-gray-800 rounded-lg p-6" }
const _hoisted_3 = { class: "space-y-4" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "bg-gray-800 rounded-lg p-6"
}
const _hoisted_8 = { class: "space-y-4" }
const _hoisted_9 = {
  key: 0,
  class: "mb-6 bg-pink-600/10 border border-pink-500/20 rounded-lg p-4"
}
const _hoisted_10 = { class: "relative" }
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 0,
  class: "space-y-2"
}
const _hoisted_13 = { class: "flex space-x-4" }
const _hoisted_14 = { class: "flex items-center space-x-2 cursor-pointer" }
const _hoisted_15 = { class: "flex items-center space-x-2 cursor-pointer" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex justify-between items-center mt-12 px-1" }
const _hoisted_18 = ["disabled"]

import {computed, onMounted, ref, watch} from 'vue';
import {CalculatorState, MoneyAmount, PersonIncome, ResidencyStatus} from '@/api/proto';
import CountrySelector from '@/components/CountrySelector.vue';
import Long from "long";


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomePage',
  props: {
    state: {}
  },
  emits: ["next", "prev"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Local state
const userAmount = ref<number | null>(null);
const spouseAmount = ref<number>(0);
const userContractDate = ref<string>('');
const spouseContractDate = ref<string>('');
const userEmployerCountry = ref('');
const spouseEmployerCountry = ref('');
const spouseEmploymentType = ref<'employed' | 'self-employed'>('self-employed');

const hasSpouse = computed(() => {
  return !!props.state.family?.spouse?.birthdate;
});

const showSpouseAdditionalFields = computed(() => {
  return spouseAmount.value > 0;
});

const showSpouseContractDate = computed(() => {
  return showSpouseAdditionalFields.value && spouseEmploymentType.value === 'self-employed';
});

const showEmploymentType = computed(() => {
  return spouseEmployerCountry.value === 'ES';
});

const shouldDefaultToSpain = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_WORK_VISA ||
      status === ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST ||
      status === ResidencyStatus.RS_CUENTA_AJENA ||
      status === ResidencyStatus.RS_CUENTA_PROPIA;
});

const showUserContractDate = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_STARTUP_AUTONOMO ||
      status === ResidencyStatus.RS_CUENTA_PROPIA ||
      status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT;
});

watch(spouseEmployerCountry, (newCountry) => {
  if (newCountry !== 'ES') {
    spouseEmploymentType.value = 'self-employed';
  }
});

onMounted(() => {
  let canChangeCountry = true;
  if (shouldDefaultToSpain.value) {
    userEmployerCountry.value = 'ES';
    canChangeCountry = false;
  }

  if (props.state.income) {
    if (props.state.income.user) {
      userAmount.value = Math.floor((props.state.income.user?.amount?.amount?.toNumber() || 0) / 100);
      if (showUserContractDate.value) {
        userContractDate.value = props.state.income.user?.contractDate || '';
      }
      if (canChangeCountry) {
        userEmployerCountry.value = props.state.income.user.employerCountry || '';
      }
    }
    if (props.state.income.spouse) {
      spouseAmount.value = Math.floor((props.state.income.spouse?.amount?.amount?.toNumber() || 0) / 100);
      spouseContractDate.value = props.state.income.spouse?.contractDate || '';
      spouseEmployerCountry.value = props.state.income.spouse?.employerCountry || '';
      spouseEmploymentType.value = props.state.income.spouse?.contractDate ? 'self-employed' : 'employed';
    }
  }
});

const formatIncome = (value: number | null): string => {
  if (value === null) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseIncome = (value: string): number | null => {
  if (!value) return null;
  const parsed = parseInt(value.replace(/\s/g, ''), 10);
  return isNaN(parsed) ? null : parsed;
};

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const createPersonIncome = (amount: number | null, contractDate: string, country: string): PersonIncome | undefined => {
  if (amount === null) return undefined;
  return {
    amount: createMoneyAmount(amount),
    contractDate: contractDate,
    employerCountry: country,
  };
};

const updateState = () => {
  props.state.income = {
    user: createPersonIncome(
        userAmount.value,
        showUserContractDate.value ? userContractDate.value : '',
        userEmployerCountry.value
    ),
    spouse: hasSpouse.value ? (
        spouseAmount.value > 0
            ? createPersonIncome(
                spouseAmount.value,
                spouseEmploymentType.value === 'self-employed' ? spouseContractDate.value : '',
                spouseEmployerCountry.value
            )
            : {amount: createMoneyAmount(0), contractDate: '', employerCountry: ''}
    ) : undefined
  };
};

const isValid = computed(() => {
  if (userAmount.value === null || userAmount.value < 0 || !userEmployerCountry.value) return false;

  if (showUserContractDate.value && !userContractDate.value) return false;

  if (hasSpouse.value && spouseAmount.value > 0) {
    if (!spouseEmployerCountry.value) return false;
    if (spouseEmploymentType.value === 'self-employed' && !spouseContractDate.value) return false;
  }

  return true;
});

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[22] || (_cache[22] = _createStaticVNode("<div class=\"space-y-4\"><h1 class=\"text-3xl font-bold\">Доходы</h1></div><div class=\"bg-blue-900/30 border border-blue-800/50 rounded-lg p-6\"><div class=\"space-y-4\"><p class=\"text-sm text-blue-100\"> В Испании вы платите налоги со всех доходов, полученных в любой стране мира </p><ul class=\"space-y-2\"><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Зарплата в иностранных компаниях</span></li><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Доходы от бизнеса в других странах</span></li><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Доходы от удаленной работы</span></li><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>...</span></li></ul></div></div>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"flex items-center space-x-4 mb-6\"><div class=\"p-3 bg-blue-600 rounded-lg\"><svg class=\"w-8 h-8\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z\"></path></svg></div><h3 class=\"text-xl font-semibold\">Ваш доход</h3></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _cache[9] || (_cache[9] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, " Годовой доход (EUR) ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              type: "text",
              value: formatIncome(userAmount.value),
              onInput: _cache[0] || (_cache[0] = e => userAmount.value = parseIncome((e.target as HTMLInputElement).value)),
              class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent",
              placeholder: "0"
            }, null, 40, _hoisted_5),
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
          ])
        ]),
        _createVNode(CountrySelector, {
          modelValue: userEmployerCountry.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userEmployerCountry).value = $event)),
          disabled: shouldDefaultToSpain.value,
          placeholder: "Выберите страну работодателя",
          "ring-color": "blue"
        }, null, 8, ["modelValue", "disabled"]),
        (showUserContractDate.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
                _createTextVNode(" Дата заключения контракта "),
                _createElementVNode("span", { class: "ml-2 text-xs text-gray-500" }, "(нужна для проверки возможных вычетов за начало деятельности)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: "date",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((userContractDate).value = $event)),
                class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              }, null, 512), [
                [_vModelText, userContractDate.value]
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (hasSpouse.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"flex items-center space-x-4 mb-6\"><div class=\"p-3 bg-pink-600 rounded-lg\"><svg class=\"w-8 h-8\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z\"></path></svg></div><h3 class=\"text-xl font-semibold\">Доход супруга/супруги</h3></div>", 1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              (spouseEmployerCountry.value && spouseEmployerCountry.value !== 'ES')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[12] || (_cache[12] = [
                    _createStaticVNode("<p class=\"text-sm text-pink-200\"> Учтите, что для удаленной работы супругу/супруге также требуется регистрация в системе социального страхования Испании </p><div class=\"space-y-3 mt-3\"><a href=\"https://www.boe.es/buscar/act.php?id=BOE-A-2015-11724\" class=\"flex items-start space-x-3 group\" target=\"_blank\" rel=\"noopener noreferrer\"><span class=\"text-sm text-pink-300 group-hover:text-pink-200 underline\"> Статья 136 (общий режим) </span></a><a href=\"https://www.boe.es/buscar/act.php?id=BOE-A-2015-11724\" class=\"flex items-start space-x-3 group\" target=\"_blank\" rel=\"noopener noreferrer\"><span class=\"text-sm text-pink-300 group-hover:text-pink-200 underline\"> Cтатья 305 (спецрежим аутономо) </span></a></div>", 2)
                  ])))
                : _createCommentVNode("", true),
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, " Годовой доход (EUR) ", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("input", {
                  type: "text",
                  value: formatIncome(spouseAmount.value),
                  onInput: _cache[3] || (_cache[3] = e => spouseAmount.value = parseIncome((e.target as HTMLInputElement).value) ?? 0),
                  class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent",
                  placeholder: "0"
                }, null, 40, _hoisted_11),
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
              ])
            ]),
            (showSpouseAdditionalFields.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(CountrySelector, {
                    modelValue: spouseEmployerCountry.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((spouseEmployerCountry).value = $event)),
                    placeholder: "Выберите страну работодателя",
                    "ring-color": "pink"
                  }, null, 8, ["modelValue"]),
                  (showEmploymentType.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400" }, " Тип занятости ", -1)),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("label", _hoisted_14, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((spouseEmploymentType).value = $event)),
                              value: "employed",
                              class: "w-4 h-4 text-pink-600 focus:ring-pink-500 border-gray-600 bg-gray-700"
                            }, null, 512), [
                              [_vModelRadio, spouseEmploymentType.value]
                            ]),
                            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-sm" }, "Работа в найме", -1))
                          ]),
                          _createElementVNode("label", _hoisted_15, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((spouseEmploymentType).value = $event)),
                              value: "self-employed",
                              class: "w-4 h-4 text-pink-600 focus:ring-pink-500 border-gray-600 bg-gray-700"
                            }, null, 512), [
                              [_vModelRadio, spouseEmploymentType.value]
                            ]),
                            _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-sm" }, "Self-employed", -1))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (showSpouseContractDate.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
                          _createTextVNode(" Дата заключения контракта "),
                          _createElementVNode("span", { class: "ml-2 text-xs text-gray-500" }, "(нужна для проверки возможных вычетов за начало деятельности)")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((spouseContractDate).value = $event)),
                          class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-focus:ring-pink-500 focus:border-transparent"
                        }, null, 512), [
                          [_vModelText, spouseContractDate.value]
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("button", {
        onClick: handlePrev,
        class: "group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      }, _cache[20] || (_cache[20] = [
        _createElementVNode("svg", {
          class: "w-5 h-5 text-gray-400 group-hover:text-white transition-colors",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 19l-7-7 7-7"
          })
        ], -1),
        _createElementVNode("span", { class: "text-gray-400 group-hover:text-white transition-colors" }, "Назад", -1)
      ])),
      _createElementVNode("button", {
        onClick: handleNext,
        disabled: !isValid.value,
        class: "group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600 disabled:hover:translate-y-0 disabled:hover:shadow-lg bg-blue-600 hover:bg-blue-500 active:bg-blue-700 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30 shadow-lg shadow-blue-500/20"
      }, _cache[21] || (_cache[21] = [
        _createElementVNode("span", { class: "text-white font-medium" }, "Далее", -1),
        _createElementVNode("svg", {
          class: "w-5 h-5 text-white",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M9 5l7 7-7 7"
          })
        ], -1)
      ]), 8, _hoisted_18)
    ])
  ]))
}
}

})
<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import { CalculatorState, ComunidadAutonoma } from '@/api/proto';
import { getAllRegions, getRegionDetails } from '@/data/comunidades';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Local state
const selectedRegion = ref<ComunidadAutonoma | null>(null);
const excludedRegions = ref<Set<ComunidadAutonoma>>(new Set());
const regions = getAllRegions();

// Initialize state on mount
onMounted(() => {
  if (props.state.region) {
    selectedRegion.value = props.state.region.selected;
    if (props.state.region.excluded) {
      excludedRegions.value = new Set(props.state.region.excluded);
    }
  }
});

// Update state when region selection changes
const updateState = () => {
  props.state.region = {
    selected: selectedRegion.value,
    excluded: Array.from(excludedRegions.value)
  };
  console.log(props.state);
};

const selectRegion = (region: ComunidadAutonoma) => {
  selectedRegion.value = region;
  // Remove the selected region from excluded list if it was there
  if (excludedRegions.value.has(region)) {
    excludedRegions.value.delete(region);
  }
  updateState();
};

const toggleExclusion = (region: ComunidadAutonoma, event: Event) => {
  event.stopPropagation(); // Prevent region selection when clicking the toggle
  if (region === selectedRegion.value) return; // Can't exclude selected region

  if (excludedRegions.value.has(region)) {
    excludedRegions.value.delete(region);
  } else {
    excludedRegions.value.add(region);
  }
  updateState();
};

// Validation for navigation
const isValid = computed(() => {
  return selectedRegion.value !== null;
});

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

const hasChildren = computed(() => {
  return props.state.family?.children && props.state.family.children.length > 0;
});

</script>

<template>
  <!-- Template remains the same as in previous version -->
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Выбор comunidad autónoma</h1>
    </div>

    <!-- Info block -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 mb-8">
      <div class="flex items-start space-x-3">
        <div class="p-2 bg-blue-600/20 rounded-lg">
          <svg class="w-6 h-6 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
        </div>
        <div class="text-sm text-blue-100 space-y-2">
          <p>Налоговые ставки а также даже налоговое законодательство - отличаются в разных регионах Испании.</p>
          <p>Выберите наиболее интересный регион/где живете, мы используем это для сравнения с другими.</p>
          <p v-if="hasChildren">Так как у вас есть дети, мы добавили информацию по языкам, которые они возможно будут учить в школе.
            Например в Барселоне даже в частной Британской школе будет объязательный к изучению Каталанский язык,
            это может повлиять на ваш выбор.</p>
          <p class="pt-2 border-t border-blue-800/50">Вы можете исключить регионы из сравнения, нажав на значок глаза.</p>
        </div>
      </div>
    </div>

    <!-- Regions Grid -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
      <div v-for="region in regions"
           :key="region"
           @click="selectRegion(region)"
           class="bg-gray-800 p-6 rounded-lg cursor-pointer transition-all duration-200 hover:bg-gray-700
                  border-2 relative"
           :class="[
             selectedRegion === region
               ? 'border-blue-500 shadow-lg shadow-blue-500/20'
               : excludedRegions.has(region)
                 ? 'border-red-500/50 opacity-50'
                 : 'border-transparent'
           ]">
        <!-- Exclude toggle -->
        <button
            v-if="region !== selectedRegion"
            @click="toggleExclusion(region, $event)"
            class="absolute top-4 right-4 p-1.5 rounded-lg transition-colors"
            :class="excludedRegions.has(region) ? 'bg-red-500/20 text-red-400' : 'hover:bg-gray-600/50 text-gray-400'">
          <svg v-if="!excludedRegions.has(region)" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg>
          <svg v-else class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
          </svg>
        </button>

        <div class="space-y-4">
          <h3 class="text-xl font-semibold pr-8">{{ getRegionDetails(region).name }}</h3>

          <div class="space-y-2">
            <div class="flex items-center space-x-2">
              <svg class="w-5 h-5 text-blue-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"/>
              </svg>
              <span class="text-sm text-gray-400">
                {{ getRegionDetails(region).languages.join(', ') }}
              </span>
            </div>

            <div class="flex items-center space-x-2">
              <svg class="w-5 h-5 text-green-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
              </svg>
              <span class="text-sm text-gray-400">{{ getRegionDetails(region).capital }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button @click="handlePrev"
              class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors">
        <svg class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
             fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button @click="handleNext"
              :disabled="!isValid"
              class="group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200
                     disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600
                     disabled:hover:translate-y-0 disabled:hover:shadow-lg
                     bg-blue-600 hover:bg-blue-500 active:bg-blue-700
                     hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
                     shadow-lg shadow-blue-500/20">
        <span class="text-white font-medium">Далее</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Local state
const userBirthdate = ref<string | null>(null);
const hasSpouse = ref(false);
const spouseBirthdate = ref<string | null>(null);
const children = ref<{ birthdate: string | null }[]>([]);

// Initialize states on mount
onMounted(() => {
  if (props.state.family) {
    userBirthdate.value = props.state.family.userBirthdate;
    if (props.state.family.spouse) {
      hasSpouse.value = true;
      spouseBirthdate.value = props.state.family.spouse.birthdate;
    }
    if (props.state.family.children && props.state.family.children.length > 0) {
      children.value = props.state.family.children.map(child => ({
        birthdate: child.birthdate
      }));
    }
  }
});

// Update state whenever family information changes
const updateState = () => {
  props.state.family = {
    userBirthdate: userBirthdate.value,
    spouse: hasSpouse.value ? {
      birthdate: spouseBirthdate.value
    } : undefined,
    children: children.value
  };
  console.log(props.state);
};

const toggleSpouse = () => {
  hasSpouse.value = !hasSpouse.value;
  if (!hasSpouse.value) {
    spouseBirthdate.value = null;
  }
  updateState();
};

const toggleChildren = () => {
  if (children.value.length === 0) {
    children.value.push({ birthdate: null });
  } else {
    children.value = [];
  }
  updateState();
};

const addChild = () => {
  children.value.push({ birthdate: null });
  updateState();
};

const removeChild = (index: number) => {
  children.value.splice(index, 1);
  updateState();
};

// Validation for navigation
const isValid = computed(() => {
  // User birthdate is required
  if (!userBirthdate.value) {
    return false;
  }

  // If spouse is added, birthdate is required
  if (hasSpouse.value && !spouseBirthdate.value) {
    return false;
  }

  // If children are added, all birthdates are required
  if (children.value.length > 0) {
    return children.value.every(child => child.birthdate !== null);
  }

  return true;
});

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

const onBirthdateChange = () => {
  updateState();
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Семейное положение</h1>
    </div>

    <!-- Info block -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 mb-8">
      <div class="space-y-4">
        <p class="text-sm text-blue-100">
          Дата рождения и состав семьи требуется для определения возможных семейных вычетов.
        </p>

        <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2006-20764#a57"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Ley de IRPF Статья 57: Mínimo del contribuyente.
            </h4>
            <p class="text-sm">Подробнее о базовом налоговом вычете налогоплательщика.</p>
          </div>
        </a>

        <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2006-20764#a58"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Ley de IRPF Статья 58: Mínimo por descendientes.
            </h4>
            <p class="text-sm">Подробнее о базовом налоговом вычете налогоплательщика за членов семьи.</p>
          </div>
        </a>
      </div>
    </div>

    <!-- User birthdate -->
    <div class="bg-gray-800 p-6 rounded-lg">
      <div class="flex items-center space-x-4 mb-6">
        <div class="p-3 bg-blue-600 rounded-lg">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
        </div>
        <h3 class="text-xl font-semibold">Вы</h3>
      </div>
      <div>
        <label class="block text-sm font-medium text-gray-400 mb-2">Дата рождения</label>
        <input type="date"
               v-model="userBirthdate"
               @change="onBirthdateChange"
               class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                      focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
      </div>
    </div>

    <!-- Spouse section -->
    <div class="bg-gray-800 p-6 rounded-lg space-y-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <div class="p-3 bg-pink-600 rounded-lg">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
            </svg>
          </div>
          <h3 class="text-xl font-semibold">Супруг(а)</h3>
        </div>
        <button @click="toggleSpouse"
                class="flex items-center space-x-2 text-gray-400 hover:text-white transition-colors">
          <svg v-if="!hasSpouse" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <svg v-else class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
          <span>{{ hasSpouse ? 'Удалить' : 'Добавить' }}</span>
        </button>
      </div>

      <div v-if="hasSpouse" class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-gray-400 mb-2">Дата рождения</label>
          <input type="date"
                 v-model="spouseBirthdate"
                 @change="onBirthdateChange"
                 class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                      focus:ring-2 focus:ring-pink-500 focus:border-transparent" />
        </div>
      </div>
    </div>

    <!-- Children section - updated with p-6 padding on outer div -->
    <div class="bg-gray-800 p-6 rounded-lg space-y-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <div class="p-3 bg-green-600 rounded-lg">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div>
          <h3 class="text-xl font-semibold">Дети</h3>
        </div>
        <button @click="toggleChildren"
                class="flex items-center space-x-2 text-gray-400 hover:text-white transition-colors">
          <svg v-if="children.length === 0" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <svg v-else class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
          <span>{{ children.length === 0 ? 'Добавить' : 'Удалить' }}</span>
        </button>
      </div>

      <div v-if="children.length > 0" class="space-y-4">
        <div v-for="(child, index) in children"
             :key="index"
             class="py-6 first:pt-0 last:pb-0 border-b border-gray-700 last:border-0">
          <div class="space-y-4">
            <div class="flex justify-between items-center">
              <h4 class="font-medium">Ребёнок {{ index + 1 }}</h4>
              <button @click="removeChild(index)"
                      class="text-gray-400 hover:text-white transition-colors">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-400 mb-2">Дата рождения</label>
              <input type="date"
                     v-model="child.birthdate"
                     @change="onBirthdateChange"
                     class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                          focus:ring-2 focus:ring-green-500 focus:border-transparent" />
            </div>
          </div>
        </div>

        <button @click="addChild"
                class="w-full flex items-center justify-center space-x-2 py-3 bg-gray-800 rounded-lg
                     text-gray-400 hover:text-white hover:bg-gray-700 transition-colors">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          <span>Добавить ещё</span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button @click="handlePrev"
              class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors">
        <svg class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
             fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button @click="handleNext"
              :disabled="!isValid"
              class="group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200
                 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600
                 disabled:hover:translate-y-0 disabled:hover:shadow-lg
                 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
                 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
                 shadow-lg shadow-blue-500/20">
        <span class="text-white font-medium">Далее</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
  </div>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 mb-8" }
const _hoisted_3 = { class: "flex items-start space-x-3" }
const _hoisted_4 = { class: "text-sm text-blue-100 space-y-2" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "w-5 h-5",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24"
}
const _hoisted_10 = {
  key: 1,
  class: "w-5 h-5",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24"
}
const _hoisted_11 = { class: "space-y-4" }
const _hoisted_12 = { class: "text-xl font-semibold pr-8" }
const _hoisted_13 = { class: "space-y-2" }
const _hoisted_14 = { class: "flex items-center space-x-2" }
const _hoisted_15 = { class: "text-sm text-gray-400" }
const _hoisted_16 = { class: "flex items-center space-x-2" }
const _hoisted_17 = { class: "text-sm text-gray-400" }
const _hoisted_18 = { class: "flex justify-between items-center mt-12 px-1" }
const _hoisted_19 = ["disabled"]

import { ref, computed, onMounted } from 'vue';
import { CalculatorState, ComunidadAutonoma } from '@/api/proto';
import { getAllRegions, getRegionDetails } from '@/data/comunidades';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComunidadPage',
  props: {
    state: {}
  },
  emits: ["next", "prev"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Local state
const selectedRegion = ref<ComunidadAutonoma | null>(null);
const excludedRegions = ref<Set<ComunidadAutonoma>>(new Set());
const regions = getAllRegions();

// Initialize state on mount
onMounted(() => {
  if (props.state.region) {
    selectedRegion.value = props.state.region.selected;
    if (props.state.region.excluded) {
      excludedRegions.value = new Set(props.state.region.excluded);
    }
  }
});

// Update state when region selection changes
const updateState = () => {
  props.state.region = {
    selected: selectedRegion.value,
    excluded: Array.from(excludedRegions.value)
  };
  console.log(props.state);
};

const selectRegion = (region: ComunidadAutonoma) => {
  selectedRegion.value = region;
  // Remove the selected region from excluded list if it was there
  if (excludedRegions.value.has(region)) {
    excludedRegions.value.delete(region);
  }
  updateState();
};

const toggleExclusion = (region: ComunidadAutonoma, event: Event) => {
  event.stopPropagation(); // Prevent region selection when clicking the toggle
  if (region === selectedRegion.value) return; // Can't exclude selected region

  if (excludedRegions.value.has(region)) {
    excludedRegions.value.delete(region);
  } else {
    excludedRegions.value.add(region);
  }
  updateState();
};

// Validation for navigation
const isValid = computed(() => {
  return selectedRegion.value !== null;
});

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

const hasChildren = computed(() => {
  return props.state.family?.children && props.state.family.children.length > 0;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "space-y-4" }, [
      _createElementVNode("h1", { class: "text-3xl font-bold" }, "Выбор comunidad autónoma")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "p-2 bg-blue-600/20 rounded-lg" }, [
          _createElementVNode("svg", {
            class: "w-6 h-6 text-blue-400",
            fill: "none",
            stroke: "currentColor",
            viewBox: "0 0 24 24"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Налоговые ставки а также даже налоговое законодательство - отличаются в разных регионах Испании.", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Выберите наиболее интересный регион/где живете, мы используем это для сравнения с другими.", -1)),
          (hasChildren.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Так как у вас есть дети, мы добавили информацию по языкам, которые они возможно будут учить в школе. Например в Барселоне даже в частной Британской школе будет объязательный к изучению Каталанский язык, это может повлиять на ваш выбор."))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "pt-2 border-t border-blue-800/50" }, "Вы можете исключить регионы из сравнения, нажав на значок глаза.", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(regions), (region) => {
        return (_openBlock(), _createElementBlock("div", {
          key: region,
          onClick: ($event: any) => (selectRegion(region)),
          class: _normalizeClass(["bg-gray-800 p-6 rounded-lg cursor-pointer transition-all duration-200 hover:bg-gray-700 border-2 relative", [
             selectedRegion.value === region
               ? 'border-blue-500 shadow-lg shadow-blue-500/20'
               : excludedRegions.value.has(region)
                 ? 'border-red-500/50 opacity-50'
                 : 'border-transparent'
           ]])
        }, [
          (region !== selectedRegion.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: ($event: any) => (toggleExclusion(region, $event)),
                class: _normalizeClass(["absolute top-4 right-4 p-1.5 rounded-lg transition-colors", excludedRegions.value.has(region) ? 'bg-red-500/20 text-red-400' : 'hover:bg-gray-600/50 text-gray-400'])
              }, [
                (!excludedRegions.value.has(region))
                  ? (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[4] || (_cache[4] = [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      }, null, -1),
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      }, null, -1)
                    ])))
                  : (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[5] || (_cache[5] = [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      }, null, -1)
                    ])))
              ], 10, _hoisted_8))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h3", _hoisted_12, _toDisplayString(_unref(getRegionDetails)(region).name), 1),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[6] || (_cache[6] = _createElementVNode("svg", {
                  class: "w-5 h-5 text-blue-400 flex-shrink-0",
                  fill: "none",
                  stroke: "currentColor",
                  viewBox: "0 0 24 24"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                  })
                ], -1)),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(getRegionDetails)(region).languages.join(', ')), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[7] || (_cache[7] = _createElementVNode("svg", {
                  class: "w-5 h-5 text-green-400 flex-shrink-0",
                  fill: "none",
                  stroke: "currentColor",
                  viewBox: "0 0 24 24"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  }),
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  })
                ], -1)),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(getRegionDetails)(region).capital), 1)
              ])
            ])
          ])
        ], 10, _hoisted_7))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("button", {
        onClick: handlePrev,
        class: "group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("svg", {
          class: "w-5 h-5 text-gray-400 group-hover:text-white transition-colors",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 19l-7-7 7-7"
          })
        ], -1),
        _createElementVNode("span", { class: "text-gray-400 group-hover:text-white transition-colors" }, "Назад", -1)
      ])),
      _createElementVNode("button", {
        onClick: handleNext,
        disabled: !isValid.value,
        class: "group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600 disabled:hover:translate-y-0 disabled:hover:shadow-lg bg-blue-600 hover:bg-blue-500 active:bg-blue-700 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30 shadow-lg shadow-blue-500/20"
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("span", { class: "text-white font-medium" }, "Далее", -1),
        _createElementVNode("svg", {
          class: "w-5 h-5 text-white",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M9 5l7 7-7 7"
          })
        ], -1)
      ]), 8, _hoisted_19)
    ])
  ]))
}
}

})
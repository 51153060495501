<script setup lang="ts">
import {computed, defineEmits, defineProps, onMounted, ref, watch} from 'vue';
import {CalculatorState, MoneyAmount, PersonIncome, ResidencyStatus} from '@/api/proto';
import CountrySelector from '@/components/CountrySelector.vue';
import Long from "long";

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Local state
const userAmount = ref<number | null>(null);
const spouseAmount = ref<number>(0);
const userContractDate = ref<string>('');
const spouseContractDate = ref<string>('');
const userEmployerCountry = ref('');
const spouseEmployerCountry = ref('');
const spouseEmploymentType = ref<'employed' | 'self-employed'>('self-employed');

const hasSpouse = computed(() => {
  return !!props.state.family?.spouse?.birthdate;
});

const showSpouseAdditionalFields = computed(() => {
  return spouseAmount.value > 0;
});

const showSpouseContractDate = computed(() => {
  return showSpouseAdditionalFields.value && spouseEmploymentType.value === 'self-employed';
});

const showEmploymentType = computed(() => {
  return spouseEmployerCountry.value === 'ES';
});

const shouldDefaultToSpain = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_WORK_VISA ||
      status === ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST ||
      status === ResidencyStatus.RS_CUENTA_AJENA ||
      status === ResidencyStatus.RS_CUENTA_PROPIA;
});

const showUserContractDate = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_STARTUP_AUTONOMO ||
      status === ResidencyStatus.RS_CUENTA_PROPIA ||
      status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT;
});

watch(spouseEmployerCountry, (newCountry) => {
  if (newCountry !== 'ES') {
    spouseEmploymentType.value = 'self-employed';
  }
});

onMounted(() => {
  let canChangeCountry = true;
  if (shouldDefaultToSpain.value) {
    userEmployerCountry.value = 'ES';
    canChangeCountry = false;
  }

  if (props.state.income) {
    if (props.state.income.user) {
      userAmount.value = Math.floor((props.state.income.user?.amount?.amount?.toNumber() || 0) / 100);
      if (showUserContractDate.value) {
        userContractDate.value = props.state.income.user?.contractDate || '';
      }
      if (canChangeCountry) {
        userEmployerCountry.value = props.state.income.user.employerCountry || '';
      }
    }
    if (props.state.income.spouse) {
      spouseAmount.value = Math.floor((props.state.income.spouse?.amount?.amount?.toNumber() || 0) / 100);
      spouseContractDate.value = props.state.income.spouse?.contractDate || '';
      spouseEmployerCountry.value = props.state.income.spouse?.employerCountry || '';
      spouseEmploymentType.value = props.state.income.spouse?.contractDate ? 'self-employed' : 'employed';
    }
  }
});

const formatIncome = (value: number | null): string => {
  if (value === null) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseIncome = (value: string): number | null => {
  if (!value) return null;
  const parsed = parseInt(value.replace(/\s/g, ''), 10);
  return isNaN(parsed) ? null : parsed;
};

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const createPersonIncome = (amount: number | null, contractDate: string, country: string): PersonIncome | undefined => {
  if (amount === null) return undefined;
  return {
    amount: createMoneyAmount(amount),
    contractDate: contractDate,
    employerCountry: country,
  };
};

const updateState = () => {
  props.state.income = {
    user: createPersonIncome(
        userAmount.value,
        showUserContractDate.value ? userContractDate.value : '',
        userEmployerCountry.value
    ),
    spouse: hasSpouse.value ? (
        spouseAmount.value > 0
            ? createPersonIncome(
                spouseAmount.value,
                spouseEmploymentType.value === 'self-employed' ? spouseContractDate.value : '',
                spouseEmployerCountry.value
            )
            : {amount: createMoneyAmount(0), contractDate: '', employerCountry: ''}
    ) : undefined
  };
};

const isValid = computed(() => {
  if (userAmount.value === null || userAmount.value < 0 || !userEmployerCountry.value) return false;

  if (showUserContractDate.value && !userContractDate.value) return false;

  if (hasSpouse.value && spouseAmount.value > 0) {
    if (!spouseEmployerCountry.value) return false;
    if (spouseEmploymentType.value === 'self-employed' && !spouseContractDate.value) return false;
  }

  return true;
});

const handleNext = () => {
  if (isValid.value) {
    updateState();
    emit('next');
  }
};

const handlePrev = () => {
  updateState();
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Доходы</h1>
    </div>

    <!-- Info block -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6">
      <div class="space-y-4">
        <p class="text-sm text-blue-100">
          В Испании вы платите налоги со всех доходов, полученных в любой стране мира
        </p>
        <ul class="space-y-2">
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Зарплата в иностранных компаниях</span>
          </li>
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Доходы от бизнеса в других странах</span>
          </li>
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>Доходы от удаленной работы</span>
          </li>
          <li class="flex items-start">
            <svg class="w-4 h-4 mt-1 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
            </svg>
            <span>...</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- User income form -->
    <div class="bg-gray-800 rounded-lg p-6">
      <div class="flex items-center space-x-4 mb-6">
        <div class="p-3 bg-blue-600 rounded-lg">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
        </div>
        <h3 class="text-xl font-semibold">Ваш доход</h3>
      </div>
      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-gray-400 mb-2">
            Годовой доход (EUR)
          </label>
          <div class="relative">
            <input
                type="text"
                :value="formatIncome(userAmount)"
                @input="e => userAmount = parseIncome((e.target as HTMLInputElement).value)"
                class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                   focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="0"
            />
            <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
          </div>
        </div>
        <CountrySelector
            v-model="userEmployerCountry"
            :disabled="shouldDefaultToSpain"
            placeholder="Выберите страну работодателя"
            ring-color="blue"
        />
        <div v-if="showUserContractDate">
          <label class="block text-sm font-medium text-gray-400 mb-2">
            Дата заключения контракта
            <span class="ml-2 text-xs text-gray-500">(нужна для проверки возможных вычетов за начало деятельности)</span>
          </label>
          <input
              type="date"
              v-model="userContractDate"
              class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>
    </div>

    <!-- Spouse income form -->
    <div v-if="hasSpouse" class="bg-gray-800 rounded-lg p-6">
      <div class="flex items-center space-x-4 mb-6">
        <div class="p-3 bg-pink-600 rounded-lg">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
          </svg>
        </div>
        <h3 class="text-xl font-semibold">Доход супруга/супруги</h3>
      </div>
      <div class="space-y-4">
        <div>
          <!-- Info block for Spain -->
          <div v-if="spouseEmployerCountry && spouseEmployerCountry !== 'ES'" class="mb-6 bg-pink-600/10 border border-pink-500/20 rounded-lg p-4">
            <p class="text-sm text-pink-200">
              Учтите, что для удаленной работы супругу/супруге также требуется регистрация в системе социального страхования Испании
            </p>
            <div class="space-y-3 mt-3">
              <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2015-11724"
                 class="flex items-start space-x-3 group"
                 target="_blank"
                 rel="noopener noreferrer">
              <span class="text-sm text-pink-300 group-hover:text-pink-200 underline">
                Статья 136 (общий режим)
              </span>
              </a>
              <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2015-11724"
                 class="flex items-start space-x-3 group"
                 target="_blank"
                 rel="noopener noreferrer">
              <span class="text-sm text-pink-300 group-hover:text-pink-200 underline">
                Cтатья 305 (спецрежим аутономо)
              </span>
              </a>
            </div>
          </div>

          <label class="block text-sm font-medium text-gray-400 mb-2">
            Годовой доход (EUR)
          </label>
          <div class="relative">
            <input
                type="text"
                :value="formatIncome(spouseAmount)"
                @input="e => spouseAmount = parseIncome((e.target as HTMLInputElement).value) ?? 0"
                class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                 focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                placeholder="0"
            />
            <span class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400">EUR</span>
          </div>
        </div>

        <!-- Additional fields only shown if income > 0 -->
        <template v-if="showSpouseAdditionalFields">
          <CountrySelector
              v-model="spouseEmployerCountry"
              placeholder="Выберите страну работодателя"
              ring-color="pink"
          />

          <div v-if="showEmploymentType" class="space-y-2">
            <label class="block text-sm font-medium text-gray-400">
              Тип занятости
            </label>
            <div class="flex space-x-4">
              <label class="flex items-center space-x-2 cursor-pointer">
                <input
                    type="radio"
                    v-model="spouseEmploymentType"
                    value="employed"
                    class="w-4 h-4 text-pink-600 focus:ring-pink-500 border-gray-600 bg-gray-700"
                />
                <span class="text-sm">Работа в найме</span>
              </label>
              <label class="flex items-center space-x-2 cursor-pointer">
                <input
                    type="radio"
                    v-model="spouseEmploymentType"
                    value="self-employed"
                    class="w-4 h-4 text-pink-600 focus:ring-pink-500 border-gray-600 bg-gray-700"
                />
                <span class="text-sm">Self-employed</span>
              </label>
            </div>
          </div>

          <div v-if="showSpouseContractDate">
            <label class="block text-sm font-medium text-gray-400 mb-2">
              Дата заключения контракта
              <span class="ml-2 text-xs text-gray-500">(нужна для проверки возможных вычетов за начало деятельности)</span>
            </label>
            <input
                type="date"
                v-model="spouseContractDate"
                class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                   focus:ring-focus:ring-pink-500 focus:border-transparent"
            />
          </div>
        </template>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
             fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>

      <button
          @click="handleNext"
          :disabled="!isValid"
          class="group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200
               disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-600
               disabled:hover:translate-y-0 disabled:hover:shadow-lg
               bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <span class="text-white font-medium">Далее</span>
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </button>
    </div>
  </div>
</template>
import {ComunidadAutonoma} from "@/api/proto";
import * as CA_ANDALUCIA from "./comunidades/ANDALUCIA";
import * as CA_ARAGON from "./comunidades/ARAGON";
import * as CA_ASTURIAS from "./comunidades/ASTURIAS";
import * as CA_BALEARIC_ISLANDS from "./comunidades/BALEARIC_ISLANDS";
import * as CA_BASQUE_COUNTRY from "./comunidades/BASQUE_COUNTRY";
import * as CA_CANARY_ISLANDS from "./comunidades/CANARY_ISLANDS";
import * as CA_CANTABRIA from "./comunidades/CANTABRIA";
import * as CA_CASTILE_LA_MANCHA from "./comunidades/CASTILE_LA_MANCHA";
import * as CA_CASTILE_AND_LEON from "./comunidades/CASTILE_AND_LEON";
import * as CA_CATALONIA from "./comunidades/CATALONIA";
import * as CA_EXTREMADURA from "./comunidades/EXTREMADURA";
import * as CA_GALICIA from "./comunidades/GALICIA";
import * as CA_LA_RIOJA from "./comunidades/LA_RIOJA";
import * as CA_MADRID from "./comunidades/MADRID";
import * as CA_MURCIA from "./comunidades/MURCIA";
import * as CA_NAVARRE from "./comunidades/NAVARRE";
import * as CA_VALENCIA from "./comunidades/VALENCIA";
import {TaxBracket} from "@/data/taxes";

export interface RegionDetails {
    name: string;
    languages: string[];
    capital: string;
}

export const getFederalTax = (region: ComunidadAutonoma): Array<TaxBracket> => {
    // Check for regions with their own tax systems ("régimen foral")
    if (region === ComunidadAutonoma.CA_BASQUE_COUNTRY ||
        region === ComunidadAutonoma.CA_NAVARRE) {
        return [];
    }

    return [
        {
            from: 0,
            to: 12450,
            rate: 0.095
        },
        {
            from: 12450,
            to: 20200,
            rate: 0.12
        },
        {
            from: 20200,
            to: 35200,
            rate: 0.15
        },
        {
            from: 35200,
            to: 60000,
            rate: 0.185
        },
        {
            from: 60000,
            to: 300000,
            rate: 0.225
        },
        {
            from: 300000,
            to: 1000000,
            rate: 0.245
        }
    ];
};

export const getLocalTax = (region: ComunidadAutonoma) => {
    switch (region) {
        case ComunidadAutonoma.CA_ANDALUCIA:
            return CA_ANDALUCIA.localBrackets;
        case ComunidadAutonoma.CA_ARAGON:
            return CA_ARAGON.localBrackets;
        case ComunidadAutonoma.CA_ASTURIAS:
            return CA_ASTURIAS.localBrackets;
        case ComunidadAutonoma.CA_BALEARIC_ISLANDS:
            return CA_BALEARIC_ISLANDS.localBrackets;
        case ComunidadAutonoma.CA_BASQUE_COUNTRY:
            return CA_BASQUE_COUNTRY.localBrackets;
        case ComunidadAutonoma.CA_CANARY_ISLANDS:
            return CA_CANARY_ISLANDS.localBrackets;
        case ComunidadAutonoma.CA_CANTABRIA:
            return CA_CANTABRIA.localBrackets;
        case ComunidadAutonoma.CA_CASTILE_LA_MANCHA:
            return CA_CASTILE_LA_MANCHA.localBrackets;
        case ComunidadAutonoma.CA_CASTILE_AND_LEON:
            return CA_CASTILE_AND_LEON.localBrackets;
        case ComunidadAutonoma.CA_CATALONIA:
            return CA_CATALONIA.localBrackets;
        case ComunidadAutonoma.CA_EXTREMADURA:
            return CA_EXTREMADURA.localBrackets;
        case ComunidadAutonoma.CA_GALICIA:
            return CA_GALICIA.localBrackets;
        case ComunidadAutonoma.CA_LA_RIOJA:
            return CA_LA_RIOJA.localBrackets;
        case ComunidadAutonoma.CA_MADRID:
            return CA_MADRID.localBrackets;
        case ComunidadAutonoma.CA_MURCIA:
            return CA_MURCIA.localBrackets;
        case ComunidadAutonoma.CA_NAVARRE:
            return CA_NAVARRE.localBrackets;
        case ComunidadAutonoma.CA_VALENCIA:
            return CA_VALENCIA.localBrackets;
    }
};

export const getRegionDetails = (region: ComunidadAutonoma): RegionDetails => {
    switch (region) {
        case ComunidadAutonoma.CA_ANDALUCIA:
            return {
                name: "Андалусия",
                languages: ["Испанский"],
                capital: "Севилья",
            };

        case ComunidadAutonoma.CA_ARAGON:
            return {
                name: "Арагон",
                languages: ["Арагонский", "Испанский"],
                capital: "Сарагоса",
            };

        case ComunidadAutonoma.CA_ASTURIAS:
            return {
                name: "Астурия",
                languages: ["Астурийский", "Испанский"],
                capital: "Овьедо",
            };

        case ComunidadAutonoma.CA_BALEARIC_ISLANDS:
            return {
                name: "Балеарские острова",
                languages: ["Каталанский", "Испанский"],
                capital: "Пальма",
            };

        case ComunidadAutonoma.CA_BASQUE_COUNTRY:
            return {
                name: "Страна Басков",
                languages: ["Баскский", "Испанский"],
                capital: "Витория-Гастейс (неофициальная - Бильбао)",
            };

        case ComunidadAutonoma.CA_CANARY_ISLANDS:
            return {
                name: "Канарские острова",
                languages: ["Испанский"],
                capital: "Лас-Пальмас и Санта-Крус-де-Тенерифе",
            };

        case ComunidadAutonoma.CA_CANTABRIA:
            return {
                name: "Кантабрия",
                languages: ["Испанский"],
                capital: "Сантандер",
            };

        case ComunidadAutonoma.CA_CASTILE_LA_MANCHA:
            return {
                name: "Кастилия-Ла-Манча",
                languages: ["Испанский"],
                capital: "Толедо",
            };

        case ComunidadAutonoma.CA_CASTILE_AND_LEON:
            return {
                name: "Кастилия и Леон",
                languages: ["Испанский"],
                capital: "Вальядолид",
            };

        case ComunidadAutonoma.CA_CATALONIA:
            return {
                name: "Каталония",
                languages: ["Каталанский", "Испанский"],
                capital: "Барселона",
            };

        case ComunidadAutonoma.CA_EXTREMADURA:
            return {
                name: "Эстремадура",
                languages: ["Испанский"],
                capital: "Мерида",
            };

        case ComunidadAutonoma.CA_GALICIA:
            return {
                name: "Галисия",
                languages: ["Галисийский", "Испанский"],
                capital: "Сантьяго-де-Компостела",
            };

        case ComunidadAutonoma.CA_LA_RIOJA:
            return {
                name: "Ла-Риоха",
                languages: ["Испанский"],
                capital: "Логроньо",
            };

        case ComunidadAutonoma.CA_MADRID:
            return {
                name: "Мадрид",
                languages: ["Испанский"],
                capital: "Мадрид",
            };

        case ComunidadAutonoma.CA_MURCIA:
            return {
                name: "Мурсия",
                languages: ["Испанский"],
                capital: "Мурсия",
            };

        case ComunidadAutonoma.CA_NAVARRE:
            return {
                name: "Наварра",
                languages: ["Эускера", "Испанский"],
                capital: "Памплона",
            };

        case ComunidadAutonoma.CA_VALENCIA:
            return {
                name: "Валенсия",
                languages: ["Валенсийский", "Испанский"],
                capital: "Валенсия",
            };
    }
};

// Helper function to get array of all regions
export const getAllRegions = (): ComunidadAutonoma[] => {
    return [
        ComunidadAutonoma.CA_CATALONIA,
        ComunidadAutonoma.CA_MADRID,
        ComunidadAutonoma.CA_VALENCIA,
        ComunidadAutonoma.CA_ANDALUCIA,
        ComunidadAutonoma.CA_ARAGON,
        ComunidadAutonoma.CA_ASTURIAS,
        ComunidadAutonoma.CA_BALEARIC_ISLANDS,
        ComunidadAutonoma.CA_BASQUE_COUNTRY,
        ComunidadAutonoma.CA_CANARY_ISLANDS,
        ComunidadAutonoma.CA_CANTABRIA,
        ComunidadAutonoma.CA_CASTILE_LA_MANCHA,
        ComunidadAutonoma.CA_CASTILE_AND_LEON,
        ComunidadAutonoma.CA_EXTREMADURA,
        ComunidadAutonoma.CA_GALICIA,
        ComunidadAutonoma.CA_LA_RIOJA,
        ComunidadAutonoma.CA_MURCIA,
        ComunidadAutonoma.CA_NAVARRE
    ];
};
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleWithCertificate = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.taxResidentCertificate = true;
  }
  emit('next');
};

const handleNoCertificate = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.taxResidentCertificate = false;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Соглашение об избежании двойного налогообложения</h1>
    </div>

    <!-- Success message -->
    <div class="bg-green-900/30 border-l-4 border-green-600 p-6 mb-8 rounded-r-lg">
      <div class="flex space-x-4">
        <div class="flex-shrink-0">
          <svg
              class="h-6 w-6 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div>
          <p class="text-gray-200">
            Между выбранной страной и Испанией есть соглашение об избежании двойного налогообложения!
          </p>
        </div>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-medium mb-4">
          Есть ли у вас сертификат подтвердить свое налоговое резиденство в этой стране?
        </h2>
        <p class="text-gray-400 mb-6">
          Сертификат должен подтверждать налоговое резидентство и возможность применения соглашения об избежании двойного налогообложения.
        </p>

        <!-- Has Certificate Option -->
        <button
            @click="handleWithCertificate"
            class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
                 transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
                 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
                 shadow-lg shadow-blue-500/20 mb-4"
        >
          <span class="font-medium text-white">Да, у меня есть/будет сертификат</span>
        </button>

        <!-- No Certificate Option -->
        <div class="relative">
          <div class="absolute -inset-1">
            <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
          </div>
          <button
              @click="handleNoCertificate"
              class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
          >
            <span class="block font-medium mb-1">Нет, у меня нет сертификата</span>
            <span class="block text-sm text-red-300">Высокий риск признания резидентом</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
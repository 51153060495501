import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-8 max-w-4xl space-y-8" }
const _hoisted_2 = { class: "bg-gray-800 rounded-lg p-6 space-y-8" }
const _hoisted_3 = { class: "space-y-6" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "relative" }
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 0,
  class: "border-t border-gray-700"
}
const _hoisted_9 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_10 = { class: "space-y-4" }
const _hoisted_11 = { class: "flex-grow" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = { class: "w-48 relative" }
const _hoisted_14 = ["value", "onInput"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 2,
  class: "border-t border-gray-700"
}
const _hoisted_17 = {
  key: 3,
  class: "space-y-4"
}
const _hoisted_18 = { class: "space-y-4" }
const _hoisted_19 = { class: "flex-grow" }
const _hoisted_20 = ["onUpdate:modelValue"]
const _hoisted_21 = { class: "w-48 relative" }
const _hoisted_22 = ["value", "onInput"]
const _hoisted_23 = ["onClick"]

import {computed, onMounted, ref} from 'vue';
import {CalculatorState, MoneyAmount} from '@/api/proto';
import {needAutonomoForSpouse, titularWithAutonomo} from '@/data/residency';
import Long from "long";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpensesPage',
  props: {
    state: {}
  },
  emits: ["next", "prev"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Default expenses suggestions with amounts
const DEFAULT_EXPENSES = [
  { name: 'Хестор/сервис для налогов', amount: 60 },
  { name: 'Coworking', amount: 100 },
  { name: 'Claude Pro', amount: 20 },
  { name: 'ChatGPT Plus', amount: 20 },
  { name: 'GitHub Copilot', amount: 10 },
  { name: 'Midjourney', amount: 10 },
  { name: 'Notion', amount: 8 },
];

// Initialize reactive refs
const rentAmount = ref<number | null>(null);
const insuranceAmount = ref<number | null>(null);
const expenses = ref<Array<{name: string; amount: number}>>([]);
const spouseExpenses = ref<Array<{name: string; amount: number}>>([]);

const isAutonomo = computed(() => {
  return props.state.residency && titularWithAutonomo(props.state.residency!.status);
});

const isSpouseAutonomo = computed(() => {
  return needAutonomoForSpouse(props.state);
});

// Initialize expenses based on state or defaults
const initializeExpenses = () => {
  // Load expenses from state if they exist
  if (props.state.bills?.expenses?.length) {
    expenses.value = props.state.bills.expenses.map(exp => ({
      name: exp.name,
      amount: Math.floor((exp.amount?.toNumber() || 0) / 1200) // Convert yearly cents to monthly EUR
    }));
  } else if (isAutonomo.value) {
    // If no state but is autonomo, load all defaults
    expenses.value = [...DEFAULT_EXPENSES];
  }

  // Load spouse expenses from state if they exist
  if (props.state.bills?.spouseExpenses?.length) {
    spouseExpenses.value = props.state.bills.spouseExpenses.map(exp => ({
      name: exp.name,
      amount: Math.floor((exp.amount?.toNumber() || 0) / 1200) // Convert yearly cents to monthly EUR
    }));
  } else if (isSpouseAutonomo.value) {
    // If no state but spouse is autonomo, load first default
    spouseExpenses.value = [DEFAULT_EXPENSES[0]];
  }

  // Load rent and insurance if they exist in state
  if (props.state.bills?.rent?.amount) {
    rentAmount.value = Math.floor(props.state.bills.rent.amount.toNumber() / 1200); // Convert to monthly
  }

  if (props.state.bills?.insurance?.amount) {
    insuranceAmount.value = Math.floor(props.state.bills.insurance.amount.toNumber() / 100); // Already yearly
  }
};

onMounted(() => {
  initializeExpenses();
});

const formatAmount = (value: number | null): string => {
  if (value === null) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const parseAmount = (value: string): number | null => {
  if (!value) return null;
  const parsed = parseInt(value.replace(/\s/g, ''), 10);
  return isNaN(parsed) ? null : parsed;
};

const createMoneyAmount = (amount: number): MoneyAmount => ({
  amount: Long.fromNumber(amount * 100, true),
  currency: 978,
  amountEur: Long.fromNumber(amount * 100, true)
});

const addUserExpense = () => {
  expenses.value.push({ name: '', amount: 0 });
};

const addSpouseExpense = () => {
  spouseExpenses.value.push({ name: '', amount: 0 });
};

const removeUserExpense = (index: number) => {
  expenses.value.splice(index, 1);
};

const removeSpouseExpense = (index: number) => {
  spouseExpenses.value.splice(index, 1);
};

const updateState = () => {
  props.state.bills = {
    rent: rentAmount.value !== null ? {
      amount: createMoneyAmount(rentAmount.value * 12) // Convert monthly to yearly
    } : undefined,
    insurance: insuranceAmount.value !== null ? {
      amount: createMoneyAmount(insuranceAmount.value) // Already yearly
    } : undefined,
    expenses: expenses.value.map(exp => ({
      name: exp.name,
      amount: Long.fromNumber(exp.amount * 100 * 12, true) // Converting monthly to yearly cents
    })),
    spouseExpenses: spouseExpenses.value.map(exp => ({
      name: exp.name,
      amount: Long.fromNumber(exp.amount * 100 * 12, true) // Converting monthly to yearly cents
    }))
  };
};

const handleNext = () => {
  updateState();
  emit('next');
};

const handlePrev = () => {
  updateState();
  emit('prev');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"space-y-4\"><h1 class=\"text-3xl font-bold\">Расходы</h1><p class=\"text-gray-400\">Укажите регулярные расходы, которые могут быть учтены при расчете налогов</p></div><div class=\"bg-blue-900/30 border border-blue-800/50 rounded-lg p-6\"><div class=\"space-y-4\"><p class=\"text-sm text-blue-100\"> Некоторые расходы могут уменьшить ваш налогооблагаемый доход в зависимости от: </p><ul class=\"space-y-2\"><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Провинции</span></li><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Выбранного налогового режима</span></li><li class=\"flex items-start\"><svg class=\"w-4 h-4 mt-1 mr-2 text-blue-400\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\"></path></svg><span>Других условий</span></li></ul></div></div>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-lg font-medium text-gray-200" }, "Расходы на проживание", -1)),
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
            _createTextVNode(" Аренда жилья (EUR в месяц) "),
            _createElementVNode("span", { class: "ml-2 text-xs text-gray-500" }, "(если есть)")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              type: "text",
              value: formatAmount(rentAmount.value),
              onInput: _cache[0] || (_cache[0] = e => rentAmount.value = parseAmount((e.target as HTMLInputElement).value)),
              class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent",
              placeholder: "0"
            }, null, 40, _hoisted_5),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "block text-sm font-medium text-gray-400 mb-2" }, [
            _createTextVNode(" Частная медицинская страховка (EUR в год) "),
            _createElementVNode("span", { class: "ml-2 text-xs text-gray-500" }, "(если есть)")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("input", {
              type: "text",
              value: formatAmount(insuranceAmount.value),
              onInput: _cache[1] || (_cache[1] = e => insuranceAmount.value = parseAmount((e.target as HTMLInputElement).value)),
              class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent",
              placeholder: "0"
            }, null, 40, _hoisted_7),
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
          ])
        ])
      ]),
      (isAutonomo.value || isSpouseAutonomo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true),
      (isAutonomo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-lg font-medium text-gray-200" }, "Ваши расходы на ПО и сервисы", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-sm text-gray-400" }, "Укажите ежемесячные расходы на ПО и сервисы, которые вы используете для работы", -1)),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expenses.value, (expense, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex items-start space-x-4"
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((expense.name) = $event),
                      type: "text",
                      class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg",
                      placeholder: "Название сервиса"
                    }, null, 8, _hoisted_12), [
                      [_vModelText, expense.name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("input", {
                      type: "text",
                      value: formatAmount(expense.amount),
                      onInput: e => expense.amount = parseAmount((e.target as HTMLInputElement).value),
                      class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg",
                      placeholder: "0"
                    }, null, 40, _hoisted_14),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
                  ]),
                  _createElementVNode("button", {
                    onClick: () => removeUserExpense(index),
                    class: "p-2 text-gray-400 hover:text-red-400 transition-colors"
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("svg", {
                      class: "w-5 h-5",
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M6 18L18 6M6 6l12 12"
                      })
                    ], -1)
                  ]), 8, _hoisted_15)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                onClick: addUserExpense,
                class: "px-4 py-2 text-sm bg-blue-600 hover:bg-blue-500 rounded-lg transition-colors"
              }, " + Добавить сервис ")
            ])
          ]))
        : _createCommentVNode("", true),
      (isAutonomo.value && isSpouseAutonomo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16))
        : _createCommentVNode("", true),
      (isSpouseAutonomo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "text-lg font-medium text-gray-200" }, "Расходы супруга(и) на ПО и сервисы", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-sm text-gray-400" }, "Укажите ежемесячные расходы на ПО и сервисы, которые использует ваш супруг(а) для работы", -1)),
            _createElementVNode("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(spouseExpenses.value, (expense, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex items-start space-x-4"
                }, [
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((expense.name) = $event),
                      type: "text",
                      class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg",
                      placeholder: "Название сервиса"
                    }, null, 8, _hoisted_20), [
                      [_vModelText, expense.name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("input", {
                      type: "text",
                      value: formatAmount(expense.amount),
                      onInput: e => expense.amount = parseAmount((e.target as HTMLInputElement).value),
                      class: "w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg",
                      placeholder: "0"
                    }, null, 40, _hoisted_22),
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" }, "EUR", -1))
                  ]),
                  _createElementVNode("button", {
                    onClick: () => removeSpouseExpense(index),
                    class: "p-2 text-gray-400 hover:text-red-400 transition-colors"
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("svg", {
                      class: "w-5 h-5",
                      fill: "none",
                      stroke: "currentColor",
                      viewBox: "0 0 24 24"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M6 18L18 6M6 6l12 12"
                      })
                    ], -1)
                  ]), 8, _hoisted_23)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                onClick: addSpouseExpense,
                class: "px-4 py-2 text-sm bg-blue-600 hover:bg-blue-500 rounded-lg transition-colors"
              }, " + Добавить сервис ")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", { class: "flex justify-between items-center mt-12 px-1" }, [
      _createElementVNode("button", {
        onClick: handlePrev,
        class: "group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      }, _cache[15] || (_cache[15] = [
        _createElementVNode("svg", {
          class: "w-5 h-5 text-gray-400 group-hover:text-white transition-colors",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 19l-7-7 7-7"
          })
        ], -1),
        _createElementVNode("span", { class: "text-gray-400 group-hover:text-white transition-colors" }, "Назад", -1)
      ])),
      _createElementVNode("button", {
        onClick: handleNext,
        class: "group flex items-center space-x-2 px-6 py-3 rounded-lg transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30 shadow-lg shadow-blue-500/20"
      }, _cache[16] || (_cache[16] = [
        _createElementVNode("span", { class: "text-white font-medium" }, "Далее", -1),
        _createElementVNode("svg", {
          class: "w-5 h-5 text-white",
          fill: "none",
          stroke: "currentColor",
          viewBox: "0 0 24 24"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M9 5l7 7-7 7"
          })
        ], -1)
      ]))
    ])
  ]))
}
}

})
import {CalculatorState, ResidencyStatus} from "@/api/proto";

export const statusesList = [
    ResidencyStatus.RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT,
    ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT,
    ResidencyStatus.RS_STARTUP_AUTONOMO,
    ResidencyStatus.RS_WORK_VISA,
    ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST,
    ResidencyStatus.RS_CUENTA_AJENA,
    ResidencyStatus.RS_CUENTA_PROPIA
];

export const requireBilateralAgreement = function(status: ResidencyStatus): boolean {
    return status == ResidencyStatus.RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT;
}

export function canApplySpecialRegime(status: ResidencyStatus): boolean {
    return status != ResidencyStatus.RS_WORK_VISA &&
        status != ResidencyStatus.RS_CUENTA_AJENA &&
        status != ResidencyStatus.RS_CUENTA_PROPIA;
}

export function titularWithAutonomo(status: ResidencyStatus): boolean {
    return status === ResidencyStatus.RS_CUENTA_PROPIA ||
        status === ResidencyStatus.RS_STARTUP_AUTONOMO ||
        status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT;
}

export function needAutonomoForSpouse(state: CalculatorState): boolean {
    return !!state.family?.spouse && state.income?.spouse?.amount?.amount > 0 &&
        !!state.income?.spouse?.contractDate;
}

export const getStatusName = function(status: ResidencyStatus): string {
    switch (status) {
        case ResidencyStatus.RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT:
            return "Удаленный работник с трудовым договором (номад в найме)";
        case ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT:
            return "Удаленный работник с сервисным контрактом (номад ип)";
        case ResidencyStatus.RS_STARTUP_AUTONOMO:
            return "Стартап (с открытием autonomo)";
        case ResidencyStatus.RS_WORK_VISA:
            return "Рабочая виза";
        case ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST:
            return "ВКС - Высококвалифицированный специалист";
        case ResidencyStatus.RS_CUENTA_AJENA:
            return "Cuenta Ajena (найм в Испании)";
        case ResidencyStatus.RS_CUENTA_PROPIA:
            return "Cuenta Propia (autonomo в Испании)";
        default:
            return "";
    }
}
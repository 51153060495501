<script setup lang="ts">
import {ref, computed, defineProps, defineEmits, onUnmounted, nextTick, onMounted} from 'vue';
import countriesData from '@/data/countries.json';

const props = defineProps<{
  modelValue: string;
  placeholder?: string;
  error?: string;
  ringColor?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const isOpen = ref(false);
const searchTerm = ref('');
const inputRef = ref<HTMLInputElement | null>(null);
const componentRef = ref<HTMLDivElement | null>(null);

// List of countries - you can expand this as needed
const countries = [...countriesData.countries].sort((a, b) => a.name.localeCompare(b.name, 'ru'));

const filteredCountries = computed(() => {
  const term = searchTerm.value.toLowerCase();
  return countries.filter(country =>
      country.name.toLowerCase().includes(term) ||
      country.code.toLowerCase().includes(term)
  );
});

const selectedCountry = computed(() => {
  return props.modelValue ? countries.find(country => country.code === props.modelValue) : null;
});

const handleSelect = (country: { code: string; name: string }) => {
  if (props.disabled) return;
  emit('update:modelValue', country.code);
  isOpen.value = false;
  searchTerm.value = '';
};

const toggleDropdown = () => {
  if (props.disabled) return;
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    nextTick(() => {
      inputRef.value?.focus();
    });
  }
};

// Click outside handler
const handleClickOutside = (event: MouseEvent) => {
  if (componentRef.value && !componentRef.value.contains(event.target as Node)) {
    isOpen.value = false;
    searchTerm.value = '';
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <div ref="componentRef" class="relative country-selector">
    <!-- Main button -->
    <div
        @click="toggleDropdown"
        class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg flex items-center justify-between"
        :class="{
          'cursor-pointer hover:bg-gray-650': !disabled,
          'opacity-50 cursor-not-allowed': disabled,
          'ring-2': isOpen && !disabled,
          [`ring-${props.ringColor || 'blue'}-500`]: isOpen && !disabled
        }"
    >
      <span :class="{ 'text-gray-400': !selectedCountry }">
        {{ selectedCountry?.name || props.placeholder || 'Выберите страну' }}
      </span>
      <svg
          class="w-5 h-5 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          :class="{ 'transform rotate-180': isOpen }"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
      </svg>
    </div>

    <!-- Dropdown -->
    <div
        v-if="isOpen && !disabled"
        class="absolute z-50 w-full mt-1 bg-gray-700 border border-gray-600 rounded-lg shadow-lg"
    >
      <!-- Search input -->
      <div class="p-2">
        <input
            ref="inputRef"
            v-model="searchTerm"
            type="text"
            class="w-full px-3 py-2 bg-gray-800 border border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Поиск..."
            @click.stop
        />
      </div>

      <!-- Countries list -->
      <div class="max-h-60 overflow-y-auto">
        <div
            v-for="country in filteredCountries"
            :key="country.code"
            @click="handleSelect(country)"
            class="px-4 py-2 cursor-pointer hover:bg-gray-600 flex items-center space-x-2"
            :class="{ 'bg-gray-600': country.code === props.modelValue }"
        >
          <span>{{ country.name }}</span>
          <span class="text-gray-400 text-sm">({{ country.code }})</span>
        </div>

        <!-- No results message -->
        <div
            v-if="filteredCountries.length === 0"
            class="px-4 py-2 text-gray-400 text-center"
        >
          Ничего не найдено
        </div>
      </div>
    </div>
  </div>
</template>
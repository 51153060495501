<script setup lang="ts">
import {defineProps, defineEmits, computed} from 'vue';
import {CalculatorState, ResidencyStatus} from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const showSelfEmployedWarning = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT;
});

const handleRegular = () => {
  if (props.state.special) {
    props.state.special.beckham = false;
  }else {
    props.state.special = {
      beckham: false
    };
  }
  emit('next');
};

const handleSpecial = () => {
  if (props.state.special) {
    props.state.special.beckham = true;
  }else {
    props.state.special = {
      beckham: true
    };
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Специальный налоговый режим (Beckham)</h1>
    </div>

    <!-- Info block with links -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Источники:
      </p>
      <div class="flex flex-col space-y-3">
        <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2006-20764#a93"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Ley de IRPF, Artículo 93
            </h4>
            <p class="text-sm">Закон о подоходном налоге, статья 93</p>
          </div>
        </a>
        <a href="https://sede.agenciatributaria.gob.es/Sede/procedimientoini/G606.shtml"
           class="flex items-start space-x-3 group"
           target="_blank"
           rel="noopener noreferrer">
          <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
            <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
            </svg>
          </div>
          <div class="flex-1">
            <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
              Modelo 149
            </h4>
            <p class="text-sm">Форма заявления на специальный налоговый режим</p>
          </div>
        </a>
      </div>
    </div>

    <!-- Requirements -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Требования</h2>
      <div class="space-y-6">
        <div class="space-y-3">
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Не быть налоговым резидентом Испании 5 лет до въезда</span>
          </div>
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Цель въезда совпадает с требованиями режима: приехать студентом и в следующем году податься на Бэкхема может быть проблематично</span>
          </div>
          <div class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Срок подачи - не позднее 6 месяцев</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Advantages -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Преимущества</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>24% налог на трудовые доходы до 600 000 евро</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>42% налог на трудовые доходы свыше 600 000 евро</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>0% налог в Испании на пассивный доход не в Испании</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Налоги платятся с момента переезда, а не за календарный год</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Не требуется раскрывать имущество и активы за пределами Испании</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Действует 5 лет (6 включая неполный год переезда)</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Недоступен для жителей Страны Басков и Наварры (в данных сообществах - свои режимы)</span>
        </li>
      </ul>
    </div>

    <!-- Disadvantages -->
    <div class="bg-gray-800 rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">Недостатки</h2>
      <ul class="space-y-3">
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Нет возможности получить сертификат налогового резидента Испании для целей СОИДН</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Невозможно отказаться посреди года и уехать, доходы за год будут облагаться 24%</span>
        </li>
        <li class="flex items-start">
          <span class="text-blue-400 mr-2">•</span>
          <span>Невозможно зачесть расходы и применять различные налоговые вычеты</span>
        </li>
      </ul>
    </div>

    <!-- Warning -->
    <div class="bg-yellow-900/30 border border-yellow-800/50 rounded-lg p-6">
      <div class="flex items-start space-x-3">
        <svg class="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <p>Важно: <br/>Любой мировой трудовой доход считается доходом в Испании, даже если он получен на ИП в Грузии</p>
      </div>
    </div>

    <!-- Important Risks Warning -->
    <div class="bg-yellow-900/30 border border-yellow-800/50 rounded-lg p-6 space-y-6">
      <h2 class="text-xl font-semibold text-yellow-400 mb-4">Важные риски режима</h2>

      <!-- Startup and Remote Workers Risk -->
      <div class="space-y-3" v-if="showSelfEmployedWarning">
        <h3 class="text-lg font-medium text-yellow-300">Специальный режим не доступен для удаленных работников с
          сервисными контрактами</h3>
        <p>Номадов с сервисными контрактами не упомянули в законе, формально режим не применим.</p>
        <div class="bg-yellow-950/30 rounded p-4 border border-yellow-900/50">
          <p class="text-yellow-200 italic">
            "Por lo tanto, los teletrabajadores se encuadran dentro de una relación laboral ordinaria, de trabajador por
            cuenta ajena."
          </p>
        </div>
        <p>Есть случаи отзыва решения по данному режиму спустя год его действия.</p>
      </div>

      <!-- Startup and Remote Workers Risk -->
      <div class="space-y-3">
        <h3 class="text-lg font-medium text-yellow-300">Риск отказа при подаче из статуса студента/etc:</h3>
        <div class="bg-yellow-950/30 rounded p-4 border border-yellow-900/50">
          <p class="text-yellow-200 italic">
            "El hecho de que, en el caso particular del solicitante, pase un tiempo prolongado entre la emisión de la
            Autorización de Residencia y el inicio de la actividad profesional es indicativo de que no existe tal
            relación de causalidad."
          </p>
        </div>
        <p>Большой временной разрыв между получением первой резиденции и стартом работы может привести к отказу в
          режиме</p>
      </div>

      <!-- Court Case -->
      <div class="space-y-3">
        <h3 class="text-lg font-medium text-yellow-300">Прецедент отзыва режима с пересчетом налогов (2021):</h3>
        <a href="https://serviciostelematicosext.hacienda.gob.es/TEAC/DYCTEA/criterio.aspx?id=00/03226/2019/00/0/1"
           class="text-blue-400 hover:text-blue-300 underline"
           target="_blank">
          Решение суда
        </a>
        <ul class="space-y-2 ml-4">
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Топ-менеджер получил режим в 2013 году как наемный работник (категория "администратор" появилась только в 2015)</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Спустя 5 лет налоговая начала проверку</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>По итогам проверки выставлен счет на 216,000€:</span>
            <ul class="ml-6 space-y-1">
              <li class="flex items-start">
                <span class="text-yellow-500 mr-2">-</span>
                <span>180,000€ - доначисление налогов</span>
              </li>
              <li class="flex items-start">
                <span class="text-yellow-500 mr-2">-</span>
                <span>36,000€ - штрафы и пени</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- Court Conclusions -->
      <div class="space-y-3">
        <h3 class="text-lg font-medium text-yellow-300">Основные выводы суда:</h3>
        <ul class="space-y-2 ml-4">
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Одобрение режима Бекхема не является финальным - налоговая может отозвать его в любой момент при обнаружении несоответствий</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Изначальная категория въезда (работник/администратор/предприниматель) должна соблюдаться весь срок действия режима</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">•</span>
            <span>Налоговая вправе провести проверку в конце срока действия режима для максимизации штрафов</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Special Regime Option -->
      <button
          @click="handleSpecial"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
                 transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
                 hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
                 shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Добавить в расчет
          </span>
          <span class="block text-sm text-blue-200">
            Фиксированная ставка 24% на трудовые доходы до 600k€
          </span>
        </div>
      </button>

      <!-- Regular Option -->
      <button
          @click="handleRegular"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-gray-800 hover:bg-gray-700 active:bg-gray-900
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-gray-800/30
               shadow-lg shadow-gray-800/20"
      >
        <div class="text-center">
        <span class="block font-medium text-white mb-1">
          Не добавлять в расчет
        </span>
          <span class="block text-sm text-gray-300">
          Прогрессивная шкала налогообложения
        </span>
        </div>
      </button>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { CalculatorState } from '@/api/proto';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

const handleResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
    props.state.taxResidency.financialCenter = true;
  }
  emit('next');
};

const handleNonResident = () => {
  if (props.state.taxResidency) {
    props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
    props.state.taxResidency.financialCenter = false;
  }
  emit('next');
};

const handlePrev = () => {
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налоговое резидентство: экономическая связь с Испанией</h1>
    </div>

    <!-- Info block with source link -->
    <div class="bg-blue-900/30 border border-blue-800/50 rounded-lg p-6 space-y-4">
      <p class="text-gray-200">
        Экономическая связь с Испанией определяется комплексно: учитываются доходы, активы и расходы. Важно документальное подтверждение связи с другими странами.
      </p>
      <a href="https://t.me/desmontaje_iberico/119"
         class="flex items-start space-x-3 group"
         target="_blank"
         rel="noopener noreferrer">
        <div class="p-2 bg-blue-600/20 rounded-lg group-hover:bg-blue-600/30 transition-colors">
          <svg class="w-5 h-5 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
          </svg>
        </div>
        <div class="flex-1">
          <h4 class="text-sm font-medium text-blue-400 group-hover:text-blue-300">
            Канал "Иберийские разборки"
          </h4>
          <p class="text-sm">Определение экономической связи с Испанией</p>
        </div>
      </a>
    </div>

    <!-- Main content sections -->
    <div class="space-y-6">
      <!-- Main Criteria -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-blue-400">
          Основные критерии
        </h2>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>В какой стране вы получаете основной доход</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Где находятся ваши активы</span>
          </li>
          <li class="flex items-start">
            <span class="text-blue-400 mr-2">•</span>
            <span>Где вы расходуете большую часть средств</span>
          </li>
        </ul>
      </div>

      <!-- Tax Office Requirements -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-green-400">
          Что запрашивает налоговая
        </h2>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-green-400 mr-2">•</span>
            <span>Данные о ваших активах (местоположение, размер)</span>
          </li>
          <li class="flex items-start">
            <span class="text-green-400 mr-2">•</span>
            <span>Страну управления активами</span>
          </li>
          <li class="flex items-start">
            <span class="text-green-400 mr-2">•</span>
            <span>Источники доходов, уплаченные налоги</span>
          </li>
          <li class="flex items-start">
            <span class="text-green-400 mr-2">•</span>
            <span>Крупные расходы и экономическую активность</span>
          </li>
        </ul>
      </div>

      <!-- Important Points -->
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-yellow-400">
          Важные моменты
        </h2>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">!</span>
            <span>Если данные по другим странам не предоставлены, Испания признается центром экономических интересов</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">!</span>
            <span>Сравнение проводится по каждой стране отдельно, а не в сумме</span>
          </li>
          <li class="flex items-start">
            <span class="text-yellow-400 mr-2">!</span>
            <span>Доходы из другой страны должны быть легальными, обложенными налогами и связанными с этой страной</span>
          </li>
        </ul>
      </div>

      <!-- Example -->
      <div class="bg-gray-800/50 border border-gray-700 rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4">Пример</h2>
        <p class="mb-4">Работая удаленно на Американскую компанию из Грузии, ваш доход не связан с Грузией, даже если вы находитесь там физически:</p>
        <ul class="space-y-3">
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Доходы генерируются в США, а не в Грузии</span>
          </li>
          <li class="flex items-start">
            <span class="text-purple-400 mr-2">•</span>
            <span>Доход не поступает из грузинских источников: если заработок не связан с местной экономической деятельностью, страна не может считаться центром экономических интересов</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <!-- Resident Option -->
      <button
          @click="handleResident"
          class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
               transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
               hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
               shadow-lg shadow-blue-500/20"
      >
        <div class="text-center">
          <span class="block font-medium text-white mb-1">
            Резидент: Испания становится центром финансовых интересов
          </span>
          <span class="block text-sm text-blue-200">
            Основная экономическая активность связана с Испанией
          </span>
        </div>
      </button>

      <!-- Non-resident Option -->
      <div class="relative">
        <div class="absolute -inset-1">
          <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
        </div>
        <button
            @click="handleNonResident"
            class="relative w-full p-6 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
        >
          <span class="block font-medium mb-1">
            Не резидент: центр финансовых интересов в другой стране
          </span>
          <span class="block text-sm text-red-300">
            Могу документально подтвердить экономическую связь с другой страной
          </span>
        </button>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-between items-center mt-12 px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute z-50 w-full mt-1 bg-gray-700 border border-gray-600 rounded-lg shadow-lg"
}
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = { class: "max-h-60 overflow-y-auto" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-gray-400 text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "px-4 py-2 text-gray-400 text-center"
}

import {ref, computed, onUnmounted, nextTick, onMounted} from 'vue';
import countriesData from '@/data/countries.json';


export default /*@__PURE__*/_defineComponent({
  __name: 'CountrySelector',
  props: {
    modelValue: {},
    placeholder: {},
    error: {},
    ringColor: {},
    disabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isOpen = ref(false);
const searchTerm = ref('');
const inputRef = ref<HTMLInputElement | null>(null);
const componentRef = ref<HTMLDivElement | null>(null);

// List of countries - you can expand this as needed
const countries = [...countriesData.countries].sort((a, b) => a.name.localeCompare(b.name, 'ru'));

const filteredCountries = computed(() => {
  const term = searchTerm.value.toLowerCase();
  return countries.filter(country =>
      country.name.toLowerCase().includes(term) ||
      country.code.toLowerCase().includes(term)
  );
});

const selectedCountry = computed(() => {
  return props.modelValue ? countries.find(country => country.code === props.modelValue) : null;
});

const handleSelect = (country: { code: string; name: string }) => {
  if (props.disabled) return;
  emit('update:modelValue', country.code);
  isOpen.value = false;
  searchTerm.value = '';
};

const toggleDropdown = () => {
  if (props.disabled) return;
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    nextTick(() => {
      inputRef.value?.focus();
    });
  }
};

// Click outside handler
const handleClickOutside = (event: MouseEvent) => {
  if (componentRef.value && !componentRef.value.contains(event.target as Node)) {
    isOpen.value = false;
    searchTerm.value = '';
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "componentRef",
    ref: componentRef,
    class: "relative country-selector"
  }, [
    _createElementVNode("div", {
      onClick: toggleDropdown,
      class: _normalizeClass(["w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg flex items-center justify-between", {
          'cursor-pointer hover:bg-gray-650': !_ctx.disabled,
          'opacity-50 cursor-not-allowed': _ctx.disabled,
          'ring-2': isOpen.value && !_ctx.disabled,
          [`ring-${props.ringColor || 'blue'}-500`]: isOpen.value && !_ctx.disabled
        }])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass({ 'text-gray-400': !selectedCountry.value })
      }, _toDisplayString(selectedCountry.value?.name || props.placeholder || 'Выберите страну'), 3),
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(["w-5 h-5 text-gray-400", { 'transform rotate-180': isOpen.value }]),
        fill: "none",
        stroke: "currentColor",
        viewBox: "0 0 24 24"
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
          d: "M19 9l-7 7-7-7"
        }, null, -1)
      ]), 2))
    ], 2),
    (isOpen.value && !_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              ref_key: "inputRef",
              ref: inputRef,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchTerm).value = $event)),
              type: "text",
              class: "w-full px-3 py-2 bg-gray-800 border border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent",
              placeholder: "Поиск...",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, null, 512), [
              [_vModelText, searchTerm.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCountries.value, (country) => {
              return (_openBlock(), _createElementBlock("div", {
                key: country.code,
                onClick: ($event: any) => (handleSelect(country)),
                class: _normalizeClass(["px-4 py-2 cursor-pointer hover:bg-gray-600 flex items-center space-x-2", { 'bg-gray-600': country.code === props.modelValue }])
              }, [
                _createElementVNode("span", null, _toDisplayString(country.name), 1),
                _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(country.code) + ")", 1)
              ], 10, _hoisted_4))
            }), 128)),
            (filteredCountries.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Ничего не найдено "))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})
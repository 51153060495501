<script setup lang="ts">
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue';
import {CalculatorState, ResidencyStatus} from '@/api/proto';
import CountrySelector from '@/components/CountrySelector.vue';

const props = defineProps<{
  state: CalculatorState;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
  (e: 'prev'): void;
}>();

// Local state
const status = ref<CalculatorState.TaxResidency.Status | null>(null);
const enterDate = ref('');
const country = ref('');
const error = ref<string | null>(null);

const canApplyBeckham = computed(() => {
  const status = props.state.residency?.status;
  return status === ResidencyStatus.RS_WORK_VISA ||
      status === ResidencyStatus.RS_HIGHLY_QUALIFIED_SPECIALIST ||
      status === ResidencyStatus.RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT ||
      status === ResidencyStatus.RS_STARTUP_AUTONOMO;
});

const isValidDate = computed(() => {
  if (!enterDate.value) return false;
  const date = new Date(enterDate.value);
  return date.getFullYear() >= 2023;
});

const isValid = computed(() => {
  if (!enterDate.value || !country.value || !isValidDate.value) {
    return false;
  }
  if (country.value.toLowerCase() === 'spain' || country.value.toLowerCase() === 'españa') {
    return false;
  }
  return true;
});

// Initialize states on mount
onMounted(() => {
  if (props.state.taxResidency) {
    status.value = props.state.taxResidency.status;
    enterDate.value = props.state.taxResidency.enterDate || '';
    country.value = props.state.taxResidency.country || '';
  }
});

const validateAndSetError = () => {
  error.value = null;
  if (!enterDate.value) {
    error.value = 'Пожалуйста, укажите дату въезда';
    return false;
  }
  if (!isValidDate.value) {
    error.value = 'Дата въезда должна быть не ранее 2023 года';
    return false;
  }
  if (!country.value) {
    error.value = 'Пожалуйста, выберите страну';
    return false;
  }
  if (country.value.toLowerCase() === 'spain' || country.value.toLowerCase() === 'españa') {
    error.value = 'Пожалуйста, выберите страну, отличную от Испании';
    return false;
  }
  return true;
};

const handleResident = () => {
  if (!validateAndSetError()) {
    return;
  }

  if (!props.state.taxResidency) {
    props.state.taxResidency = {};
  }

  props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_RESIDENT;
  props.state.taxResidency.enterDate = enterDate.value;
  props.state.taxResidency.country = country.value;
  emit('next');
};

const handleNonResident = () => {
  if (!validateAndSetError()) {
    return;
  }

  if (!props.state.taxResidency) {
    props.state.taxResidency = {};
  }

  props.state.taxResidency.status = CalculatorState.TaxResidency.Status.TRS_NON_RESIDENT;
  props.state.taxResidency.enterDate = enterDate.value;
  props.state.taxResidency.country = country.value;
  emit('next');
};

const handlePrev = () => {
  if (status.value !== null) {
    props.state.taxResidency = {
      status: status.value,
      enterDate: enterDate.value,
      country: country.value
    };
  }
  emit('prev');
};
</script>

<template>
  <div class="container mx-auto px-4 py-8 max-w-4xl space-y-8">
    <!-- Title -->
    <div class="space-y-4">
      <h1 class="text-3xl font-bold">Налоговый резидент в год переезда</h1>
    </div>

    <!-- Warning block -->
    <div class="bg-yellow-900/30 border-l-4 border-yellow-600 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-yellow-100" v-if="canApplyBeckham">
            Важно: если вы налоговый резидент Испании, вы обязаны уплачивать налоги за весь календарный год, включая период до переезда, кроме случая применения специального режима Бэкхема.
          </p>
          <p class="text-yellow-100" v-else>
            Важно: если вы налоговый резидент Испании, вы обязаны уплачивать налоги за весь календарный год, включая период до переезда.
          </p>
          <p class="text-yellow-100">
            Можно быть налоговым резидентом нескольких стран одновременно.
          </p>
        </div>
      </div>
    </div>

    <!-- Entry details section -->
    <div class="bg-gray-800 rounded-lg p-6 space-y-6">
      <h2 class="text-xl font-semibold mb-4">Переезд в Испанию</h2>

      <!-- Country selector -->
      <div>
        <label class="block text-sm font-medium text-gray-400 mb-2">
          Страна, откуда <span class="text-red-500">*</span>
        </label>
        <CountrySelector
            v-model="country"
            placeholder="Выберите страну"
            ring-color="blue"
            required
        />
      </div>

      <!-- Date input -->
      <div>
        <label class="block text-sm font-medium text-gray-400 mb-2">
          Дата первого въезда в Испанию с целью остаться (например туристом для подачи на ВНЖ) <span class="text-red-500">*</span>
        </label>
        <input
            type="date"
            v-model="enterDate"
            required
            min="2023-01-01"
            class="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
             focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <p class="text-sm text-gray-400 mt-1">Дата должна быть не ранее 2023 года</p>
      </div>

      <!-- Error message -->
      <div v-if="error" class="text-red-500 text-sm">
        {{ error }}
      </div>
    </div>

    <!-- Options -->
    <div class="space-y-4">
      <div class="bg-gray-800 rounded-lg p-6">
        <h2 class="text-xl font-medium mb-4">Считаю себя</h2>

        <!-- Resident Option -->
        <button
            @click="handleResident"
            class="group flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-lg
           transition-all duration-200 bg-blue-600 hover:bg-blue-500 active:bg-blue-700
           hover:-translate-y-0.5 hover:shadow-xl hover:shadow-blue-500/30
           shadow-lg shadow-blue-500/20 mb-4"
            :disabled="!isValid"
            :class="{'opacity-50 cursor-not-allowed': !isValid}"
        >
          <span class="font-medium text-white">Налоговым резидентом Испании</span>
        </button>

        <!-- Non-resident Option -->
        <div class="relative">
          <div class="absolute -inset-1" v-if="isValid">
            <div class="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-600 via-red-600 to-red-700" />
          </div>
          <button
              @click="handleNonResident"
              class="relative w-full px-6 py-3 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors flex items-center justify-center"
              :disabled="!isValid"
              :class="{'opacity-50 cursor-not-allowed': !isValid}"
          >
            <span class="font-medium">Налоговым резидентом другой страны</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div class="flex justify-start items-center px-1">
      <button
          @click="handlePrev"
          class="group flex items-center space-x-2 px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors"
      >
        <svg
            class="w-5 h-5 text-gray-400 group-hover:text-white transition-colors"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        <span class="text-gray-400 group-hover:text-white transition-colors">Назад</span>
      </button>
    </div>
  </div>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {ref} from 'vue';
import {CalculatorState, Page, ResidencyStatus} from "@/api/proto";
import countries from '@/data/countries.json';
import IntroPage from './pages/IntroPage.vue';
import FamilyPage from "@/pages/FamilyPage.vue";
import IncomePage from "@/pages/IncomePage.vue";
import TaxResidencyPage from "@/pages/TaxResidencyPage.vue";
import TRNoDTAPage from "@/pages/tax_residency/TRNoDTAPage.vue";
import TRDTAPage from "@/pages/tax_residency/TRDTAPage.vue";
import TRDaysPage from "@/pages/tax_residency/TRDaysPage.vue";
import TRFinancesPage from "@/pages/tax_residency/TRFinancesPage.vue";
import TRFamilyPage from "@/pages/tax_residency/TRFamilyPage.vue";
import TRConflict from "@/pages/tax_residency/TRConflictPage.vue";
import ComunidadPage from "@/pages/ComunidadPage.vue";
import TaxSpecialRegime from "@/pages/TaxSpecialRegimePage.vue";
import TaxVizkayaRegimePage from "@/pages/TaxVizkayaRegimePage.vue";
import IRNRPage from "@/pages/tax_residency/IRNRPage.vue";
import ExpensesPage from "@/pages/ExpensesPage.vue";
import {canApplySpecialRegime, needAutonomoForSpouse, titularWithAutonomo} from "@/data/residency";
import NewActivityPage from "@/pages/NewActivityPage.vue";
import FinalPage from "@/pages/FinalPage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const initializeState = () => {
  try {
    if (location.hash) {
      const hashData = location.hash.substring(1); // Remove the # symbol
      const binaryData = new Uint8Array(
          atob(hashData)
              .split('')
              .map(char => char.charCodeAt(0))
      );
      return CalculatorState.decode(binaryData);
    }
  } catch (error) {
    console.error('Failed to parse state from hash:', error);
  }

  // Return default state if hash parsing fails or hash is empty
  return new CalculatorState({
    residency: {
      status: ResidencyStatus.RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT,
    },
    page: Page.P_INITIAL,
  });
};

const state = initializeState();
const page = ref<Page>(state.page);

const handlePageChange = (newPage: Page) => {
  console.log('state', state, newPage);

  if (newPage === Page.P_TAX_SPECIAL_REGIME) {
    if (!canApplySpecialRegime(state.residency!.status!)) {
      newPage = Page.P_EXPENSES;
    }
  }

  if (newPage == Page.P_NEW_ACTIVITY_DEDUCTION) {
    if (!needAutonomoForSpouse(state) && !titularWithAutonomo(state.residency!.status!)) {
      newPage = Page.P_FINAL;
    }
  }

  state.page = newPage;
  page.value = newPage;

  const body: Uint8Array = CalculatorState.encode(state).finish();
  const base64body = btoa(String.fromCharCode.apply(null, body));
  location.hash = base64body;
};

const handleTRNext = (nextPage: Page) => {
  if (state.taxResidency?.status === CalculatorState.TaxResidency.Status.TRS_RESIDENT) {
    handlePageChange(Page.P_TAX_SPECIAL_REGIME);
    return;
  }

  if (state.page === Page.P_TAX_RESIDENCY) {
    const country = countries.countries.find(c => c.code === state.taxResidency?.country);
    const hasDTA = country?.has_dta ?? false;
    handlePageChange(hasDTA ? Page.P_TAX_RESIDENCY_DTA : Page.P_TAX_RESIDENCY_NO_DTA);
    return;
  }

  // Skip if resident
  if (state.taxResidency?.status === CalculatorState.TaxResidency.Status.TRS_RESIDENT) {
    handlePageChange(Page.P_TAX_SPECIAL_REGIME);
    return;
  }

  // Skip family page if no family
  const hasFamily = !!(state.family?.spouse || (state.family?.children && state.family.children.length > 0));
  if (!hasFamily && nextPage === Page.P_TAX_RESIDENCY_FAMILY) {
    handlePageChange(Page.P_TAX_RESIDENCY_CONFLICT);
    return;
  }

  handlePageChange(nextPage);
};

const handleTRPrev = (prevPage: Page) => {
  // Skip family page if no family
  const hasFamily = !!(state.family?.spouse || (state.family?.children && state.family.children.length > 0));
  if (!hasFamily && prevPage === Page.P_TAX_RESIDENCY_FAMILY) {
    handlePageChange(Page.P_TAX_RESIDENCY_FINANCES);
    return;
  }

  handlePageChange(prevPage);
};

return (_ctx: any,_cache: any) => {
  return (page.value === _unref(Page).P_INITIAL)
    ? (_openBlock(), _createBlock(IntroPage, {
        key: 0,
        state: _unref(state),
        onNext: _cache[0] || (_cache[0] = () => handlePageChange(_unref(Page).P_FAMILY))
      }, null, 8, ["state"]))
    : (page.value === _unref(Page).P_FAMILY)
      ? (_openBlock(), _createBlock(FamilyPage, {
          key: 1,
          state: _unref(state),
          onNext: _cache[1] || (_cache[1] = () => handlePageChange(_unref(Page).P_INCOME)),
          onPrev: _cache[2] || (_cache[2] = () => handlePageChange(_unref(Page).P_INITIAL))
        }, null, 8, ["state"]))
      : (page.value === _unref(Page).P_INCOME)
        ? (_openBlock(), _createBlock(IncomePage, {
            key: 2,
            state: _unref(state),
            onNext: _cache[3] || (_cache[3] = () => handlePageChange(_unref(Page).P_COMUNIDAD)),
            onPrev: _cache[4] || (_cache[4] = () => handlePageChange(_unref(Page).P_FAMILY))
          }, null, 8, ["state"]))
        : (page.value === _unref(Page).P_COMUNIDAD)
          ? (_openBlock(), _createBlock(ComunidadPage, {
              key: 3,
              state: _unref(state),
              onNext: _cache[5] || (_cache[5] = () => handlePageChange(_unref(Page).P_TAX_RESIDENCY)),
              onPrev: _cache[6] || (_cache[6] = () => handlePageChange(_unref(Page).P_INITIAL))
            }, null, 8, ["state"]))
          : (page.value === _unref(Page).P_TAX_RESIDENCY)
            ? (_openBlock(), _createBlock(TaxResidencyPage, {
                key: 4,
                state: _unref(state),
                onNext: _cache[7] || (_cache[7] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_DTA)),
                onPrev: _cache[8] || (_cache[8] = () => handlePageChange(_unref(Page).P_COMUNIDAD))
              }, null, 8, ["state"]))
            : (page.value === _unref(Page).P_TAX_SPECIAL_REGIME)
              ? (_openBlock(), _createBlock(TaxSpecialRegime, {
                  key: 5,
                  state: _unref(state),
                  onPrev: _cache[9] || (_cache[9] = () => handlePageChange(_unref(Page).P_TAX_RESIDENCY)),
                  onNext: _cache[10] || (_cache[10] = () => handlePageChange(_unref(Page).P_VIZKAYA_SPECIAL_REGIME))
                }, null, 8, ["state"]))
              : (page.value === _unref(Page).P_VIZKAYA_SPECIAL_REGIME)
                ? (_openBlock(), _createBlock(TaxVizkayaRegimePage, {
                    key: 6,
                    state: _unref(state),
                    onPrev: _cache[11] || (_cache[11] = () => handlePageChange(_unref(Page).P_TAX_SPECIAL_REGIME)),
                    onNext: _cache[12] || (_cache[12] = () => handlePageChange(_unref(Page).P_EXPENSES))
                  }, null, 8, ["state"]))
                : (page.value === _unref(Page).P_EXPENSES)
                  ? (_openBlock(), _createBlock(ExpensesPage, {
                      key: 7,
                      state: _unref(state),
                      onNext: _cache[13] || (_cache[13] = () => handlePageChange(_unref(Page).P_NEW_ACTIVITY_DEDUCTION)),
                      onPrev: _cache[14] || (_cache[14] = () => handlePageChange(_unref(Page).P_TAX_SPECIAL_REGIME))
                    }, null, 8, ["state"]))
                  : (page.value === _unref(Page).P_NEW_ACTIVITY_DEDUCTION)
                    ? (_openBlock(), _createBlock(NewActivityPage, {
                        key: 8,
                        state: _unref(state),
                        onNext: _cache[15] || (_cache[15] = () => handlePageChange(_unref(Page).P_FINAL)),
                        onPrev: _cache[16] || (_cache[16] = () => handlePageChange(_unref(Page).P_EXPENSES))
                      }, null, 8, ["state"]))
                    : (page.value === _unref(Page).P_FINAL)
                      ? (_openBlock(), _createBlock(FinalPage, {
                          key: 9,
                          state: _unref(state)
                        }, null, 8, ["state"]))
                      : (page.value === _unref(Page).P_TAX_RESIDENCY_NO_DTA)
                        ? (_openBlock(), _createBlock(TRNoDTAPage, {
                            key: 10,
                            state: _unref(state),
                            onNext: _cache[17] || (_cache[17] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_DAYS)),
                            onPrev: _cache[18] || (_cache[18] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY))
                          }, null, 8, ["state"]))
                        : (page.value === _unref(Page).P_TAX_RESIDENCY_DTA)
                          ? (_openBlock(), _createBlock(TRDTAPage, {
                              key: 11,
                              state: _unref(state),
                              onNext: _cache[19] || (_cache[19] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_DAYS)),
                              onPrev: _cache[20] || (_cache[20] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY))
                            }, null, 8, ["state"]))
                          : (page.value === _unref(Page).P_TAX_RESIDENCY_DAYS)
                            ? (_openBlock(), _createBlock(TRDaysPage, {
                                key: 12,
                                state: _unref(state),
                                onNext: _cache[21] || (_cache[21] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_FINANCES)),
                                onPrev: _cache[22] || (_cache[22] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY_DTA))
                              }, null, 8, ["state"]))
                            : (page.value === _unref(Page).P_TAX_RESIDENCY_FINANCES)
                              ? (_openBlock(), _createBlock(TRFinancesPage, {
                                  key: 13,
                                  state: _unref(state),
                                  onNext: _cache[23] || (_cache[23] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_FAMILY)),
                                  onPrev: _cache[24] || (_cache[24] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY_DAYS))
                                }, null, 8, ["state"]))
                              : (page.value === _unref(Page).P_TAX_RESIDENCY_FAMILY)
                                ? (_openBlock(), _createBlock(TRFamilyPage, {
                                    key: 14,
                                    state: _unref(state),
                                    onNext: _cache[25] || (_cache[25] = () => handleTRNext(_unref(Page).P_TAX_RESIDENCY_CONFLICT)),
                                    onPrev: _cache[26] || (_cache[26] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY_FINANCES))
                                  }, null, 8, ["state"]))
                                : (page.value === _unref(Page).P_TAX_RESIDENCY_CONFLICT)
                                  ? (_openBlock(), _createBlock(TRConflict, {
                                      key: 15,
                                      state: _unref(state),
                                      onNext: _cache[27] || (_cache[27] = () => handleTRNext(_unref(Page).P_IRNR)),
                                      onPrev: _cache[28] || (_cache[28] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY_FAMILY))
                                    }, null, 8, ["state"]))
                                  : (page.value === _unref(Page).P_IRNR)
                                    ? (_openBlock(), _createBlock(IRNRPage, {
                                        key: 16,
                                        state: _unref(state),
                                        onNext: _cache[29] || (_cache[29] = () => handleTRNext(_unref(Page).P_TAX_SPECIAL_REGIME)),
                                        onPrev: _cache[30] || (_cache[30] = () => handleTRPrev(_unref(Page).P_TAX_RESIDENCY_CONFLICT))
                                      }, null, 8, ["state"]))
                                    : _createCommentVNode("", true)
}
}

})
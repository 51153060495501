/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

/**
 * SpecialRegime enum.
$protobuf.util.Long = require("long");
$protobuf.configure();
 * @exports SpecialRegime
 * @enum {number}
 * @property {number} SR_IMPOSSIBLE=0 SR_IMPOSSIBLE value
 * @property {number} SR_WARNING=1 SR_WARNING value
 * @property {number} SR_SAFE=2 SR_SAFE value
 */
export const SpecialRegime = $root.SpecialRegime = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "SR_IMPOSSIBLE"] = 0;
    values[valuesById[1] = "SR_WARNING"] = 1;
    values[valuesById[2] = "SR_SAFE"] = 2;
    return values;
})();

/**
 * ComunidadAutonoma enum.
 * @exports ComunidadAutonoma
 * @enum {number}
 * @property {number} CA_ANDALUCIA=0 CA_ANDALUCIA value
 * @property {number} CA_ARAGON=1 CA_ARAGON value
 * @property {number} CA_ASTURIAS=2 CA_ASTURIAS value
 * @property {number} CA_BALEARIC_ISLANDS=3 CA_BALEARIC_ISLANDS value
 * @property {number} CA_BASQUE_COUNTRY=4 CA_BASQUE_COUNTRY value
 * @property {number} CA_CANARY_ISLANDS=5 CA_CANARY_ISLANDS value
 * @property {number} CA_CANTABRIA=6 CA_CANTABRIA value
 * @property {number} CA_CASTILE_LA_MANCHA=7 CA_CASTILE_LA_MANCHA value
 * @property {number} CA_CASTILE_AND_LEON=8 CA_CASTILE_AND_LEON value
 * @property {number} CA_CATALONIA=9 CA_CATALONIA value
 * @property {number} CA_EXTREMADURA=10 CA_EXTREMADURA value
 * @property {number} CA_GALICIA=11 CA_GALICIA value
 * @property {number} CA_LA_RIOJA=12 CA_LA_RIOJA value
 * @property {number} CA_MADRID=13 CA_MADRID value
 * @property {number} CA_MURCIA=14 CA_MURCIA value
 * @property {number} CA_NAVARRE=15 CA_NAVARRE value
 * @property {number} CA_VALENCIA=16 CA_VALENCIA value
 */
export const ComunidadAutonoma = $root.ComunidadAutonoma = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CA_ANDALUCIA"] = 0;
    values[valuesById[1] = "CA_ARAGON"] = 1;
    values[valuesById[2] = "CA_ASTURIAS"] = 2;
    values[valuesById[3] = "CA_BALEARIC_ISLANDS"] = 3;
    values[valuesById[4] = "CA_BASQUE_COUNTRY"] = 4;
    values[valuesById[5] = "CA_CANARY_ISLANDS"] = 5;
    values[valuesById[6] = "CA_CANTABRIA"] = 6;
    values[valuesById[7] = "CA_CASTILE_LA_MANCHA"] = 7;
    values[valuesById[8] = "CA_CASTILE_AND_LEON"] = 8;
    values[valuesById[9] = "CA_CATALONIA"] = 9;
    values[valuesById[10] = "CA_EXTREMADURA"] = 10;
    values[valuesById[11] = "CA_GALICIA"] = 11;
    values[valuesById[12] = "CA_LA_RIOJA"] = 12;
    values[valuesById[13] = "CA_MADRID"] = 13;
    values[valuesById[14] = "CA_MURCIA"] = 14;
    values[valuesById[15] = "CA_NAVARRE"] = 15;
    values[valuesById[16] = "CA_VALENCIA"] = 16;
    return values;
})();

/**
 * ResidencyStatus enum.
 * @exports ResidencyStatus
 * @enum {number}
 * @property {number} RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT=0 RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT value
 * @property {number} RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT=1 RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT value
 * @property {number} RS_WORK_VISA=2 RS_WORK_VISA value
 * @property {number} RS_HIGHLY_QUALIFIED_SPECIALIST=3 RS_HIGHLY_QUALIFIED_SPECIALIST value
 * @property {number} RS_STARTUP_AUTONOMO=4 RS_STARTUP_AUTONOMO value
 * @property {number} RS_CUENTA_AJENA=5 RS_CUENTA_AJENA value
 * @property {number} RS_CUENTA_PROPIA=6 RS_CUENTA_PROPIA value
 */
export const ResidencyStatus = $root.ResidencyStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT"] = 0;
    values[valuesById[1] = "RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT"] = 1;
    values[valuesById[2] = "RS_WORK_VISA"] = 2;
    values[valuesById[3] = "RS_HIGHLY_QUALIFIED_SPECIALIST"] = 3;
    values[valuesById[4] = "RS_STARTUP_AUTONOMO"] = 4;
    values[valuesById[5] = "RS_CUENTA_AJENA"] = 5;
    values[valuesById[6] = "RS_CUENTA_PROPIA"] = 6;
    return values;
})();

/**
 * Page enum.
 * @exports Page
 * @enum {number}
 * @property {number} P_INITIAL=0 P_INITIAL value
 * @property {number} P_FAMILY=1 P_FAMILY value
 * @property {number} P_INCOME=2 P_INCOME value
 * @property {number} P_TAX_RESIDENCY=3 P_TAX_RESIDENCY value
 * @property {number} P_COMUNIDAD=4 P_COMUNIDAD value
 * @property {number} P_TAX_SPECIAL_REGIME=10 P_TAX_SPECIAL_REGIME value
 * @property {number} P_VIZKAYA_SPECIAL_REGIME=11 P_VIZKAYA_SPECIAL_REGIME value
 * @property {number} P_IRNR=12 P_IRNR value
 * @property {number} P_EXPENSES=13 P_EXPENSES value
 * @property {number} P_FINAL=14 P_FINAL value
 * @property {number} P_TAX_RESIDENCY_NO_DTA=30 P_TAX_RESIDENCY_NO_DTA value
 * @property {number} P_TAX_RESIDENCY_DTA=31 P_TAX_RESIDENCY_DTA value
 * @property {number} P_TAX_RESIDENCY_DAYS=32 P_TAX_RESIDENCY_DAYS value
 * @property {number} P_TAX_RESIDENCY_FINANCES=33 P_TAX_RESIDENCY_FINANCES value
 * @property {number} P_TAX_RESIDENCY_FAMILY=34 P_TAX_RESIDENCY_FAMILY value
 * @property {number} P_TAX_RESIDENCY_CONFLICT=35 P_TAX_RESIDENCY_CONFLICT value
 * @property {number} P_NEW_ACTIVITY_DEDUCTION=50 P_NEW_ACTIVITY_DEDUCTION value
 */
export const Page = $root.Page = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "P_INITIAL"] = 0;
    values[valuesById[1] = "P_FAMILY"] = 1;
    values[valuesById[2] = "P_INCOME"] = 2;
    values[valuesById[3] = "P_TAX_RESIDENCY"] = 3;
    values[valuesById[4] = "P_COMUNIDAD"] = 4;
    values[valuesById[10] = "P_TAX_SPECIAL_REGIME"] = 10;
    values[valuesById[11] = "P_VIZKAYA_SPECIAL_REGIME"] = 11;
    values[valuesById[12] = "P_IRNR"] = 12;
    values[valuesById[13] = "P_EXPENSES"] = 13;
    values[valuesById[14] = "P_FINAL"] = 14;
    values[valuesById[30] = "P_TAX_RESIDENCY_NO_DTA"] = 30;
    values[valuesById[31] = "P_TAX_RESIDENCY_DTA"] = 31;
    values[valuesById[32] = "P_TAX_RESIDENCY_DAYS"] = 32;
    values[valuesById[33] = "P_TAX_RESIDENCY_FINANCES"] = 33;
    values[valuesById[34] = "P_TAX_RESIDENCY_FAMILY"] = 34;
    values[valuesById[35] = "P_TAX_RESIDENCY_CONFLICT"] = 35;
    values[valuesById[50] = "P_NEW_ACTIVITY_DEDUCTION"] = 50;
    return values;
})();

export const MoneyAmount = $root.MoneyAmount = (() => {

    /**
     * Properties of a MoneyAmount.
     * @exports IMoneyAmount
     * @interface IMoneyAmount
     * @property {Long|null} [amount] MoneyAmount amount
     * @property {Long|null} [currency] MoneyAmount currency
     * @property {Long|null} [amountEur] MoneyAmount amountEur
     */

    /**
     * Constructs a new MoneyAmount.
     * @exports MoneyAmount
     * @classdesc Represents a MoneyAmount.
     * @implements IMoneyAmount
     * @constructor
     * @param {IMoneyAmount=} [properties] Properties to set
     */
    function MoneyAmount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MoneyAmount amount.
     * @member {Long} amount
     * @memberof MoneyAmount
     * @instance
     */
    MoneyAmount.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MoneyAmount currency.
     * @member {Long} currency
     * @memberof MoneyAmount
     * @instance
     */
    MoneyAmount.prototype.currency = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * MoneyAmount amountEur.
     * @member {Long} amountEur
     * @memberof MoneyAmount
     * @instance
     */
    MoneyAmount.prototype.amountEur = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

    /**
     * Creates a new MoneyAmount instance using the specified properties.
     * @function create
     * @memberof MoneyAmount
     * @static
     * @param {IMoneyAmount=} [properties] Properties to set
     * @returns {MoneyAmount} MoneyAmount instance
     */
    MoneyAmount.create = function create(properties) {
        return new MoneyAmount(properties);
    };

    /**
     * Encodes the specified MoneyAmount message. Does not implicitly {@link MoneyAmount.verify|verify} messages.
     * @function encode
     * @memberof MoneyAmount
     * @static
     * @param {IMoneyAmount} message MoneyAmount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MoneyAmount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.amount);
        if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.currency);
        if (message.amountEur != null && Object.hasOwnProperty.call(message, "amountEur"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.amountEur);
        return writer;
    };

    /**
     * Encodes the specified MoneyAmount message, length delimited. Does not implicitly {@link MoneyAmount.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MoneyAmount
     * @static
     * @param {IMoneyAmount} message MoneyAmount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MoneyAmount.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MoneyAmount message from the specified reader or buffer.
     * @function decode
     * @memberof MoneyAmount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MoneyAmount} MoneyAmount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MoneyAmount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MoneyAmount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.amount = reader.uint64();
                    break;
                }
            case 2: {
                    message.currency = reader.uint64();
                    break;
                }
            case 3: {
                    message.amountEur = reader.uint64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MoneyAmount message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MoneyAmount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MoneyAmount} MoneyAmount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MoneyAmount.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MoneyAmount message.
     * @function verify
     * @memberof MoneyAmount
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MoneyAmount.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
            if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                return "amount: integer|Long expected";
        if (message.currency != null && message.hasOwnProperty("currency"))
            if (!$util.isInteger(message.currency) && !(message.currency && $util.isInteger(message.currency.low) && $util.isInteger(message.currency.high)))
                return "currency: integer|Long expected";
        if (message.amountEur != null && message.hasOwnProperty("amountEur"))
            if (!$util.isInteger(message.amountEur) && !(message.amountEur && $util.isInteger(message.amountEur.low) && $util.isInteger(message.amountEur.high)))
                return "amountEur: integer|Long expected";
        return null;
    };

    /**
     * Creates a MoneyAmount message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MoneyAmount
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MoneyAmount} MoneyAmount
     */
    MoneyAmount.fromObject = function fromObject(object) {
        if (object instanceof $root.MoneyAmount)
            return object;
        let message = new $root.MoneyAmount();
        if (object.amount != null)
            if ($util.Long)
                (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
            else if (typeof object.amount === "string")
                message.amount = parseInt(object.amount, 10);
            else if (typeof object.amount === "number")
                message.amount = object.amount;
            else if (typeof object.amount === "object")
                message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
        if (object.currency != null)
            if ($util.Long)
                (message.currency = $util.Long.fromValue(object.currency)).unsigned = true;
            else if (typeof object.currency === "string")
                message.currency = parseInt(object.currency, 10);
            else if (typeof object.currency === "number")
                message.currency = object.currency;
            else if (typeof object.currency === "object")
                message.currency = new $util.LongBits(object.currency.low >>> 0, object.currency.high >>> 0).toNumber(true);
        if (object.amountEur != null)
            if ($util.Long)
                (message.amountEur = $util.Long.fromValue(object.amountEur)).unsigned = true;
            else if (typeof object.amountEur === "string")
                message.amountEur = parseInt(object.amountEur, 10);
            else if (typeof object.amountEur === "number")
                message.amountEur = object.amountEur;
            else if (typeof object.amountEur === "object")
                message.amountEur = new $util.LongBits(object.amountEur.low >>> 0, object.amountEur.high >>> 0).toNumber(true);
        return message;
    };

    /**
     * Creates a plain object from a MoneyAmount message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MoneyAmount
     * @static
     * @param {MoneyAmount} message MoneyAmount
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MoneyAmount.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.amount = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.currency = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.currency = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, true);
                object.amountEur = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.amountEur = options.longs === String ? "0" : 0;
        }
        if (message.amount != null && message.hasOwnProperty("amount"))
            if (typeof message.amount === "number")
                object.amount = options.longs === String ? String(message.amount) : message.amount;
            else
                object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
        if (message.currency != null && message.hasOwnProperty("currency"))
            if (typeof message.currency === "number")
                object.currency = options.longs === String ? String(message.currency) : message.currency;
            else
                object.currency = options.longs === String ? $util.Long.prototype.toString.call(message.currency) : options.longs === Number ? new $util.LongBits(message.currency.low >>> 0, message.currency.high >>> 0).toNumber(true) : message.currency;
        if (message.amountEur != null && message.hasOwnProperty("amountEur"))
            if (typeof message.amountEur === "number")
                object.amountEur = options.longs === String ? String(message.amountEur) : message.amountEur;
            else
                object.amountEur = options.longs === String ? $util.Long.prototype.toString.call(message.amountEur) : options.longs === Number ? new $util.LongBits(message.amountEur.low >>> 0, message.amountEur.high >>> 0).toNumber(true) : message.amountEur;
        return object;
    };

    /**
     * Converts this MoneyAmount to JSON.
     * @function toJSON
     * @memberof MoneyAmount
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MoneyAmount.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MoneyAmount
     * @function getTypeUrl
     * @memberof MoneyAmount
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MoneyAmount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MoneyAmount";
    };

    return MoneyAmount;
})();

export const CalculatorState = $root.CalculatorState = (() => {

    /**
     * Properties of a CalculatorState.
     * @exports ICalculatorState
     * @interface ICalculatorState
     * @property {Page|null} [page] CalculatorState page
     * @property {CalculatorState.IResidency|null} [residency] CalculatorState residency
     * @property {CalculatorState.IFamily|null} [family] CalculatorState family
     * @property {CalculatorState.IIncome|null} [income] CalculatorState income
     * @property {CalculatorState.ITaxResidency|null} [taxResidency] CalculatorState taxResidency
     * @property {CalculatorState.IRegion|null} [region] CalculatorState region
     * @property {CalculatorState.INonResidentTax|null} [irnr] CalculatorState irnr
     * @property {CalculatorState.ISpecialRegime|null} [special] CalculatorState special
     * @property {CalculatorState.IBills|null} [bills] CalculatorState bills
     * @property {CalculatorState.INewActivityDeduction|null} [newActivity] CalculatorState newActivity
     */

    /**
     * Constructs a new CalculatorState.
     * @exports CalculatorState
     * @classdesc Represents a CalculatorState.
     * @implements ICalculatorState
     * @constructor
     * @param {ICalculatorState=} [properties] Properties to set
     */
    function CalculatorState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CalculatorState page.
     * @member {Page} page
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.page = 0;

    /**
     * CalculatorState residency.
     * @member {CalculatorState.IResidency|null|undefined} residency
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.residency = null;

    /**
     * CalculatorState family.
     * @member {CalculatorState.IFamily|null|undefined} family
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.family = null;

    /**
     * CalculatorState income.
     * @member {CalculatorState.IIncome|null|undefined} income
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.income = null;

    /**
     * CalculatorState taxResidency.
     * @member {CalculatorState.ITaxResidency|null|undefined} taxResidency
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.taxResidency = null;

    /**
     * CalculatorState region.
     * @member {CalculatorState.IRegion|null|undefined} region
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.region = null;

    /**
     * CalculatorState irnr.
     * @member {CalculatorState.INonResidentTax|null|undefined} irnr
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.irnr = null;

    /**
     * CalculatorState special.
     * @member {CalculatorState.ISpecialRegime|null|undefined} special
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.special = null;

    /**
     * CalculatorState bills.
     * @member {CalculatorState.IBills|null|undefined} bills
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.bills = null;

    /**
     * CalculatorState newActivity.
     * @member {CalculatorState.INewActivityDeduction|null|undefined} newActivity
     * @memberof CalculatorState
     * @instance
     */
    CalculatorState.prototype.newActivity = null;

    /**
     * Creates a new CalculatorState instance using the specified properties.
     * @function create
     * @memberof CalculatorState
     * @static
     * @param {ICalculatorState=} [properties] Properties to set
     * @returns {CalculatorState} CalculatorState instance
     */
    CalculatorState.create = function create(properties) {
        return new CalculatorState(properties);
    };

    /**
     * Encodes the specified CalculatorState message. Does not implicitly {@link CalculatorState.verify|verify} messages.
     * @function encode
     * @memberof CalculatorState
     * @static
     * @param {ICalculatorState} message CalculatorState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CalculatorState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
        if (message.residency != null && Object.hasOwnProperty.call(message, "residency"))
            $root.CalculatorState.Residency.encode(message.residency, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.family != null && Object.hasOwnProperty.call(message, "family"))
            $root.CalculatorState.Family.encode(message.family, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.income != null && Object.hasOwnProperty.call(message, "income"))
            $root.CalculatorState.Income.encode(message.income, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.taxResidency != null && Object.hasOwnProperty.call(message, "taxResidency"))
            $root.CalculatorState.TaxResidency.encode(message.taxResidency, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.region != null && Object.hasOwnProperty.call(message, "region"))
            $root.CalculatorState.Region.encode(message.region, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.irnr != null && Object.hasOwnProperty.call(message, "irnr"))
            $root.CalculatorState.NonResidentTax.encode(message.irnr, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.special != null && Object.hasOwnProperty.call(message, "special"))
            $root.CalculatorState.SpecialRegime.encode(message.special, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.bills != null && Object.hasOwnProperty.call(message, "bills"))
            $root.CalculatorState.Bills.encode(message.bills, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.newActivity != null && Object.hasOwnProperty.call(message, "newActivity"))
            $root.CalculatorState.NewActivityDeduction.encode(message.newActivity, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CalculatorState message, length delimited. Does not implicitly {@link CalculatorState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CalculatorState
     * @static
     * @param {ICalculatorState} message CalculatorState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CalculatorState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CalculatorState message from the specified reader or buffer.
     * @function decode
     * @memberof CalculatorState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CalculatorState} CalculatorState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CalculatorState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.page = reader.int32();
                    break;
                }
            case 10: {
                    message.residency = $root.CalculatorState.Residency.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.family = $root.CalculatorState.Family.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.income = $root.CalculatorState.Income.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.taxResidency = $root.CalculatorState.TaxResidency.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.region = $root.CalculatorState.Region.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.irnr = $root.CalculatorState.NonResidentTax.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.special = $root.CalculatorState.SpecialRegime.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.bills = $root.CalculatorState.Bills.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.newActivity = $root.CalculatorState.NewActivityDeduction.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CalculatorState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CalculatorState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CalculatorState} CalculatorState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CalculatorState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CalculatorState message.
     * @function verify
     * @memberof CalculatorState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CalculatorState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.page != null && message.hasOwnProperty("page"))
            switch (message.page) {
            default:
                return "page: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 30:
            case 31:
            case 32:
            case 33:
            case 34:
            case 35:
            case 50:
                break;
            }
        if (message.residency != null && message.hasOwnProperty("residency")) {
            let error = $root.CalculatorState.Residency.verify(message.residency);
            if (error)
                return "residency." + error;
        }
        if (message.family != null && message.hasOwnProperty("family")) {
            let error = $root.CalculatorState.Family.verify(message.family);
            if (error)
                return "family." + error;
        }
        if (message.income != null && message.hasOwnProperty("income")) {
            let error = $root.CalculatorState.Income.verify(message.income);
            if (error)
                return "income." + error;
        }
        if (message.taxResidency != null && message.hasOwnProperty("taxResidency")) {
            let error = $root.CalculatorState.TaxResidency.verify(message.taxResidency);
            if (error)
                return "taxResidency." + error;
        }
        if (message.region != null && message.hasOwnProperty("region")) {
            let error = $root.CalculatorState.Region.verify(message.region);
            if (error)
                return "region." + error;
        }
        if (message.irnr != null && message.hasOwnProperty("irnr")) {
            let error = $root.CalculatorState.NonResidentTax.verify(message.irnr);
            if (error)
                return "irnr." + error;
        }
        if (message.special != null && message.hasOwnProperty("special")) {
            let error = $root.CalculatorState.SpecialRegime.verify(message.special);
            if (error)
                return "special." + error;
        }
        if (message.bills != null && message.hasOwnProperty("bills")) {
            let error = $root.CalculatorState.Bills.verify(message.bills);
            if (error)
                return "bills." + error;
        }
        if (message.newActivity != null && message.hasOwnProperty("newActivity")) {
            let error = $root.CalculatorState.NewActivityDeduction.verify(message.newActivity);
            if (error)
                return "newActivity." + error;
        }
        return null;
    };

    /**
     * Creates a CalculatorState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CalculatorState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CalculatorState} CalculatorState
     */
    CalculatorState.fromObject = function fromObject(object) {
        if (object instanceof $root.CalculatorState)
            return object;
        let message = new $root.CalculatorState();
        switch (object.page) {
        default:
            if (typeof object.page === "number") {
                message.page = object.page;
                break;
            }
            break;
        case "P_INITIAL":
        case 0:
            message.page = 0;
            break;
        case "P_FAMILY":
        case 1:
            message.page = 1;
            break;
        case "P_INCOME":
        case 2:
            message.page = 2;
            break;
        case "P_TAX_RESIDENCY":
        case 3:
            message.page = 3;
            break;
        case "P_COMUNIDAD":
        case 4:
            message.page = 4;
            break;
        case "P_TAX_SPECIAL_REGIME":
        case 10:
            message.page = 10;
            break;
        case "P_VIZKAYA_SPECIAL_REGIME":
        case 11:
            message.page = 11;
            break;
        case "P_IRNR":
        case 12:
            message.page = 12;
            break;
        case "P_EXPENSES":
        case 13:
            message.page = 13;
            break;
        case "P_FINAL":
        case 14:
            message.page = 14;
            break;
        case "P_TAX_RESIDENCY_NO_DTA":
        case 30:
            message.page = 30;
            break;
        case "P_TAX_RESIDENCY_DTA":
        case 31:
            message.page = 31;
            break;
        case "P_TAX_RESIDENCY_DAYS":
        case 32:
            message.page = 32;
            break;
        case "P_TAX_RESIDENCY_FINANCES":
        case 33:
            message.page = 33;
            break;
        case "P_TAX_RESIDENCY_FAMILY":
        case 34:
            message.page = 34;
            break;
        case "P_TAX_RESIDENCY_CONFLICT":
        case 35:
            message.page = 35;
            break;
        case "P_NEW_ACTIVITY_DEDUCTION":
        case 50:
            message.page = 50;
            break;
        }
        if (object.residency != null) {
            if (typeof object.residency !== "object")
                throw TypeError(".CalculatorState.residency: object expected");
            message.residency = $root.CalculatorState.Residency.fromObject(object.residency);
        }
        if (object.family != null) {
            if (typeof object.family !== "object")
                throw TypeError(".CalculatorState.family: object expected");
            message.family = $root.CalculatorState.Family.fromObject(object.family);
        }
        if (object.income != null) {
            if (typeof object.income !== "object")
                throw TypeError(".CalculatorState.income: object expected");
            message.income = $root.CalculatorState.Income.fromObject(object.income);
        }
        if (object.taxResidency != null) {
            if (typeof object.taxResidency !== "object")
                throw TypeError(".CalculatorState.taxResidency: object expected");
            message.taxResidency = $root.CalculatorState.TaxResidency.fromObject(object.taxResidency);
        }
        if (object.region != null) {
            if (typeof object.region !== "object")
                throw TypeError(".CalculatorState.region: object expected");
            message.region = $root.CalculatorState.Region.fromObject(object.region);
        }
        if (object.irnr != null) {
            if (typeof object.irnr !== "object")
                throw TypeError(".CalculatorState.irnr: object expected");
            message.irnr = $root.CalculatorState.NonResidentTax.fromObject(object.irnr);
        }
        if (object.special != null) {
            if (typeof object.special !== "object")
                throw TypeError(".CalculatorState.special: object expected");
            message.special = $root.CalculatorState.SpecialRegime.fromObject(object.special);
        }
        if (object.bills != null) {
            if (typeof object.bills !== "object")
                throw TypeError(".CalculatorState.bills: object expected");
            message.bills = $root.CalculatorState.Bills.fromObject(object.bills);
        }
        if (object.newActivity != null) {
            if (typeof object.newActivity !== "object")
                throw TypeError(".CalculatorState.newActivity: object expected");
            message.newActivity = $root.CalculatorState.NewActivityDeduction.fromObject(object.newActivity);
        }
        return message;
    };

    /**
     * Creates a plain object from a CalculatorState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CalculatorState
     * @static
     * @param {CalculatorState} message CalculatorState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CalculatorState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.page = options.enums === String ? "P_INITIAL" : 0;
            object.residency = null;
            object.family = null;
            object.income = null;
            object.taxResidency = null;
            object.region = null;
            object.irnr = null;
            object.special = null;
            object.bills = null;
            object.newActivity = null;
        }
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = options.enums === String ? $root.Page[message.page] === undefined ? message.page : $root.Page[message.page] : message.page;
        if (message.residency != null && message.hasOwnProperty("residency"))
            object.residency = $root.CalculatorState.Residency.toObject(message.residency, options);
        if (message.family != null && message.hasOwnProperty("family"))
            object.family = $root.CalculatorState.Family.toObject(message.family, options);
        if (message.income != null && message.hasOwnProperty("income"))
            object.income = $root.CalculatorState.Income.toObject(message.income, options);
        if (message.taxResidency != null && message.hasOwnProperty("taxResidency"))
            object.taxResidency = $root.CalculatorState.TaxResidency.toObject(message.taxResidency, options);
        if (message.region != null && message.hasOwnProperty("region"))
            object.region = $root.CalculatorState.Region.toObject(message.region, options);
        if (message.irnr != null && message.hasOwnProperty("irnr"))
            object.irnr = $root.CalculatorState.NonResidentTax.toObject(message.irnr, options);
        if (message.special != null && message.hasOwnProperty("special"))
            object.special = $root.CalculatorState.SpecialRegime.toObject(message.special, options);
        if (message.bills != null && message.hasOwnProperty("bills"))
            object.bills = $root.CalculatorState.Bills.toObject(message.bills, options);
        if (message.newActivity != null && message.hasOwnProperty("newActivity"))
            object.newActivity = $root.CalculatorState.NewActivityDeduction.toObject(message.newActivity, options);
        return object;
    };

    /**
     * Converts this CalculatorState to JSON.
     * @function toJSON
     * @memberof CalculatorState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CalculatorState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CalculatorState
     * @function getTypeUrl
     * @memberof CalculatorState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CalculatorState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CalculatorState";
    };

    CalculatorState.Residency = (function() {

        /**
         * Properties of a Residency.
         * @memberof CalculatorState
         * @interface IResidency
         * @property {ResidencyStatus|null} [status] Residency status
         */

        /**
         * Constructs a new Residency.
         * @memberof CalculatorState
         * @classdesc Represents a Residency.
         * @implements IResidency
         * @constructor
         * @param {CalculatorState.IResidency=} [properties] Properties to set
         */
        function Residency(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Residency status.
         * @member {ResidencyStatus} status
         * @memberof CalculatorState.Residency
         * @instance
         */
        Residency.prototype.status = 0;

        /**
         * Creates a new Residency instance using the specified properties.
         * @function create
         * @memberof CalculatorState.Residency
         * @static
         * @param {CalculatorState.IResidency=} [properties] Properties to set
         * @returns {CalculatorState.Residency} Residency instance
         */
        Residency.create = function create(properties) {
            return new Residency(properties);
        };

        /**
         * Encodes the specified Residency message. Does not implicitly {@link CalculatorState.Residency.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.Residency
         * @static
         * @param {CalculatorState.IResidency} message Residency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Residency.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified Residency message, length delimited. Does not implicitly {@link CalculatorState.Residency.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.Residency
         * @static
         * @param {CalculatorState.IResidency} message Residency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Residency.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Residency message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.Residency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.Residency} Residency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Residency.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Residency();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Residency message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.Residency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.Residency} Residency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Residency.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Residency message.
         * @function verify
         * @memberof CalculatorState.Residency
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Residency.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            return null;
        };

        /**
         * Creates a Residency message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.Residency
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.Residency} Residency
         */
        Residency.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.Residency)
                return object;
            let message = new $root.CalculatorState.Residency();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT":
            case 0:
                message.status = 0;
                break;
            case "RS_REMOTE_WORKER_WITH_SERVICE_CONTRACT":
            case 1:
                message.status = 1;
                break;
            case "RS_WORK_VISA":
            case 2:
                message.status = 2;
                break;
            case "RS_HIGHLY_QUALIFIED_SPECIALIST":
            case 3:
                message.status = 3;
                break;
            case "RS_STARTUP_AUTONOMO":
            case 4:
                message.status = 4;
                break;
            case "RS_CUENTA_AJENA":
            case 5:
                message.status = 5;
                break;
            case "RS_CUENTA_PROPIA":
            case 6:
                message.status = 6;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Residency message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.Residency
         * @static
         * @param {CalculatorState.Residency} message Residency
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Residency.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "RS_REMOTE_WORKER_WITH_EMPLOYMENT_CONTRACT" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.ResidencyStatus[message.status] === undefined ? message.status : $root.ResidencyStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this Residency to JSON.
         * @function toJSON
         * @memberof CalculatorState.Residency
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Residency.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Residency
         * @function getTypeUrl
         * @memberof CalculatorState.Residency
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Residency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.Residency";
        };

        return Residency;
    })();

    CalculatorState.Family = (function() {

        /**
         * Properties of a Family.
         * @memberof CalculatorState
         * @interface IFamily
         * @property {string|null} [userBirthdate] Family userBirthdate
         * @property {CalculatorState.Family.ISpouse|null} [spouse] Family spouse
         * @property {Array.<CalculatorState.Family.IChild>|null} [children] Family children
         */

        /**
         * Constructs a new Family.
         * @memberof CalculatorState
         * @classdesc Represents a Family.
         * @implements IFamily
         * @constructor
         * @param {CalculatorState.IFamily=} [properties] Properties to set
         */
        function Family(properties) {
            this.children = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Family userBirthdate.
         * @member {string} userBirthdate
         * @memberof CalculatorState.Family
         * @instance
         */
        Family.prototype.userBirthdate = "";

        /**
         * Family spouse.
         * @member {CalculatorState.Family.ISpouse|null|undefined} spouse
         * @memberof CalculatorState.Family
         * @instance
         */
        Family.prototype.spouse = null;

        /**
         * Family children.
         * @member {Array.<CalculatorState.Family.IChild>} children
         * @memberof CalculatorState.Family
         * @instance
         */
        Family.prototype.children = $util.emptyArray;

        /**
         * Creates a new Family instance using the specified properties.
         * @function create
         * @memberof CalculatorState.Family
         * @static
         * @param {CalculatorState.IFamily=} [properties] Properties to set
         * @returns {CalculatorState.Family} Family instance
         */
        Family.create = function create(properties) {
            return new Family(properties);
        };

        /**
         * Encodes the specified Family message. Does not implicitly {@link CalculatorState.Family.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.Family
         * @static
         * @param {CalculatorState.IFamily} message Family message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Family.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userBirthdate != null && Object.hasOwnProperty.call(message, "userBirthdate"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userBirthdate);
            if (message.spouse != null && Object.hasOwnProperty.call(message, "spouse"))
                $root.CalculatorState.Family.Spouse.encode(message.spouse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.children != null && message.children.length)
                for (let i = 0; i < message.children.length; ++i)
                    $root.CalculatorState.Family.Child.encode(message.children[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Family message, length delimited. Does not implicitly {@link CalculatorState.Family.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.Family
         * @static
         * @param {CalculatorState.IFamily} message Family message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Family.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Family message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.Family
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.Family} Family
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Family.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Family();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userBirthdate = reader.string();
                        break;
                    }
                case 2: {
                        message.spouse = $root.CalculatorState.Family.Spouse.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        if (!(message.children && message.children.length))
                            message.children = [];
                        message.children.push($root.CalculatorState.Family.Child.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Family message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.Family
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.Family} Family
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Family.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Family message.
         * @function verify
         * @memberof CalculatorState.Family
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Family.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userBirthdate != null && message.hasOwnProperty("userBirthdate"))
                if (!$util.isString(message.userBirthdate))
                    return "userBirthdate: string expected";
            if (message.spouse != null && message.hasOwnProperty("spouse")) {
                let error = $root.CalculatorState.Family.Spouse.verify(message.spouse);
                if (error)
                    return "spouse." + error;
            }
            if (message.children != null && message.hasOwnProperty("children")) {
                if (!Array.isArray(message.children))
                    return "children: array expected";
                for (let i = 0; i < message.children.length; ++i) {
                    let error = $root.CalculatorState.Family.Child.verify(message.children[i]);
                    if (error)
                        return "children." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Family message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.Family
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.Family} Family
         */
        Family.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.Family)
                return object;
            let message = new $root.CalculatorState.Family();
            if (object.userBirthdate != null)
                message.userBirthdate = String(object.userBirthdate);
            if (object.spouse != null) {
                if (typeof object.spouse !== "object")
                    throw TypeError(".CalculatorState.Family.spouse: object expected");
                message.spouse = $root.CalculatorState.Family.Spouse.fromObject(object.spouse);
            }
            if (object.children) {
                if (!Array.isArray(object.children))
                    throw TypeError(".CalculatorState.Family.children: array expected");
                message.children = [];
                for (let i = 0; i < object.children.length; ++i) {
                    if (typeof object.children[i] !== "object")
                        throw TypeError(".CalculatorState.Family.children: object expected");
                    message.children[i] = $root.CalculatorState.Family.Child.fromObject(object.children[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Family message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.Family
         * @static
         * @param {CalculatorState.Family} message Family
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Family.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.children = [];
            if (options.defaults) {
                object.userBirthdate = "";
                object.spouse = null;
            }
            if (message.userBirthdate != null && message.hasOwnProperty("userBirthdate"))
                object.userBirthdate = message.userBirthdate;
            if (message.spouse != null && message.hasOwnProperty("spouse"))
                object.spouse = $root.CalculatorState.Family.Spouse.toObject(message.spouse, options);
            if (message.children && message.children.length) {
                object.children = [];
                for (let j = 0; j < message.children.length; ++j)
                    object.children[j] = $root.CalculatorState.Family.Child.toObject(message.children[j], options);
            }
            return object;
        };

        /**
         * Converts this Family to JSON.
         * @function toJSON
         * @memberof CalculatorState.Family
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Family.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Family
         * @function getTypeUrl
         * @memberof CalculatorState.Family
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Family.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.Family";
        };

        Family.Spouse = (function() {

            /**
             * Properties of a Spouse.
             * @memberof CalculatorState.Family
             * @interface ISpouse
             * @property {string|null} [birthdate] Spouse birthdate
             */

            /**
             * Constructs a new Spouse.
             * @memberof CalculatorState.Family
             * @classdesc Represents a Spouse.
             * @implements ISpouse
             * @constructor
             * @param {CalculatorState.Family.ISpouse=} [properties] Properties to set
             */
            function Spouse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Spouse birthdate.
             * @member {string} birthdate
             * @memberof CalculatorState.Family.Spouse
             * @instance
             */
            Spouse.prototype.birthdate = "";

            /**
             * Creates a new Spouse instance using the specified properties.
             * @function create
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {CalculatorState.Family.ISpouse=} [properties] Properties to set
             * @returns {CalculatorState.Family.Spouse} Spouse instance
             */
            Spouse.create = function create(properties) {
                return new Spouse(properties);
            };

            /**
             * Encodes the specified Spouse message. Does not implicitly {@link CalculatorState.Family.Spouse.verify|verify} messages.
             * @function encode
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {CalculatorState.Family.ISpouse} message Spouse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Spouse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.birthdate != null && Object.hasOwnProperty.call(message, "birthdate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.birthdate);
                return writer;
            };

            /**
             * Encodes the specified Spouse message, length delimited. Does not implicitly {@link CalculatorState.Family.Spouse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {CalculatorState.Family.ISpouse} message Spouse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Spouse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Spouse message from the specified reader or buffer.
             * @function decode
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {CalculatorState.Family.Spouse} Spouse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Spouse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Family.Spouse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.birthdate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Spouse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {CalculatorState.Family.Spouse} Spouse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Spouse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Spouse message.
             * @function verify
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Spouse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.birthdate != null && message.hasOwnProperty("birthdate"))
                    if (!$util.isString(message.birthdate))
                        return "birthdate: string expected";
                return null;
            };

            /**
             * Creates a Spouse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {CalculatorState.Family.Spouse} Spouse
             */
            Spouse.fromObject = function fromObject(object) {
                if (object instanceof $root.CalculatorState.Family.Spouse)
                    return object;
                let message = new $root.CalculatorState.Family.Spouse();
                if (object.birthdate != null)
                    message.birthdate = String(object.birthdate);
                return message;
            };

            /**
             * Creates a plain object from a Spouse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {CalculatorState.Family.Spouse} message Spouse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Spouse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.birthdate = "";
                if (message.birthdate != null && message.hasOwnProperty("birthdate"))
                    object.birthdate = message.birthdate;
                return object;
            };

            /**
             * Converts this Spouse to JSON.
             * @function toJSON
             * @memberof CalculatorState.Family.Spouse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Spouse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Spouse
             * @function getTypeUrl
             * @memberof CalculatorState.Family.Spouse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Spouse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/CalculatorState.Family.Spouse";
            };

            return Spouse;
        })();

        Family.Child = (function() {

            /**
             * Properties of a Child.
             * @memberof CalculatorState.Family
             * @interface IChild
             * @property {string|null} [birthdate] Child birthdate
             */

            /**
             * Constructs a new Child.
             * @memberof CalculatorState.Family
             * @classdesc Represents a Child.
             * @implements IChild
             * @constructor
             * @param {CalculatorState.Family.IChild=} [properties] Properties to set
             */
            function Child(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Child birthdate.
             * @member {string} birthdate
             * @memberof CalculatorState.Family.Child
             * @instance
             */
            Child.prototype.birthdate = "";

            /**
             * Creates a new Child instance using the specified properties.
             * @function create
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {CalculatorState.Family.IChild=} [properties] Properties to set
             * @returns {CalculatorState.Family.Child} Child instance
             */
            Child.create = function create(properties) {
                return new Child(properties);
            };

            /**
             * Encodes the specified Child message. Does not implicitly {@link CalculatorState.Family.Child.verify|verify} messages.
             * @function encode
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {CalculatorState.Family.IChild} message Child message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Child.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.birthdate != null && Object.hasOwnProperty.call(message, "birthdate"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.birthdate);
                return writer;
            };

            /**
             * Encodes the specified Child message, length delimited. Does not implicitly {@link CalculatorState.Family.Child.verify|verify} messages.
             * @function encodeDelimited
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {CalculatorState.Family.IChild} message Child message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Child.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Child message from the specified reader or buffer.
             * @function decode
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {CalculatorState.Family.Child} Child
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Child.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Family.Child();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.birthdate = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Child message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {CalculatorState.Family.Child} Child
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Child.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Child message.
             * @function verify
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Child.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.birthdate != null && message.hasOwnProperty("birthdate"))
                    if (!$util.isString(message.birthdate))
                        return "birthdate: string expected";
                return null;
            };

            /**
             * Creates a Child message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {CalculatorState.Family.Child} Child
             */
            Child.fromObject = function fromObject(object) {
                if (object instanceof $root.CalculatorState.Family.Child)
                    return object;
                let message = new $root.CalculatorState.Family.Child();
                if (object.birthdate != null)
                    message.birthdate = String(object.birthdate);
                return message;
            };

            /**
             * Creates a plain object from a Child message. Also converts values to other types if specified.
             * @function toObject
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {CalculatorState.Family.Child} message Child
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Child.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.birthdate = "";
                if (message.birthdate != null && message.hasOwnProperty("birthdate"))
                    object.birthdate = message.birthdate;
                return object;
            };

            /**
             * Converts this Child to JSON.
             * @function toJSON
             * @memberof CalculatorState.Family.Child
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Child.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Child
             * @function getTypeUrl
             * @memberof CalculatorState.Family.Child
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Child.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/CalculatorState.Family.Child";
            };

            return Child;
        })();

        return Family;
    })();

    CalculatorState.Income = (function() {

        /**
         * Properties of an Income.
         * @memberof CalculatorState
         * @interface IIncome
         * @property {CalculatorState.Income.IPersonIncome|null} [user] Income user
         * @property {CalculatorState.Income.IPersonIncome|null} [spouse] Income spouse
         */

        /**
         * Constructs a new Income.
         * @memberof CalculatorState
         * @classdesc Represents an Income.
         * @implements IIncome
         * @constructor
         * @param {CalculatorState.IIncome=} [properties] Properties to set
         */
        function Income(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Income user.
         * @member {CalculatorState.Income.IPersonIncome|null|undefined} user
         * @memberof CalculatorState.Income
         * @instance
         */
        Income.prototype.user = null;

        /**
         * Income spouse.
         * @member {CalculatorState.Income.IPersonIncome|null|undefined} spouse
         * @memberof CalculatorState.Income
         * @instance
         */
        Income.prototype.spouse = null;

        /**
         * Creates a new Income instance using the specified properties.
         * @function create
         * @memberof CalculatorState.Income
         * @static
         * @param {CalculatorState.IIncome=} [properties] Properties to set
         * @returns {CalculatorState.Income} Income instance
         */
        Income.create = function create(properties) {
            return new Income(properties);
        };

        /**
         * Encodes the specified Income message. Does not implicitly {@link CalculatorState.Income.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.Income
         * @static
         * @param {CalculatorState.IIncome} message Income message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Income.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.CalculatorState.Income.PersonIncome.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.spouse != null && Object.hasOwnProperty.call(message, "spouse"))
                $root.CalculatorState.Income.PersonIncome.encode(message.spouse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Income message, length delimited. Does not implicitly {@link CalculatorState.Income.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.Income
         * @static
         * @param {CalculatorState.IIncome} message Income message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Income.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Income message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.Income
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.Income} Income
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Income.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Income();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.user = $root.CalculatorState.Income.PersonIncome.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.spouse = $root.CalculatorState.Income.PersonIncome.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Income message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.Income
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.Income} Income
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Income.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Income message.
         * @function verify
         * @memberof CalculatorState.Income
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Income.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.CalculatorState.Income.PersonIncome.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.spouse != null && message.hasOwnProperty("spouse")) {
                let error = $root.CalculatorState.Income.PersonIncome.verify(message.spouse);
                if (error)
                    return "spouse." + error;
            }
            return null;
        };

        /**
         * Creates an Income message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.Income
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.Income} Income
         */
        Income.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.Income)
                return object;
            let message = new $root.CalculatorState.Income();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".CalculatorState.Income.user: object expected");
                message.user = $root.CalculatorState.Income.PersonIncome.fromObject(object.user);
            }
            if (object.spouse != null) {
                if (typeof object.spouse !== "object")
                    throw TypeError(".CalculatorState.Income.spouse: object expected");
                message.spouse = $root.CalculatorState.Income.PersonIncome.fromObject(object.spouse);
            }
            return message;
        };

        /**
         * Creates a plain object from an Income message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.Income
         * @static
         * @param {CalculatorState.Income} message Income
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Income.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.user = null;
                object.spouse = null;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.CalculatorState.Income.PersonIncome.toObject(message.user, options);
            if (message.spouse != null && message.hasOwnProperty("spouse"))
                object.spouse = $root.CalculatorState.Income.PersonIncome.toObject(message.spouse, options);
            return object;
        };

        /**
         * Converts this Income to JSON.
         * @function toJSON
         * @memberof CalculatorState.Income
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Income.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Income
         * @function getTypeUrl
         * @memberof CalculatorState.Income
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Income.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.Income";
        };

        Income.PersonIncome = (function() {

            /**
             * Properties of a PersonIncome.
             * @memberof CalculatorState.Income
             * @interface IPersonIncome
             * @property {IMoneyAmount|null} [amount] PersonIncome amount
             * @property {string|null} [contractDate] PersonIncome contractDate
             * @property {string|null} [employerCountry] PersonIncome employerCountry
             */

            /**
             * Constructs a new PersonIncome.
             * @memberof CalculatorState.Income
             * @classdesc Represents a PersonIncome.
             * @implements IPersonIncome
             * @constructor
             * @param {CalculatorState.Income.IPersonIncome=} [properties] Properties to set
             */
            function PersonIncome(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PersonIncome amount.
             * @member {IMoneyAmount|null|undefined} amount
             * @memberof CalculatorState.Income.PersonIncome
             * @instance
             */
            PersonIncome.prototype.amount = null;

            /**
             * PersonIncome contractDate.
             * @member {string} contractDate
             * @memberof CalculatorState.Income.PersonIncome
             * @instance
             */
            PersonIncome.prototype.contractDate = "";

            /**
             * PersonIncome employerCountry.
             * @member {string} employerCountry
             * @memberof CalculatorState.Income.PersonIncome
             * @instance
             */
            PersonIncome.prototype.employerCountry = "";

            /**
             * Creates a new PersonIncome instance using the specified properties.
             * @function create
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {CalculatorState.Income.IPersonIncome=} [properties] Properties to set
             * @returns {CalculatorState.Income.PersonIncome} PersonIncome instance
             */
            PersonIncome.create = function create(properties) {
                return new PersonIncome(properties);
            };

            /**
             * Encodes the specified PersonIncome message. Does not implicitly {@link CalculatorState.Income.PersonIncome.verify|verify} messages.
             * @function encode
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {CalculatorState.Income.IPersonIncome} message PersonIncome message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PersonIncome.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.MoneyAmount.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.contractDate != null && Object.hasOwnProperty.call(message, "contractDate"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.contractDate);
                if (message.employerCountry != null && Object.hasOwnProperty.call(message, "employerCountry"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.employerCountry);
                return writer;
            };

            /**
             * Encodes the specified PersonIncome message, length delimited. Does not implicitly {@link CalculatorState.Income.PersonIncome.verify|verify} messages.
             * @function encodeDelimited
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {CalculatorState.Income.IPersonIncome} message PersonIncome message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PersonIncome.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PersonIncome message from the specified reader or buffer.
             * @function decode
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {CalculatorState.Income.PersonIncome} PersonIncome
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PersonIncome.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Income.PersonIncome();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.amount = $root.MoneyAmount.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.contractDate = reader.string();
                            break;
                        }
                    case 3: {
                            message.employerCountry = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PersonIncome message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {CalculatorState.Income.PersonIncome} PersonIncome
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PersonIncome.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PersonIncome message.
             * @function verify
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PersonIncome.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    let error = $root.MoneyAmount.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                if (message.contractDate != null && message.hasOwnProperty("contractDate"))
                    if (!$util.isString(message.contractDate))
                        return "contractDate: string expected";
                if (message.employerCountry != null && message.hasOwnProperty("employerCountry"))
                    if (!$util.isString(message.employerCountry))
                        return "employerCountry: string expected";
                return null;
            };

            /**
             * Creates a PersonIncome message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {CalculatorState.Income.PersonIncome} PersonIncome
             */
            PersonIncome.fromObject = function fromObject(object) {
                if (object instanceof $root.CalculatorState.Income.PersonIncome)
                    return object;
                let message = new $root.CalculatorState.Income.PersonIncome();
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".CalculatorState.Income.PersonIncome.amount: object expected");
                    message.amount = $root.MoneyAmount.fromObject(object.amount);
                }
                if (object.contractDate != null)
                    message.contractDate = String(object.contractDate);
                if (object.employerCountry != null)
                    message.employerCountry = String(object.employerCountry);
                return message;
            };

            /**
             * Creates a plain object from a PersonIncome message. Also converts values to other types if specified.
             * @function toObject
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {CalculatorState.Income.PersonIncome} message PersonIncome
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PersonIncome.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.amount = null;
                    object.contractDate = "";
                    object.employerCountry = "";
                }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.MoneyAmount.toObject(message.amount, options);
                if (message.contractDate != null && message.hasOwnProperty("contractDate"))
                    object.contractDate = message.contractDate;
                if (message.employerCountry != null && message.hasOwnProperty("employerCountry"))
                    object.employerCountry = message.employerCountry;
                return object;
            };

            /**
             * Converts this PersonIncome to JSON.
             * @function toJSON
             * @memberof CalculatorState.Income.PersonIncome
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PersonIncome.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PersonIncome
             * @function getTypeUrl
             * @memberof CalculatorState.Income.PersonIncome
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PersonIncome.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/CalculatorState.Income.PersonIncome";
            };

            return PersonIncome;
        })();

        return Income;
    })();

    CalculatorState.TaxResidency = (function() {

        /**
         * Properties of a TaxResidency.
         * @memberof CalculatorState
         * @interface ITaxResidency
         * @property {CalculatorState.TaxResidency.Status|null} [status] TaxResidency status
         * @property {string|null} [enterDate] TaxResidency enterDate
         * @property {string|null} [country] TaxResidency country
         * @property {boolean|null} [agreeNoDta] TaxResidency agreeNoDta
         * @property {boolean|null} [taxResidentCertificate] TaxResidency taxResidentCertificate
         * @property {boolean|null} [agreeDays] TaxResidency agreeDays
         * @property {boolean|null} [financialCenter] TaxResidency financialCenter
         * @property {boolean|null} [familyResidence] TaxResidency familyResidence
         * @property {boolean|null} [conflict] TaxResidency conflict
         */

        /**
         * Constructs a new TaxResidency.
         * @memberof CalculatorState
         * @classdesc Represents a TaxResidency.
         * @implements ITaxResidency
         * @constructor
         * @param {CalculatorState.ITaxResidency=} [properties] Properties to set
         */
        function TaxResidency(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaxResidency status.
         * @member {CalculatorState.TaxResidency.Status} status
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.status = 0;

        /**
         * TaxResidency enterDate.
         * @member {string} enterDate
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.enterDate = "";

        /**
         * TaxResidency country.
         * @member {string} country
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.country = "";

        /**
         * TaxResidency agreeNoDta.
         * @member {boolean} agreeNoDta
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.agreeNoDta = false;

        /**
         * TaxResidency taxResidentCertificate.
         * @member {boolean} taxResidentCertificate
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.taxResidentCertificate = false;

        /**
         * TaxResidency agreeDays.
         * @member {boolean} agreeDays
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.agreeDays = false;

        /**
         * TaxResidency financialCenter.
         * @member {boolean} financialCenter
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.financialCenter = false;

        /**
         * TaxResidency familyResidence.
         * @member {boolean} familyResidence
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.familyResidence = false;

        /**
         * TaxResidency conflict.
         * @member {boolean} conflict
         * @memberof CalculatorState.TaxResidency
         * @instance
         */
        TaxResidency.prototype.conflict = false;

        /**
         * Creates a new TaxResidency instance using the specified properties.
         * @function create
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {CalculatorState.ITaxResidency=} [properties] Properties to set
         * @returns {CalculatorState.TaxResidency} TaxResidency instance
         */
        TaxResidency.create = function create(properties) {
            return new TaxResidency(properties);
        };

        /**
         * Encodes the specified TaxResidency message. Does not implicitly {@link CalculatorState.TaxResidency.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {CalculatorState.ITaxResidency} message TaxResidency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaxResidency.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.enterDate != null && Object.hasOwnProperty.call(message, "enterDate"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.enterDate);
            if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.country);
            if (message.agreeNoDta != null && Object.hasOwnProperty.call(message, "agreeNoDta"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.agreeNoDta);
            if (message.taxResidentCertificate != null && Object.hasOwnProperty.call(message, "taxResidentCertificate"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.taxResidentCertificate);
            if (message.agreeDays != null && Object.hasOwnProperty.call(message, "agreeDays"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.agreeDays);
            if (message.financialCenter != null && Object.hasOwnProperty.call(message, "financialCenter"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.financialCenter);
            if (message.familyResidence != null && Object.hasOwnProperty.call(message, "familyResidence"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.familyResidence);
            if (message.conflict != null && Object.hasOwnProperty.call(message, "conflict"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.conflict);
            return writer;
        };

        /**
         * Encodes the specified TaxResidency message, length delimited. Does not implicitly {@link CalculatorState.TaxResidency.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {CalculatorState.ITaxResidency} message TaxResidency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaxResidency.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TaxResidency message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.TaxResidency} TaxResidency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaxResidency.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.TaxResidency();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.enterDate = reader.string();
                        break;
                    }
                case 3: {
                        message.country = reader.string();
                        break;
                    }
                case 10: {
                        message.agreeNoDta = reader.bool();
                        break;
                    }
                case 11: {
                        message.taxResidentCertificate = reader.bool();
                        break;
                    }
                case 12: {
                        message.agreeDays = reader.bool();
                        break;
                    }
                case 13: {
                        message.financialCenter = reader.bool();
                        break;
                    }
                case 14: {
                        message.familyResidence = reader.bool();
                        break;
                    }
                case 15: {
                        message.conflict = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TaxResidency message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.TaxResidency} TaxResidency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaxResidency.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TaxResidency message.
         * @function verify
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TaxResidency.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.enterDate != null && message.hasOwnProperty("enterDate"))
                if (!$util.isString(message.enterDate))
                    return "enterDate: string expected";
            if (message.country != null && message.hasOwnProperty("country"))
                if (!$util.isString(message.country))
                    return "country: string expected";
            if (message.agreeNoDta != null && message.hasOwnProperty("agreeNoDta"))
                if (typeof message.agreeNoDta !== "boolean")
                    return "agreeNoDta: boolean expected";
            if (message.taxResidentCertificate != null && message.hasOwnProperty("taxResidentCertificate"))
                if (typeof message.taxResidentCertificate !== "boolean")
                    return "taxResidentCertificate: boolean expected";
            if (message.agreeDays != null && message.hasOwnProperty("agreeDays"))
                if (typeof message.agreeDays !== "boolean")
                    return "agreeDays: boolean expected";
            if (message.financialCenter != null && message.hasOwnProperty("financialCenter"))
                if (typeof message.financialCenter !== "boolean")
                    return "financialCenter: boolean expected";
            if (message.familyResidence != null && message.hasOwnProperty("familyResidence"))
                if (typeof message.familyResidence !== "boolean")
                    return "familyResidence: boolean expected";
            if (message.conflict != null && message.hasOwnProperty("conflict"))
                if (typeof message.conflict !== "boolean")
                    return "conflict: boolean expected";
            return null;
        };

        /**
         * Creates a TaxResidency message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.TaxResidency} TaxResidency
         */
        TaxResidency.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.TaxResidency)
                return object;
            let message = new $root.CalculatorState.TaxResidency();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "TRS_RESIDENT":
            case 0:
                message.status = 0;
                break;
            case "TRS_NON_RESIDENT":
            case 1:
                message.status = 1;
                break;
            }
            if (object.enterDate != null)
                message.enterDate = String(object.enterDate);
            if (object.country != null)
                message.country = String(object.country);
            if (object.agreeNoDta != null)
                message.agreeNoDta = Boolean(object.agreeNoDta);
            if (object.taxResidentCertificate != null)
                message.taxResidentCertificate = Boolean(object.taxResidentCertificate);
            if (object.agreeDays != null)
                message.agreeDays = Boolean(object.agreeDays);
            if (object.financialCenter != null)
                message.financialCenter = Boolean(object.financialCenter);
            if (object.familyResidence != null)
                message.familyResidence = Boolean(object.familyResidence);
            if (object.conflict != null)
                message.conflict = Boolean(object.conflict);
            return message;
        };

        /**
         * Creates a plain object from a TaxResidency message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {CalculatorState.TaxResidency} message TaxResidency
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TaxResidency.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "TRS_RESIDENT" : 0;
                object.enterDate = "";
                object.country = "";
                object.agreeNoDta = false;
                object.taxResidentCertificate = false;
                object.agreeDays = false;
                object.financialCenter = false;
                object.familyResidence = false;
                object.conflict = false;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.CalculatorState.TaxResidency.Status[message.status] === undefined ? message.status : $root.CalculatorState.TaxResidency.Status[message.status] : message.status;
            if (message.enterDate != null && message.hasOwnProperty("enterDate"))
                object.enterDate = message.enterDate;
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = message.country;
            if (message.agreeNoDta != null && message.hasOwnProperty("agreeNoDta"))
                object.agreeNoDta = message.agreeNoDta;
            if (message.taxResidentCertificate != null && message.hasOwnProperty("taxResidentCertificate"))
                object.taxResidentCertificate = message.taxResidentCertificate;
            if (message.agreeDays != null && message.hasOwnProperty("agreeDays"))
                object.agreeDays = message.agreeDays;
            if (message.financialCenter != null && message.hasOwnProperty("financialCenter"))
                object.financialCenter = message.financialCenter;
            if (message.familyResidence != null && message.hasOwnProperty("familyResidence"))
                object.familyResidence = message.familyResidence;
            if (message.conflict != null && message.hasOwnProperty("conflict"))
                object.conflict = message.conflict;
            return object;
        };

        /**
         * Converts this TaxResidency to JSON.
         * @function toJSON
         * @memberof CalculatorState.TaxResidency
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TaxResidency.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TaxResidency
         * @function getTypeUrl
         * @memberof CalculatorState.TaxResidency
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TaxResidency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.TaxResidency";
        };

        /**
         * Status enum.
         * @name CalculatorState.TaxResidency.Status
         * @enum {number}
         * @property {number} TRS_RESIDENT=0 TRS_RESIDENT value
         * @property {number} TRS_NON_RESIDENT=1 TRS_NON_RESIDENT value
         */
        TaxResidency.Status = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TRS_RESIDENT"] = 0;
            values[valuesById[1] = "TRS_NON_RESIDENT"] = 1;
            return values;
        })();

        return TaxResidency;
    })();

    CalculatorState.Region = (function() {

        /**
         * Properties of a Region.
         * @memberof CalculatorState
         * @interface IRegion
         * @property {ComunidadAutonoma|null} [selected] Region selected
         * @property {Array.<ComunidadAutonoma>|null} [excluded] Region excluded
         */

        /**
         * Constructs a new Region.
         * @memberof CalculatorState
         * @classdesc Represents a Region.
         * @implements IRegion
         * @constructor
         * @param {CalculatorState.IRegion=} [properties] Properties to set
         */
        function Region(properties) {
            this.excluded = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Region selected.
         * @member {ComunidadAutonoma} selected
         * @memberof CalculatorState.Region
         * @instance
         */
        Region.prototype.selected = 0;

        /**
         * Region excluded.
         * @member {Array.<ComunidadAutonoma>} excluded
         * @memberof CalculatorState.Region
         * @instance
         */
        Region.prototype.excluded = $util.emptyArray;

        /**
         * Creates a new Region instance using the specified properties.
         * @function create
         * @memberof CalculatorState.Region
         * @static
         * @param {CalculatorState.IRegion=} [properties] Properties to set
         * @returns {CalculatorState.Region} Region instance
         */
        Region.create = function create(properties) {
            return new Region(properties);
        };

        /**
         * Encodes the specified Region message. Does not implicitly {@link CalculatorState.Region.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.Region
         * @static
         * @param {CalculatorState.IRegion} message Region message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Region.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.selected);
            if (message.excluded != null && message.excluded.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (let i = 0; i < message.excluded.length; ++i)
                    writer.int32(message.excluded[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified Region message, length delimited. Does not implicitly {@link CalculatorState.Region.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.Region
         * @static
         * @param {CalculatorState.IRegion} message Region message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Region.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Region message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.Region
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.Region} Region
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Region.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Region();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.selected = reader.int32();
                        break;
                    }
                case 10: {
                        if (!(message.excluded && message.excluded.length))
                            message.excluded = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.excluded.push(reader.int32());
                        } else
                            message.excluded.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Region message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.Region
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.Region} Region
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Region.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Region message.
         * @function verify
         * @memberof CalculatorState.Region
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Region.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.selected != null && message.hasOwnProperty("selected"))
                switch (message.selected) {
                default:
                    return "selected: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                    break;
                }
            if (message.excluded != null && message.hasOwnProperty("excluded")) {
                if (!Array.isArray(message.excluded))
                    return "excluded: array expected";
                for (let i = 0; i < message.excluded.length; ++i)
                    switch (message.excluded[i]) {
                    default:
                        return "excluded: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a Region message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.Region
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.Region} Region
         */
        Region.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.Region)
                return object;
            let message = new $root.CalculatorState.Region();
            switch (object.selected) {
            default:
                if (typeof object.selected === "number") {
                    message.selected = object.selected;
                    break;
                }
                break;
            case "CA_ANDALUCIA":
            case 0:
                message.selected = 0;
                break;
            case "CA_ARAGON":
            case 1:
                message.selected = 1;
                break;
            case "CA_ASTURIAS":
            case 2:
                message.selected = 2;
                break;
            case "CA_BALEARIC_ISLANDS":
            case 3:
                message.selected = 3;
                break;
            case "CA_BASQUE_COUNTRY":
            case 4:
                message.selected = 4;
                break;
            case "CA_CANARY_ISLANDS":
            case 5:
                message.selected = 5;
                break;
            case "CA_CANTABRIA":
            case 6:
                message.selected = 6;
                break;
            case "CA_CASTILE_LA_MANCHA":
            case 7:
                message.selected = 7;
                break;
            case "CA_CASTILE_AND_LEON":
            case 8:
                message.selected = 8;
                break;
            case "CA_CATALONIA":
            case 9:
                message.selected = 9;
                break;
            case "CA_EXTREMADURA":
            case 10:
                message.selected = 10;
                break;
            case "CA_GALICIA":
            case 11:
                message.selected = 11;
                break;
            case "CA_LA_RIOJA":
            case 12:
                message.selected = 12;
                break;
            case "CA_MADRID":
            case 13:
                message.selected = 13;
                break;
            case "CA_MURCIA":
            case 14:
                message.selected = 14;
                break;
            case "CA_NAVARRE":
            case 15:
                message.selected = 15;
                break;
            case "CA_VALENCIA":
            case 16:
                message.selected = 16;
                break;
            }
            if (object.excluded) {
                if (!Array.isArray(object.excluded))
                    throw TypeError(".CalculatorState.Region.excluded: array expected");
                message.excluded = [];
                for (let i = 0; i < object.excluded.length; ++i)
                    switch (object.excluded[i]) {
                    default:
                        if (typeof object.excluded[i] === "number") {
                            message.excluded[i] = object.excluded[i];
                            break;
                        }
                    case "CA_ANDALUCIA":
                    case 0:
                        message.excluded[i] = 0;
                        break;
                    case "CA_ARAGON":
                    case 1:
                        message.excluded[i] = 1;
                        break;
                    case "CA_ASTURIAS":
                    case 2:
                        message.excluded[i] = 2;
                        break;
                    case "CA_BALEARIC_ISLANDS":
                    case 3:
                        message.excluded[i] = 3;
                        break;
                    case "CA_BASQUE_COUNTRY":
                    case 4:
                        message.excluded[i] = 4;
                        break;
                    case "CA_CANARY_ISLANDS":
                    case 5:
                        message.excluded[i] = 5;
                        break;
                    case "CA_CANTABRIA":
                    case 6:
                        message.excluded[i] = 6;
                        break;
                    case "CA_CASTILE_LA_MANCHA":
                    case 7:
                        message.excluded[i] = 7;
                        break;
                    case "CA_CASTILE_AND_LEON":
                    case 8:
                        message.excluded[i] = 8;
                        break;
                    case "CA_CATALONIA":
                    case 9:
                        message.excluded[i] = 9;
                        break;
                    case "CA_EXTREMADURA":
                    case 10:
                        message.excluded[i] = 10;
                        break;
                    case "CA_GALICIA":
                    case 11:
                        message.excluded[i] = 11;
                        break;
                    case "CA_LA_RIOJA":
                    case 12:
                        message.excluded[i] = 12;
                        break;
                    case "CA_MADRID":
                    case 13:
                        message.excluded[i] = 13;
                        break;
                    case "CA_MURCIA":
                    case 14:
                        message.excluded[i] = 14;
                        break;
                    case "CA_NAVARRE":
                    case 15:
                        message.excluded[i] = 15;
                        break;
                    case "CA_VALENCIA":
                    case 16:
                        message.excluded[i] = 16;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a Region message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.Region
         * @static
         * @param {CalculatorState.Region} message Region
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Region.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.excluded = [];
            if (options.defaults)
                object.selected = options.enums === String ? "CA_ANDALUCIA" : 0;
            if (message.selected != null && message.hasOwnProperty("selected"))
                object.selected = options.enums === String ? $root.ComunidadAutonoma[message.selected] === undefined ? message.selected : $root.ComunidadAutonoma[message.selected] : message.selected;
            if (message.excluded && message.excluded.length) {
                object.excluded = [];
                for (let j = 0; j < message.excluded.length; ++j)
                    object.excluded[j] = options.enums === String ? $root.ComunidadAutonoma[message.excluded[j]] === undefined ? message.excluded[j] : $root.ComunidadAutonoma[message.excluded[j]] : message.excluded[j];
            }
            return object;
        };

        /**
         * Converts this Region to JSON.
         * @function toJSON
         * @memberof CalculatorState.Region
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Region.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Region
         * @function getTypeUrl
         * @memberof CalculatorState.Region
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Region.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.Region";
        };

        return Region;
    })();

    CalculatorState.SpecialRegime = (function() {

        /**
         * Properties of a SpecialRegime.
         * @memberof CalculatorState
         * @interface ISpecialRegime
         * @property {boolean|null} [beckham] SpecialRegime beckham
         * @property {boolean|null} [vizkaya] SpecialRegime vizkaya
         */

        /**
         * Constructs a new SpecialRegime.
         * @memberof CalculatorState
         * @classdesc Represents a SpecialRegime.
         * @implements ISpecialRegime
         * @constructor
         * @param {CalculatorState.ISpecialRegime=} [properties] Properties to set
         */
        function SpecialRegime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecialRegime beckham.
         * @member {boolean} beckham
         * @memberof CalculatorState.SpecialRegime
         * @instance
         */
        SpecialRegime.prototype.beckham = false;

        /**
         * SpecialRegime vizkaya.
         * @member {boolean} vizkaya
         * @memberof CalculatorState.SpecialRegime
         * @instance
         */
        SpecialRegime.prototype.vizkaya = false;

        /**
         * Creates a new SpecialRegime instance using the specified properties.
         * @function create
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {CalculatorState.ISpecialRegime=} [properties] Properties to set
         * @returns {CalculatorState.SpecialRegime} SpecialRegime instance
         */
        SpecialRegime.create = function create(properties) {
            return new SpecialRegime(properties);
        };

        /**
         * Encodes the specified SpecialRegime message. Does not implicitly {@link CalculatorState.SpecialRegime.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {CalculatorState.ISpecialRegime} message SpecialRegime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecialRegime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.beckham != null && Object.hasOwnProperty.call(message, "beckham"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.beckham);
            if (message.vizkaya != null && Object.hasOwnProperty.call(message, "vizkaya"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.vizkaya);
            return writer;
        };

        /**
         * Encodes the specified SpecialRegime message, length delimited. Does not implicitly {@link CalculatorState.SpecialRegime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {CalculatorState.ISpecialRegime} message SpecialRegime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecialRegime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpecialRegime message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.SpecialRegime} SpecialRegime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecialRegime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.SpecialRegime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.beckham = reader.bool();
                        break;
                    }
                case 2: {
                        message.vizkaya = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpecialRegime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.SpecialRegime} SpecialRegime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecialRegime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpecialRegime message.
         * @function verify
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpecialRegime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.beckham != null && message.hasOwnProperty("beckham"))
                if (typeof message.beckham !== "boolean")
                    return "beckham: boolean expected";
            if (message.vizkaya != null && message.hasOwnProperty("vizkaya"))
                if (typeof message.vizkaya !== "boolean")
                    return "vizkaya: boolean expected";
            return null;
        };

        /**
         * Creates a SpecialRegime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.SpecialRegime} SpecialRegime
         */
        SpecialRegime.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.SpecialRegime)
                return object;
            let message = new $root.CalculatorState.SpecialRegime();
            if (object.beckham != null)
                message.beckham = Boolean(object.beckham);
            if (object.vizkaya != null)
                message.vizkaya = Boolean(object.vizkaya);
            return message;
        };

        /**
         * Creates a plain object from a SpecialRegime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {CalculatorState.SpecialRegime} message SpecialRegime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpecialRegime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.beckham = false;
                object.vizkaya = false;
            }
            if (message.beckham != null && message.hasOwnProperty("beckham"))
                object.beckham = message.beckham;
            if (message.vizkaya != null && message.hasOwnProperty("vizkaya"))
                object.vizkaya = message.vizkaya;
            return object;
        };

        /**
         * Converts this SpecialRegime to JSON.
         * @function toJSON
         * @memberof CalculatorState.SpecialRegime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpecialRegime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SpecialRegime
         * @function getTypeUrl
         * @memberof CalculatorState.SpecialRegime
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SpecialRegime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.SpecialRegime";
        };

        return SpecialRegime;
    })();

    CalculatorState.NonResidentTax = (function() {

        /**
         * Properties of a NonResidentTax.
         * @memberof CalculatorState
         * @interface INonResidentTax
         * @property {string|null} [registerDate] NonResidentTax registerDate
         * @property {IMoneyAmount|null} [income] NonResidentTax income
         * @property {IMoneyAmount|null} [spouseIncome] NonResidentTax spouseIncome
         * @property {number|null} [months] NonResidentTax months
         */

        /**
         * Constructs a new NonResidentTax.
         * @memberof CalculatorState
         * @classdesc Represents a NonResidentTax.
         * @implements INonResidentTax
         * @constructor
         * @param {CalculatorState.INonResidentTax=} [properties] Properties to set
         */
        function NonResidentTax(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NonResidentTax registerDate.
         * @member {string} registerDate
         * @memberof CalculatorState.NonResidentTax
         * @instance
         */
        NonResidentTax.prototype.registerDate = "";

        /**
         * NonResidentTax income.
         * @member {IMoneyAmount|null|undefined} income
         * @memberof CalculatorState.NonResidentTax
         * @instance
         */
        NonResidentTax.prototype.income = null;

        /**
         * NonResidentTax spouseIncome.
         * @member {IMoneyAmount|null|undefined} spouseIncome
         * @memberof CalculatorState.NonResidentTax
         * @instance
         */
        NonResidentTax.prototype.spouseIncome = null;

        /**
         * NonResidentTax months.
         * @member {number} months
         * @memberof CalculatorState.NonResidentTax
         * @instance
         */
        NonResidentTax.prototype.months = 0;

        /**
         * Creates a new NonResidentTax instance using the specified properties.
         * @function create
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {CalculatorState.INonResidentTax=} [properties] Properties to set
         * @returns {CalculatorState.NonResidentTax} NonResidentTax instance
         */
        NonResidentTax.create = function create(properties) {
            return new NonResidentTax(properties);
        };

        /**
         * Encodes the specified NonResidentTax message. Does not implicitly {@link CalculatorState.NonResidentTax.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {CalculatorState.INonResidentTax} message NonResidentTax message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NonResidentTax.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.registerDate != null && Object.hasOwnProperty.call(message, "registerDate"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.registerDate);
            if (message.income != null && Object.hasOwnProperty.call(message, "income"))
                $root.MoneyAmount.encode(message.income, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.spouseIncome != null && Object.hasOwnProperty.call(message, "spouseIncome"))
                $root.MoneyAmount.encode(message.spouseIncome, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.months != null && Object.hasOwnProperty.call(message, "months"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.months);
            return writer;
        };

        /**
         * Encodes the specified NonResidentTax message, length delimited. Does not implicitly {@link CalculatorState.NonResidentTax.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {CalculatorState.INonResidentTax} message NonResidentTax message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NonResidentTax.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NonResidentTax message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.NonResidentTax} NonResidentTax
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NonResidentTax.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.NonResidentTax();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.registerDate = reader.string();
                        break;
                    }
                case 2: {
                        message.income = $root.MoneyAmount.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.spouseIncome = $root.MoneyAmount.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.months = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NonResidentTax message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.NonResidentTax} NonResidentTax
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NonResidentTax.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NonResidentTax message.
         * @function verify
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NonResidentTax.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.registerDate != null && message.hasOwnProperty("registerDate"))
                if (!$util.isString(message.registerDate))
                    return "registerDate: string expected";
            if (message.income != null && message.hasOwnProperty("income")) {
                let error = $root.MoneyAmount.verify(message.income);
                if (error)
                    return "income." + error;
            }
            if (message.spouseIncome != null && message.hasOwnProperty("spouseIncome")) {
                let error = $root.MoneyAmount.verify(message.spouseIncome);
                if (error)
                    return "spouseIncome." + error;
            }
            if (message.months != null && message.hasOwnProperty("months"))
                if (!$util.isInteger(message.months))
                    return "months: integer expected";
            return null;
        };

        /**
         * Creates a NonResidentTax message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.NonResidentTax} NonResidentTax
         */
        NonResidentTax.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.NonResidentTax)
                return object;
            let message = new $root.CalculatorState.NonResidentTax();
            if (object.registerDate != null)
                message.registerDate = String(object.registerDate);
            if (object.income != null) {
                if (typeof object.income !== "object")
                    throw TypeError(".CalculatorState.NonResidentTax.income: object expected");
                message.income = $root.MoneyAmount.fromObject(object.income);
            }
            if (object.spouseIncome != null) {
                if (typeof object.spouseIncome !== "object")
                    throw TypeError(".CalculatorState.NonResidentTax.spouseIncome: object expected");
                message.spouseIncome = $root.MoneyAmount.fromObject(object.spouseIncome);
            }
            if (object.months != null)
                message.months = object.months >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a NonResidentTax message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {CalculatorState.NonResidentTax} message NonResidentTax
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NonResidentTax.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.registerDate = "";
                object.income = null;
                object.spouseIncome = null;
                object.months = 0;
            }
            if (message.registerDate != null && message.hasOwnProperty("registerDate"))
                object.registerDate = message.registerDate;
            if (message.income != null && message.hasOwnProperty("income"))
                object.income = $root.MoneyAmount.toObject(message.income, options);
            if (message.spouseIncome != null && message.hasOwnProperty("spouseIncome"))
                object.spouseIncome = $root.MoneyAmount.toObject(message.spouseIncome, options);
            if (message.months != null && message.hasOwnProperty("months"))
                object.months = message.months;
            return object;
        };

        /**
         * Converts this NonResidentTax to JSON.
         * @function toJSON
         * @memberof CalculatorState.NonResidentTax
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NonResidentTax.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NonResidentTax
         * @function getTypeUrl
         * @memberof CalculatorState.NonResidentTax
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NonResidentTax.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.NonResidentTax";
        };

        return NonResidentTax;
    })();

    CalculatorState.Bills = (function() {

        /**
         * Properties of a Bills.
         * @memberof CalculatorState
         * @interface IBills
         * @property {IMoneyAmount|null} [rent] Bills rent
         * @property {IMoneyAmount|null} [insurance] Bills insurance
         * @property {Array.<CalculatorState.Bills.ICustomExpense>|null} [expenses] Bills expenses
         * @property {Array.<CalculatorState.Bills.ICustomExpense>|null} [spouseExpenses] Bills spouseExpenses
         */

        /**
         * Constructs a new Bills.
         * @memberof CalculatorState
         * @classdesc Represents a Bills.
         * @implements IBills
         * @constructor
         * @param {CalculatorState.IBills=} [properties] Properties to set
         */
        function Bills(properties) {
            this.expenses = [];
            this.spouseExpenses = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Bills rent.
         * @member {IMoneyAmount|null|undefined} rent
         * @memberof CalculatorState.Bills
         * @instance
         */
        Bills.prototype.rent = null;

        /**
         * Bills insurance.
         * @member {IMoneyAmount|null|undefined} insurance
         * @memberof CalculatorState.Bills
         * @instance
         */
        Bills.prototype.insurance = null;

        /**
         * Bills expenses.
         * @member {Array.<CalculatorState.Bills.ICustomExpense>} expenses
         * @memberof CalculatorState.Bills
         * @instance
         */
        Bills.prototype.expenses = $util.emptyArray;

        /**
         * Bills spouseExpenses.
         * @member {Array.<CalculatorState.Bills.ICustomExpense>} spouseExpenses
         * @memberof CalculatorState.Bills
         * @instance
         */
        Bills.prototype.spouseExpenses = $util.emptyArray;

        /**
         * Creates a new Bills instance using the specified properties.
         * @function create
         * @memberof CalculatorState.Bills
         * @static
         * @param {CalculatorState.IBills=} [properties] Properties to set
         * @returns {CalculatorState.Bills} Bills instance
         */
        Bills.create = function create(properties) {
            return new Bills(properties);
        };

        /**
         * Encodes the specified Bills message. Does not implicitly {@link CalculatorState.Bills.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.Bills
         * @static
         * @param {CalculatorState.IBills} message Bills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Bills.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rent != null && Object.hasOwnProperty.call(message, "rent"))
                $root.MoneyAmount.encode(message.rent, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.insurance != null && Object.hasOwnProperty.call(message, "insurance"))
                $root.MoneyAmount.encode(message.insurance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expenses != null && message.expenses.length)
                for (let i = 0; i < message.expenses.length; ++i)
                    $root.CalculatorState.Bills.CustomExpense.encode(message.expenses[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.spouseExpenses != null && message.spouseExpenses.length)
                for (let i = 0; i < message.spouseExpenses.length; ++i)
                    $root.CalculatorState.Bills.CustomExpense.encode(message.spouseExpenses[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Bills message, length delimited. Does not implicitly {@link CalculatorState.Bills.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.Bills
         * @static
         * @param {CalculatorState.IBills} message Bills message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Bills.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Bills message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.Bills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.Bills} Bills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Bills.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Bills();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rent = $root.MoneyAmount.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.insurance = $root.MoneyAmount.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        if (!(message.expenses && message.expenses.length))
                            message.expenses = [];
                        message.expenses.push($root.CalculatorState.Bills.CustomExpense.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (!(message.spouseExpenses && message.spouseExpenses.length))
                            message.spouseExpenses = [];
                        message.spouseExpenses.push($root.CalculatorState.Bills.CustomExpense.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Bills message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.Bills
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.Bills} Bills
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Bills.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Bills message.
         * @function verify
         * @memberof CalculatorState.Bills
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Bills.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rent != null && message.hasOwnProperty("rent")) {
                let error = $root.MoneyAmount.verify(message.rent);
                if (error)
                    return "rent." + error;
            }
            if (message.insurance != null && message.hasOwnProperty("insurance")) {
                let error = $root.MoneyAmount.verify(message.insurance);
                if (error)
                    return "insurance." + error;
            }
            if (message.expenses != null && message.hasOwnProperty("expenses")) {
                if (!Array.isArray(message.expenses))
                    return "expenses: array expected";
                for (let i = 0; i < message.expenses.length; ++i) {
                    let error = $root.CalculatorState.Bills.CustomExpense.verify(message.expenses[i]);
                    if (error)
                        return "expenses." + error;
                }
            }
            if (message.spouseExpenses != null && message.hasOwnProperty("spouseExpenses")) {
                if (!Array.isArray(message.spouseExpenses))
                    return "spouseExpenses: array expected";
                for (let i = 0; i < message.spouseExpenses.length; ++i) {
                    let error = $root.CalculatorState.Bills.CustomExpense.verify(message.spouseExpenses[i]);
                    if (error)
                        return "spouseExpenses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Bills message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.Bills
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.Bills} Bills
         */
        Bills.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.Bills)
                return object;
            let message = new $root.CalculatorState.Bills();
            if (object.rent != null) {
                if (typeof object.rent !== "object")
                    throw TypeError(".CalculatorState.Bills.rent: object expected");
                message.rent = $root.MoneyAmount.fromObject(object.rent);
            }
            if (object.insurance != null) {
                if (typeof object.insurance !== "object")
                    throw TypeError(".CalculatorState.Bills.insurance: object expected");
                message.insurance = $root.MoneyAmount.fromObject(object.insurance);
            }
            if (object.expenses) {
                if (!Array.isArray(object.expenses))
                    throw TypeError(".CalculatorState.Bills.expenses: array expected");
                message.expenses = [];
                for (let i = 0; i < object.expenses.length; ++i) {
                    if (typeof object.expenses[i] !== "object")
                        throw TypeError(".CalculatorState.Bills.expenses: object expected");
                    message.expenses[i] = $root.CalculatorState.Bills.CustomExpense.fromObject(object.expenses[i]);
                }
            }
            if (object.spouseExpenses) {
                if (!Array.isArray(object.spouseExpenses))
                    throw TypeError(".CalculatorState.Bills.spouseExpenses: array expected");
                message.spouseExpenses = [];
                for (let i = 0; i < object.spouseExpenses.length; ++i) {
                    if (typeof object.spouseExpenses[i] !== "object")
                        throw TypeError(".CalculatorState.Bills.spouseExpenses: object expected");
                    message.spouseExpenses[i] = $root.CalculatorState.Bills.CustomExpense.fromObject(object.spouseExpenses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Bills message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.Bills
         * @static
         * @param {CalculatorState.Bills} message Bills
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Bills.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.expenses = [];
                object.spouseExpenses = [];
            }
            if (options.defaults) {
                object.rent = null;
                object.insurance = null;
            }
            if (message.rent != null && message.hasOwnProperty("rent"))
                object.rent = $root.MoneyAmount.toObject(message.rent, options);
            if (message.insurance != null && message.hasOwnProperty("insurance"))
                object.insurance = $root.MoneyAmount.toObject(message.insurance, options);
            if (message.expenses && message.expenses.length) {
                object.expenses = [];
                for (let j = 0; j < message.expenses.length; ++j)
                    object.expenses[j] = $root.CalculatorState.Bills.CustomExpense.toObject(message.expenses[j], options);
            }
            if (message.spouseExpenses && message.spouseExpenses.length) {
                object.spouseExpenses = [];
                for (let j = 0; j < message.spouseExpenses.length; ++j)
                    object.spouseExpenses[j] = $root.CalculatorState.Bills.CustomExpense.toObject(message.spouseExpenses[j], options);
            }
            return object;
        };

        /**
         * Converts this Bills to JSON.
         * @function toJSON
         * @memberof CalculatorState.Bills
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Bills.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Bills
         * @function getTypeUrl
         * @memberof CalculatorState.Bills
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Bills.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.Bills";
        };

        Bills.CustomExpense = (function() {

            /**
             * Properties of a CustomExpense.
             * @memberof CalculatorState.Bills
             * @interface ICustomExpense
             * @property {string|null} [name] CustomExpense name
             * @property {Long|null} [amount] CustomExpense amount
             */

            /**
             * Constructs a new CustomExpense.
             * @memberof CalculatorState.Bills
             * @classdesc Represents a CustomExpense.
             * @implements ICustomExpense
             * @constructor
             * @param {CalculatorState.Bills.ICustomExpense=} [properties] Properties to set
             */
            function CustomExpense(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CustomExpense name.
             * @member {string} name
             * @memberof CalculatorState.Bills.CustomExpense
             * @instance
             */
            CustomExpense.prototype.name = "";

            /**
             * CustomExpense amount.
             * @member {Long} amount
             * @memberof CalculatorState.Bills.CustomExpense
             * @instance
             */
            CustomExpense.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CustomExpense instance using the specified properties.
             * @function create
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {CalculatorState.Bills.ICustomExpense=} [properties] Properties to set
             * @returns {CalculatorState.Bills.CustomExpense} CustomExpense instance
             */
            CustomExpense.create = function create(properties) {
                return new CustomExpense(properties);
            };

            /**
             * Encodes the specified CustomExpense message. Does not implicitly {@link CalculatorState.Bills.CustomExpense.verify|verify} messages.
             * @function encode
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {CalculatorState.Bills.ICustomExpense} message CustomExpense message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomExpense.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.amount);
                return writer;
            };

            /**
             * Encodes the specified CustomExpense message, length delimited. Does not implicitly {@link CalculatorState.Bills.CustomExpense.verify|verify} messages.
             * @function encodeDelimited
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {CalculatorState.Bills.ICustomExpense} message CustomExpense message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomExpense.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CustomExpense message from the specified reader or buffer.
             * @function decode
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {CalculatorState.Bills.CustomExpense} CustomExpense
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomExpense.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.Bills.CustomExpense();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.amount = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CustomExpense message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {CalculatorState.Bills.CustomExpense} CustomExpense
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomExpense.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CustomExpense message.
             * @function verify
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CustomExpense.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                        return "amount: integer|Long expected";
                return null;
            };

            /**
             * Creates a CustomExpense message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {CalculatorState.Bills.CustomExpense} CustomExpense
             */
            CustomExpense.fromObject = function fromObject(object) {
                if (object instanceof $root.CalculatorState.Bills.CustomExpense)
                    return object;
                let message = new $root.CalculatorState.Bills.CustomExpense();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.amount != null)
                    if ($util.Long)
                        (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
                    else if (typeof object.amount === "string")
                        message.amount = parseInt(object.amount, 10);
                    else if (typeof object.amount === "number")
                        message.amount = object.amount;
                    else if (typeof object.amount === "object")
                        message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CustomExpense message. Also converts values to other types if specified.
             * @function toObject
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {CalculatorState.Bills.CustomExpense} message CustomExpense
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomExpense.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.amount = options.longs === String ? "0" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount === "number")
                        object.amount = options.longs === String ? String(message.amount) : message.amount;
                    else
                        object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
                return object;
            };

            /**
             * Converts this CustomExpense to JSON.
             * @function toJSON
             * @memberof CalculatorState.Bills.CustomExpense
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomExpense.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CustomExpense
             * @function getTypeUrl
             * @memberof CalculatorState.Bills.CustomExpense
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CustomExpense.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/CalculatorState.Bills.CustomExpense";
            };

            return CustomExpense;
        })();

        return Bills;
    })();

    CalculatorState.NewActivityDeduction = (function() {

        /**
         * Properties of a NewActivityDeduction.
         * @memberof CalculatorState
         * @interface INewActivityDeduction
         * @property {boolean|null} [userApplied] NewActivityDeduction userApplied
         * @property {boolean|null} [spouseApplied] NewActivityDeduction spouseApplied
         */

        /**
         * Constructs a new NewActivityDeduction.
         * @memberof CalculatorState
         * @classdesc Represents a NewActivityDeduction.
         * @implements INewActivityDeduction
         * @constructor
         * @param {CalculatorState.INewActivityDeduction=} [properties] Properties to set
         */
        function NewActivityDeduction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewActivityDeduction userApplied.
         * @member {boolean} userApplied
         * @memberof CalculatorState.NewActivityDeduction
         * @instance
         */
        NewActivityDeduction.prototype.userApplied = false;

        /**
         * NewActivityDeduction spouseApplied.
         * @member {boolean} spouseApplied
         * @memberof CalculatorState.NewActivityDeduction
         * @instance
         */
        NewActivityDeduction.prototype.spouseApplied = false;

        /**
         * Creates a new NewActivityDeduction instance using the specified properties.
         * @function create
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {CalculatorState.INewActivityDeduction=} [properties] Properties to set
         * @returns {CalculatorState.NewActivityDeduction} NewActivityDeduction instance
         */
        NewActivityDeduction.create = function create(properties) {
            return new NewActivityDeduction(properties);
        };

        /**
         * Encodes the specified NewActivityDeduction message. Does not implicitly {@link CalculatorState.NewActivityDeduction.verify|verify} messages.
         * @function encode
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {CalculatorState.INewActivityDeduction} message NewActivityDeduction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewActivityDeduction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userApplied != null && Object.hasOwnProperty.call(message, "userApplied"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.userApplied);
            if (message.spouseApplied != null && Object.hasOwnProperty.call(message, "spouseApplied"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.spouseApplied);
            return writer;
        };

        /**
         * Encodes the specified NewActivityDeduction message, length delimited. Does not implicitly {@link CalculatorState.NewActivityDeduction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {CalculatorState.INewActivityDeduction} message NewActivityDeduction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewActivityDeduction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NewActivityDeduction message from the specified reader or buffer.
         * @function decode
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CalculatorState.NewActivityDeduction} NewActivityDeduction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewActivityDeduction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CalculatorState.NewActivityDeduction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userApplied = reader.bool();
                        break;
                    }
                case 2: {
                        message.spouseApplied = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NewActivityDeduction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CalculatorState.NewActivityDeduction} NewActivityDeduction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewActivityDeduction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NewActivityDeduction message.
         * @function verify
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewActivityDeduction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userApplied != null && message.hasOwnProperty("userApplied"))
                if (typeof message.userApplied !== "boolean")
                    return "userApplied: boolean expected";
            if (message.spouseApplied != null && message.hasOwnProperty("spouseApplied"))
                if (typeof message.spouseApplied !== "boolean")
                    return "spouseApplied: boolean expected";
            return null;
        };

        /**
         * Creates a NewActivityDeduction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CalculatorState.NewActivityDeduction} NewActivityDeduction
         */
        NewActivityDeduction.fromObject = function fromObject(object) {
            if (object instanceof $root.CalculatorState.NewActivityDeduction)
                return object;
            let message = new $root.CalculatorState.NewActivityDeduction();
            if (object.userApplied != null)
                message.userApplied = Boolean(object.userApplied);
            if (object.spouseApplied != null)
                message.spouseApplied = Boolean(object.spouseApplied);
            return message;
        };

        /**
         * Creates a plain object from a NewActivityDeduction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {CalculatorState.NewActivityDeduction} message NewActivityDeduction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewActivityDeduction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userApplied = false;
                object.spouseApplied = false;
            }
            if (message.userApplied != null && message.hasOwnProperty("userApplied"))
                object.userApplied = message.userApplied;
            if (message.spouseApplied != null && message.hasOwnProperty("spouseApplied"))
                object.spouseApplied = message.spouseApplied;
            return object;
        };

        /**
         * Converts this NewActivityDeduction to JSON.
         * @function toJSON
         * @memberof CalculatorState.NewActivityDeduction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewActivityDeduction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NewActivityDeduction
         * @function getTypeUrl
         * @memberof CalculatorState.NewActivityDeduction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NewActivityDeduction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/CalculatorState.NewActivityDeduction";
        };

        return NewActivityDeduction;
    })();

    return CalculatorState;
})();

export { $root as default };
